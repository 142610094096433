<template> 
  <div> 
    <div class="ms-4">
        <div class="page-title flex-row me-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('General Offer Report') }} ({{ $t('Account') }})
            </h1> 
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-primary">{{ $t('Reports') }}</li> 
                <li class="breadcrumb-item text-primary">{{ $t('Offer Reports') }}</li>    
                <li class="breadcrumb-item text-gray-600">{{ $t('General Offer Report') }} ({{ $t('Account') }})
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon> 
                </li> 
            </ul> 
        </div>  
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">      
        <div class="card">
            <div class="row fv-row border-0 pt-6 px-9">
                <div class="col-lg-9">
                    <div class="fv-row mb-4 row">  
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('Offer Type') }}</span>
                            </label>
                            <Select2 v-model="form.offer_type" :options="offerTypeList" :settings="{ width: '100%' }"/>
                        </div> 
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('Position') }}</span>
                            </label>
                            <Select2 v-model="form.position" :options="positionList"  :settings="{ width: '100%' }"/>
                        </div>
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('Report Type') }}</span>
                            </label>
                            <Select2 v-model="form.report_type" :options="reportTypes"  :settings="{ width: '100%' }"/>
                        </div>
                        
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12" v-if="form.report_type == '2'">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('Start Date') }}</span>
                            </label>
                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.start_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                        </div>
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12" v-if="form.report_type == '2'">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('End Date') }}</span>
                            </label>
                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                        </div>
                    </div>  
                </div>
                <div class="col-lg-3">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base" v-if="this.$isCredentialActive(72,'R')"> 
                        <Popper hover placement="top">
                            <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3">
                                <i class="bi bi-eraser-fill fs-4"> </i>
                            </button>
                            <template #content>
                                <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                            </template>
                        </Popper> 
                        <button type="button" class="btn btn-light-primary border border-primary"  data-kt-indicator="on" @click="getListData()">
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span>
                            <span v-if="!this.isListProgressing"> {{ $t('List') }} </span> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button> 
                    </div>
                    <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                        <div class="fw-bolder me-5">
                        <span class="me-2" data-kt-customer-table-select="selected_count"></span>{{ $t('Selected') }}</div>
                        <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">{{ $t('Delete Selected') }}</button>
                    </div>
                </div>
            </div>
            <div class="card-body pt-3">
                <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">
                        <ListOptions :name="this.$options.name" @click="SaveDXStateToStorage" ref="listOptionComponent"></ListOptions>
                         <DxDataGrid id="gridContainer"
                                :show-borders="true"
                                key-expr="id"
                                :data-source="lists"
                                :allow-column-reordering="true" 
                                :allow-column-resizing="true"
                                :column-auto-width="true"
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" 
                                :ref="'dataGrid'"
                                :remote-operations="true"
                                @exporting="this.$onExporting($event , 'General-List')">   

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="pageSize"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizesLists" :show-page-size-selector="showPageSizeSelectorLists" :show-info="true" :show-navigation-buttons="true"/>    
                                <DxSearchPanel :visible="true"/>
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                />

                                <DxMasterDetail
                                    :enabled="true"
                                    :autoExpandAll="false"
                                    template="masterDetailTemplate"
                                />

                                <DxColumn data-field="offer_date" :caption="$t('Record Date')" data-type="date" alignment="right" format="dd.MM.yyyy" header-cell-template="record-date-header"/> 
                                <DxColumn data-field="account_name" :caption="$t('Account')" /> 
                                <DxColumn data-field="offer_type_name" :caption="$t('Offer Type')" header-cell-template="invoice-type-header" /> 
                                <DxColumn data-field="position_name" :caption="$t('Position')"/> 
                                <DxColumn data-field="is_active" :caption="$t('Active?')" cell-template="is_active-cell-template" :width="100" /> 
                                <DxColumn data-field="is_repeated_invoice" :caption="$t('Repeated Invoice?')" alignment="center" header-cell-template="is_repeated_invoice-header" cell-template="is_repeated_invoice-cell-template"/> 
                                <DxColumn data-field="offer_no" :caption="$t('Offer Number')" header-cell-template="invoice-number-header" /> 
                                <DxColumn data-field="gross_amount" :sorting-method="this.$numericSort" :caption="$t('Grand Total')" header-cell-template="grand-total-header" alignment="right" /> 
                                <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right" /> 
                                <DxColumn data-field="gross_amount_local" :sorting-method="this.$numericSort" :caption="$t('Grand Total Local')" header-cell-template="grand-total-local-header" alignment="right" />
                                <DxColumn data-field="invoice_description" :caption="$t('Invoice Detail')" header-cell-template="invoice-description-header" cell-template="invoice_description-cell-template"/> 
                                <DxColumn data-field="sales_type" :caption="$t('Sales Type')" alignment="left" header-cell-template="sales-type-header" cell-template="sales_type-cell-template"/> 
                                <DxColumn data-field="created_by" :caption="$t('Created By')"/> 


                                <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur --> 
                                <template #headerLocalTemplate="{ data: data }">
                                    <div v-html="setColumnTemplateForLocal(data)"></div> 
                                </template>

                                <template #record-date-header>
                                    <div>{{ $t('Record') }}</div><div>{{ $t('Date') }}</div>
                                </template>
                                <template #invoice-type-header>
                                    <div>{{ $t('Offer') }}</div><div>{{ $t('Type') }}</div>
                                </template>
                                <template #invoice-description-header>
                                    <div>{{ $t('Invoice') }}</div><div>{{ $t('Detail') }}</div>
                                </template>
                                <template #sales-type-header>
                                    <div>{{ $t('Sales') }}</div><div>{{ $t('Type') }}</div>
                                </template>
                                <template #invoice-number-header>
                                    <div>{{ $t('Offer') }}</div><div>{{ $t('Number') }}</div>
                                </template>
                                <template #grand-total-header>
                                    <div>{{ $t('Grand') }}</div><div>{{ $t('Total') }}</div>
                                </template>
                                <template #grand-total-local-header>
                                    <div>{{ $t('Grand Total') }}</div><div>{{ $t('Local') }}</div>
                                </template> 
                                <template #is_repeated_invoice-header>
                                    <div>{{ $t('Repeated') }}</div><div>{{ $t('Invoice?') }}</div>
                                </template> 

                                 <!-- Cell Templates -->
                                 <template #position_name-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 text-hover-primary" @click="updateOffer(data.data.id)">
                                        <span class="badge" 
                                            :class="{'badge-info': data.data.position == 1, 'badge-success': data.data.position == 2 || data.data.position == 6, 'badge-primary': data.data.position == 3, 'badge-danger': data.data.position == 4 || data.data.position == 7}">
                                            {{ data.data.position_name }} 
                                        </span>
                                    </a>
                                </template>
                                 <template #sales_type-cell-template="{ data }"> 
                                    <a href="#" class="text-gray-800 text-hover-primary" @click="updateOffer(data.data.id)">
                                        <span v-if="data.data.sales_type == 1"> {{ $t('Domestic') }} </span>
                                        <span v-if="data.data.sales_type == 2"> {{ $t('Overseas') }} </span>
                                    </a>
                                </template>
                                <template #is_active-cell-template="{ data }"> 
                                    <a href="#" class="text-gray-800 text-hover-primary" @click="updateOffer(data.data.id)">
                                        <span v-if="data.data.is_active">                                          
                                            <i class="bi bi-check-circle fs-3 text-success"></i>
                                        </span>  
                                        <span v-if="!data.data.is_active">
                                          <i class="bi bi-x-circle fs-3 text-danger"></i>
                                        </span> 
                                    </a>
                                </template>
                                 <template #gross_amount-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 text-hover-primary" @click="updateOffer(data.data.id)">
                                        {{ data.data.gross_amount }} {{ data.data.currency }} 
                                    </a>
                                </template>
                                 <template #invoice_description-cell-template="{ data }">
                                     <a href="#" class="text-gray-800 text-hover-primary text-center" @click="updateOffer(data.data.id)">
                                        <div v-if="data.data.invoice_description !== '' && data.data.invoice_description !== null && data.data.invoice_description !== undefined">
                                            <i class="bi bi-check-circle text-success"></i> {{ $t('Invoice Issued') }}
                                        </div>
                                        <div v-if="data.data.invoice_description !== '' && data.data.invoice_description !== null && data.data.invoice_description !== undefined">
                                            {{ data.data.invoice_description }}
                                        </div>
                                    </a>
                                </template>
                               <template #gross_amount_local-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 text-hover-primary" @click="updateOffer(data.data.id)">
                                        {{ data.data.gross_amount_local }} {{ data.data.local_currency }} 
                                    </a>
                                </template>
                               <template #is_repeated_invoice-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 text-hover-primary" @click="updateOffer(data.data.id)">
                                        <span v-if="data.data.is_repeated_invoice">                                          
                                            <i class="bi bi-check-circle fs-3 text-success"></i>
                                        </span>  
                                        <span v-if="!data.data.is_repeated_invoice">
                                          <i class="bi bi-x-circle fs-3 text-danger"></i>
                                        </span> 
                                    </a>
                                </template>

                                
                                <template #masterDetailTemplate="{ data }"> 
                                    <DxDataGrid :data-source="getOfferDetail(data.data.id)" :show-borders="true" :column-auto-width="true" >
                                        <DxColumn data-field="transaction_date"  data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Trns. Date')" />
                                        <DxColumn data-field="transaction_type_name" :caption="$t('Trns. Type')" /> 
                                        <DxColumn data-field="transaction_no" :caption="$t('Trns. No')" />
                                        <DxColumn data-field="stock_account_name" :caption="$t('Stock Acc.')" cell-template="stock-account-name-cell-template"/>
                                        <DxColumn data-field="in_out_name" :caption="$t('In / Out')" />  
                                        <DxColumn data-field="currency" :caption="$t('Cur.')" />
                                        <DxColumn data-field="quantity1" :sorting-method="this.$numericSort" :caption="$t('Qty')"  alignment="right" />
                                        <DxColumn data-field="price" :sorting-method="this.$numericSort" :caption="$t('Price')" alignment="right" />
                                        <DxColumn data-field="net_price" :sorting-method="this.$numericSort" :caption="$t('Net Price')" alignment="right" />
                                        <DxColumn data-field="description" :caption="$t('Description')" />

                                        <template #stock-account-name-cell-template="{ data }"> 
                                            <span class="text-gray-900"> 
                                                {{ data.data.stock_account_name }} 
                                            </span> 
                                            <small v-if="data.data.is_bundle_product == 1" class="ms-2 badge badge-sm badge-success">Bundle</small>
                                        </template>
                                    </DxDataGrid>
                                </template>
                                
                                <DxSummary :calculate-custom-summary="setDetailSummaries">
                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="stock_code"/>
                                    <DxTotalItem
                                        name="gross_amount"
                                        summary-type="custom"
                                        show-in-column="gross_amount"
                                        display-format="{0}"
                                    />
                                    <DxTotalItem
                                        name="gross_amount_local"
                                        summary-type="custom"
                                        show-in-column="gross_amount_local"
                                        display-format="{0}"
                                    />
                                </DxSummary>

                                <DxStateStoring
                                    :enabled="true"
                                    type="custom"
                                    :storage-key="this.storageKey"
                                    :ref="dataGrid" 
                                    :saving-timeout="1000"
                                    :custom-load="LoadDXState"
                                />
                        </DxDataGrid>     
                    </div>  
                </div> 
            </div>
        </div> 
    </div>
  </div>  
</template>
<script>
import axios from 'axios' 
// import Swal from 'sweetalert2';
import moment from 'moment'; 
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxStateStoring, DxTotalItem, DxSummary, DxScrolling, DxMasterDetail } from 'devextreme-vue/data-grid';
 
export default {
  name: "GeneralOfferReport",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxStateStoring, DxTotalItem, DxSummary, DxScrolling, DxMasterDetail
  }, 
  data() {
    return {
        storageKey: 'dx_' + this.$options.name,
        pageSize: 100,
        pageSizeDetailList: 10,
        page_size: 20,
        lists: [],
        form:  {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            offer_type: 0,
            report_type: '1',
            position: 0
        },
        isListProgressing: false,
        isFilterOpen: false,
        offerTypeList: [{ id: 0, text: 'ALL'}, { id: 1, text: 'Offer'}, { id: 2, text: 'Order'}],
        reportTypes: [{ id: '1', text: 'General'}, { id: '2', text: 'Date Range'}],
        positionList: [
            { id: 0, text: 'ALL'},
            { id: 1, text: 'Offer'},
            { id: 2, text: 'Order'},
            { id: 3, text: 'To Delivery'},
            { id: 4, text: 'Cancel'},
            { id: 5, text: 'Delivered'},
            { id: 6, text: 'Activated'},
            { id: 7, text: 'Deactivated'},
        ],
        menuSelection: '/GeneralOfferReportHelppage',
        iconView : false
    };
  },
  computed: {
   previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    showPageSizeSelectorLists() {
        return this.lists.length > this.pageSize;
    },
    pageSizesLists() {
        let arr = [];
        let x = 1;
        let loop = (this.lists.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(1000);
        if(loop > 1) arr.push('all');
        return arr;
    },
  },
  methods: {
    getListData() {
        this.$Progress.start();
        this.isListProgressing = true;
        this.form.start_date = (this.form.start_date == null || this.form.start_date == '') ? moment() : this.form.start_date;
        this.form.end_date = ( this.form.end_date == null || this.form.end_date == '') ? moment () : this.form.end_date; 
        const params = {
            session_id: this.$getUser().session_id,
            start_date: moment(this.form.start_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            end_date: moment(this.form.end_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            report_type: this.form.report_type,
            offer_type: this.form.offer_type,
            position: this.form.position
        };
        axios.post('/api/OfferReports/GeneralOfferReport', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.lists = !response.data.error ? response.data : [];
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        });
    },
    getOfferDetail(offer_id) {
        let detailList = [];
        const params = {
            session_id: this.$getUser().session_id,
            offer_id: offer_id
        };
        axios.post('/api/OfferReports/GeneralOfferReportDetail', params, {'Content-Type': 'application/json'})
        .then((response) => {
            detailList = !response.data.error ? response.data : [];
        });
        return detailList;
    },
    /** DX STATE OPERATIONS **/  
    LoadDXState() {
        return JSON.parse(localStorage.getItem(this.storageKey));
    }, 
    SaveDXStateToStorage() { 
        const state = this.$refs.dataGrid.instance.state();
        localStorage.setItem(this.storageKey, JSON.stringify(state));
        this.$refs.listOptionComponent.getState();        
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/GeneralOfferReportHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    setDetailSummaries(options){  
        const optionNames = ["gross_amount", "gross_amount_local"]; 
        optionNames.forEach(item => {
            if(options.name == item){
                if(options.summaryProcess === 'start'){ options.totalValue = 0; }
                else if(options.summaryProcess === 'calculate'){
                    var itemValue = parseFloat(options.value[item].replaceAll(',', ''));
                    (options.totalValue += itemValue);
                }
                else if(options.summaryProcess === 'finalize'){ 
                    options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
                }
            }
        });
    },
    setCriteriasToDefault() {   
        this.form = {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            offer_type: 0,
            report_type: '1',
            position: 0
        };
        this.lists = [];
    },
  },
  created() {
    this.helpPageViewIcon();
  }
}; 
</script>