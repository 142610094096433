<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3">
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Create New Offsetting') }} </h1>
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li>
                    <li class="breadcrumb-item text-primary">{{ $t('Transactions') }}</li>
                    <li class="breadcrumb-item text-gray-600">{{ $t('Create New Offsetting') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1" v-if="getSubCredentials('R')">
                <button type="button" class="btn btn-light border border-gray-400" data-bs-toggle="modal" data-bs-target="#kt_modal_transaction_list" @click="onOpenTransactionListModal()" >
                    <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                    <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                    <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                    </svg>
                    </span>
                    {{ $t('Transaction List') }}
                </button>
            </div>
        </div>
        <div class="content flex-row-fluid mt-2"> 
            <!-- <StatsBar></StatsBar> -->
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch">
            <div class="row p-4">
                <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                    <div class="row p-4">
                        <div class="col-lg-12">
                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-header">
                                    <div class="card-title">
                                        <h3>{{ $t('Offsetting') }}</h3>
                                    </div>
                                </div>
                                <div class="card-body pb-0">
                                    <div class="fv-row mb-7 row">
                                        <div class="col-lg-3 mb-2">
                                            <label class="fs-6 fw-bold form-label" v-tag="'record_date'" >
                                                {{ $t('Record Date') }}
                                            </label>
                                            <Datepicker :required="this.$checkIfDateFieldRequired ('record_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.record_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" :disabled="this.docketItemsList.length > 0" name="record_date" v-custom :minDate="getMinTransactionDate" />
                                            <small class="text-info" v-if="userData.last_transaction_date !== null && userData.last_transaction_date !== undefined && userData.last_transaction_date !== ''">
                                                {{ $t('The last transaction date is ') }} {{previewDate(userData.last_transaction_date)}}. {{ $t('This date and the dates before are closed for transactions') }}.
                                            </small>
                                        </div>
                                        <div class="col-lg-3 mb-2">
                                            <label class="fs-6 fw-bold form-label" v-tag="'docket_number'" >
                                                <span>{{ $t('Offset Number') }}</span>
                                            </label>   
                                            <input type="text" class="form-control fw-boldest text-black-600" v-model="form.docket_number" v-once disabled placeholder="Automatic"  name="docket_number" v-custom />
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-sm-12">
                                            <label class="fs-6 fw-bold form-label" v-tag="'docket_sub_type'" >
                                                {{ $t('Offsetting Type') }}
                                            </label>
                                            <Select2 v-model="form.docket_sub_type" :options="offsettingTypeList" :settings="{ width: '100%' }" name="docket_sub_type" v-custom />
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-0">
                            <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300 mb-10">
                                <div class="card-body pb-0">
                                    <div class="p-0">
                                        <div class="d-flex flex-stack flex-wrap mb-5">
                                            <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                <button type="button" class="btn btn-sm btn-success fw-bold required me-3"
                                                        data-bs-toggle="modal" 
                                                        ref="btnAddItem"
                                                        data-bs-target="#kt_modal_add_item" 
                                                        @click="openDocketModal('addItem')"
                                                        data-kt-menu-trigger="click" 
                                                        data-kt-menu-placement="bottom-end">

                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                    transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                    fill="currentColor">
                                                                </rect>
                                                            </svg>
                                                        </span> 
                                                        {{ $t('Add To List') }}
                                                </button>
                                                <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" 
                                                        data-bs-toggle="modal"
                                                        ref="btnEditItem"
                                                        data-bs-target="#kt_modal_add_item" 
                                                        @click="openDocketModal('editItem')"
                                                        :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                        
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                                <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                            </svg>
                                                        </span> 
                                                        {{ $t('Edit') }}
                                                </button>
                                                <button type="button" class="btn btn-sm btn-light btn-active-warning me-3"
                                                    @click="deleteTransactionItem()"
                                                    :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                    {{ $t('Remove') }}
                                                </button>
                                                <button type="button" class="btn btn-sm btn btn-light btn-active-danger" @click="deleteAllTransactionItems()">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                        </svg>
                                                    </span>
                                                    {{ $t('Remove All (!)') }}
                                                </button> 
                                            </div>
                                        </div>
                                        <div class="pb-10">
                                            <DxDataGrid id="gridContainer" 
                                                :show-borders="true" 
                                                key-expr="id" 
                                                :data-source="docketItemsList" 
                                                :allow-column-reordering="true"  
                                                :allow-column-resizing="true" 
                                                :column-auto-width="true" 
                                                :row-alternatin-enabled="true"
                                                :hover-state-enabled="true" 
                                                :show-row-lines="true" 
                                                :customize-columns="onCustomizeColumn"
                                                @exporting="this.$onExporting($event , 'Offset-transaction-items')"
                                                :scrolling="{ mode: 'virtual' }"
                                                @onPageIndexChanged="onPageIndexChanged"
                                                @onPageSizeChanged="onPageSizeChanged"
                                                @option-changed="handlePropertyChange"> 

                                                <DxFilterRow :visible="true"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxGroupPanel :visible="true"/>      
                                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                <DxPaging :page-size="pageSize"/>
                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/> 
                                                <DxSearchPanel :visible="true"/> 

                                                <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/> 
                                                <DxColumn data-field="id" caption="" alignment="right" cell-template="id-cell-template" :allow-exporting="false"/> 
                                                <DxColumn data-field="transaction_date" :caption="$t('Date')" data-type="date" alignment="right" format="dd.MM.yyyy" cell-template="transaction_date-cell-template"/>   
                                                <DxColumn data-field="account_name" :caption="$t('Account Name')" header-cell-template="account-name-header" cell-template="payment_account_name-cell-template"/>   
                                                <DxColumn data-field="transaction_number" :caption="$t('Transaction No')" header-cell-template="transaction-no-header" cell-template="transaction_number-cell-template"/>  
                                                <DxColumn data-field="amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" alignment="right" cell-template="amount-cell-template"/>
                                                <DxColumn data-field="currency" :caption="$t('Currency')" cell-template="currency-cell-template"/>  
                                                <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right" cell-template="rate-cell-template"/> 
                                                <DxColumn data-field="amount_local" :sorting-method="this.$numericSort" :caption="$t('Local Amount')" alignment="right" cell-template="amount_local-cell-template"/> 
                                                <DxColumn data-field="item_type_name" :caption="$t('Debit/Credit')" alignment="right" header-cell-template="item_type_name-header" cell-template="item_type_name-cell-template"/> 

                                                <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur --> 
                                                <template #headerLocalTemplate="{ data: data }">
                                                    <div v-html="setColumnTemplateForLocal(data)"></div> 
                                                </template>                                                
                                                <template #transaction-date-header>
                                                    <div>{{ $t('Transaction') }}</div><div>{{ $t('Date') }}</div>
                                                </template>
                                                <template #account-name-header>
                                                    <div>{{ $t('Account') }}</div><div>{{ $t('Name') }}</div>
                                                </template>
                                                <template #transaction-no-header>
                                                    <div>{{ $t('Transaction') }}</div><div>{{ $t('No') }}</div>
                                                </template>
                                                <template #item-type-name-header>
                                                    <div>{{ $t('Debit') }}</div><div>{{ $t('Credit') }}</div>
                                                </template>
                                                <template #item_type_name-header>
                                                    <div>{{ $t('Debit') }}</div><div>{{ $t('Credit') }}</div>
                                                </template>

                                                <template #is-selected-cell-template="{ data }">
                                                    <span>
                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                                        </div>
                                                    </span>
                                                </template>
                                                <template #id-cell-template="{ data }">
                                                    <a href="#" class="text-gray-800 text-hover-primary">
                                                        {{ (this.pageSize * this.pageIndex) + data.rowIndex + 1 }}
                                                    </a>
                                                </template>
                                                <template #transaction_date-cell-template="{ data }">
                                                        <span class="text-gray-700 p-3 align-middle text-end" > {{ previewDate(data.data.transaction_date) }}</span>
                                                </template>
                                                <template #payment_account_name-cell-template="{ data }">
                                                        <span class="text-gray-700 p-3 align-middle" > {{ data.data.account_name }}</span>
                                                </template>
                                                <template #transaction_number-cell-template="{ data }">
                                                    <span class="text-gray-600 p-3 align-middle" >{{ data.data.transaction_number }}</span>
                                                </template>
                                                <template #amount-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.amount}}</span>
                                                </template>
                                                <template #currency-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold p-3 align-middle"> {{data.data.currency}}</span>
                                                </template>
                                                <template #rate-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.rate}} </span>
                                                </template>
                                                <template #amount_local-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.amount_local}} </span>
                                                </template>
                                                <template #item_type_name-cell-template="{ data }">
                                                        <span class="text-gray-800 fw-bold text-end p-3 align-middle"> {{data.data.item_type_name}} </span>
                                                </template>
                                                <DxSummary :calculate-custom-summary="setSummaries">
                                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="isSelected"/>
                                                    <DxGroupItem
                                                        :show-in-group-footer="true"
                                                        name="amount_local"
                                                        summary-type="custom"
                                                        show-in-column="amount_local"
                                                        display-format="{0}"
                                                    /> 
                                                    <DxTotalItem name="amount_local"
                                                        summary-type="custom"
                                                        show-in-column="amount_local"
                                                    />
                                                </DxSummary>
                                            </DxDataGrid>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12" v-if="getSubCredentials('I')">
                            <div class="row g-xxl-9">
                                <div class="col-lg-12">
                                    <div class="d-grid gap-2"> 
                                        <button class="btn btn-success btn-lg mb-0" type="submit" @click="createOffsetHeader()" data-kt-indicator="on" :disabled="docketItemsList.length == 0"> 
                                            <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            </svg>
                                            </span>  
                                            <strong v-if="!this.isDocketSaving"> {{ $t('Save Transaction') }} </strong> 
                                            <span class="indicator-progress" v-if="this.isDocketSaving">
                                                {{ $t('Saving Transaction. Please wait...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            </div>
        </div>
        <!-- ADD TRANSACTION MODAL -->
        <div class="modal fade" id="kt_modal_add_item" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xxl">
                <div class="modal-content"  >
                    <div class="modal-header bg-primary" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-11 text-white">
                            <span v-if="this.modalType == 'addItem'"> {{ $t('Add') }} </span>
                            <span v-if="this.modalType == 'editItem'"> {{ $t('Edit') }} </span> {{ $t('Offset') }}
                        </h2>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y pt-0" :class="{'overlay overlay-block': this.isDocketItemSaving == true}">
                        <ul class="nav nav-tabs nav-line-tabs mb-5 fs-6">
                            <li class="nav-item">
                                <a class="nav-link cursor-pointer" :class="{'active': this.activeTab == 'Transaction'}" @click="this.activeTab = 'Transaction'"><h4>{{ $t('Transaction') }}</h4></a>
                            </li> 
                            <li class="nav-item" v-if="this.$isModuleActive(3)">
                                <a class="nav-link cursor-pointer" :class="{'active': this.activeTab == 'Document'}" @click="this.onActivateDocumentsTab()"><h4>{{ $t('Documents') }}</h4></a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade" :class="{'show active': this.activeTab == 'Transaction'}">
                                <RequiredFieldOnModal :routeName="this.modalType == 'addItem' ? 'OffsettingItemCreate' : 'OffsettingItemUpdate'" class="mt-5"></RequiredFieldOnModal>
                                <form id="offsetItemForm" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">  
                                    <div class="row">
                                        <div class="col-lg-8" :class="{'col-lg-12': this.modalType == 'editItem' && this.docketForm.is_manuel == 0}">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="card card-xxl-stretch mb-3">
                                                        <div class="card-header min-h-50px">
                                                            <div class="card-title">
                                                                <h3 class="text-gray-800 me-5">
                                                                    {{ $t('Select Account') }}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div class="card-body pt-3 pb-3">
                                                            <div class="row">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                                    {{ $t('Search Account') }}
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <div class="input-group">
                                                                        <input type="search" class="form-control form-control-lg" v-model="docketForm.account_name" @keyup.enter="getAccounts()" :placeholder="$t('Please type an account name and press enter for search account')" @input="docketForm.account_id = '-1'" >
                                                                        <button type="button" class="btn btn-sm btn-light-primary border border-gray-300 z-index-0" @click="getAccounts()" :disabled="docketForm.account_name.length < 1 && !this.$getUser().is_allow_list_all_data">
                                                                            <span v-if="!isSearchingAccount"><i class="fas fa-search fs-4 me-3"></i> {{ $t('Search') }}</span>
                                                                            <span v-if="isSearchingAccount">{{ $t('Searching...') }}</span> 
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6 "  v-tag="'account_id'">
                                                                    {{ $t('Account') }}
                                                                </label>
                                                                <div class="col-lg-7 fv-row fv-plugins-icon-container" :class="{'col-lg-8': docketForm.account_id == null || docketForm.account_id == '-1' || docketForm.account_id == '', 'pe-0': docketForm.account_id !== null && docketForm.account_id !== '-1' && docketForm.account_id !== ''}">
                                                                    <Select2 id="account_id" v-model="docketForm.account_id" :options="accountList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"
                                                                    :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0" @select="onChangeAccount($event)" name="account_id" v-custom/>
                                                                </div>
                                                                <div class="col-lg-1 fv-row fv-plugins-icon-container ps-0" v-if="docketForm.account_id !== null && docketForm.account_id !== '-1' && docketForm.account_id !== '' && docketForm.account_id !== undefined">       
                                                                    <button type="button" class="btn rounded-start-0 btn-light btn-sm pb-3 text-hover-primary border border-1 border-gray-300" @click="onOpenAccountReport(docketForm.account_id, false, null)">
                                                                        <i class="fa fa-eye text-gray-600 fs-3"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="card card-xxl-stretch mb-3">
                                                        <div class="card-body py-2">   
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6" v-tag="'transaction_date'" >
                                                                    <span>{{ $t('Transaction Date') }}</span>
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <Datepicker :required="this.$checkIfDateFieldRequired('transaction_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="docketForm.transaction_date"  format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false"
                                                                    :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0" name="transaction_date" v-custom />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                                    <span v-tag="'item_type'" > {{ $t('Debit|Credit') }}</span>
                                                                    <span v-tag="'bc_type'"> / {{ $t('Balance Closing Type') }} </span>
                                                                </label>
                                                                <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <Select2 v-model="this.docketForm.item_type" :options="this.debitCreditStatusList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"
                                                                    :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0"
                                                                    @select="getDescriptions()" name="item_type" v-custom />
                                                                </div>
                                                                <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                                                    <Select2 v-if="docketForm.item_type == 1" v-model="docketForm.bc_type" :options="balanceClosingDebitList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="bc_type" v-custom />
                                                                    <Select2 v-if="docketForm.item_type == 2" v-model="docketForm.bc_type" :options="balanceClosingCreditList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="bc_type" v-custom />
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6 ">
                                                                    <span v-tag="'currency'" > {{ $t('Currency') }} </span> 
                                                                    <span v-tag="'rate'" v-if="this.docketForm.currency !== this.localCurrency"> / {{ $t('Rate') }} </span>                                                                        
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <div class="row">
                                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container" :class="{ 'col-lg-12': this.docketForm.currency == this.localCurrency }">
                                                                            <Select2 v-model="this.docketForm.currency" :options="this.currencyList" @select="onChangeCurrency()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"
                                                                            :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0" name="currency" v-custom />
                                                                        </div>
                                                                        <div class="col-lg-6 fv-row fv-row fv-plugins-icon-container" v-if="this.docketForm.currency !== this.localCurrency">
                                                                            <Select2 v-model="typeSelection" :options="typeList" @select="onChangeRateType()" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }"/>  
                                                                        </div>
                                                                        <div class="col-lg-12 fv-row fv-plugins-icon-container mt-3" v-if="this.docketForm.currency !== this.localCurrency">
                                                                        <CurrencyInput type="text" class="form-control text-end" v-model="this.docketForm.rate" :precision="5" @focus="$event.target.select()" @blur="calculateLocalAmount()"
                                                                        :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0" name="rate" v-custom />
                                                                        <small v-if="this.docketForm.rate_date !== null">{{ $t('Rate Date') }}: {{ previewDate(this.docketForm.rate_date) }}</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6 ">
                                                                    <span v-tag="'amount'" > {{ $t('Amount') }} </span> 
                                                                    <span v-if="this.docketForm.currency !== this.localCurrency"> / {{ $t('Local Amount') }} </span>                                                                        
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <div class="row">
                                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container" :class="{ 'col-lg-12': this.docketForm.currency == this.localCurrency }">
                                                                            <div class="input-group mb-3">
                                                                                <CurrencyInput type="text" class="form-control text-end" v-model="docketForm.amount" @focus="$event.target.select()" @blur="calculateLocalAmount()" name="amount" v-custom />
                                                                                <ErrorMessage name="amount" class="text-danger" />
                                                                                <span class="input-group-text p-1">
                                                                                    <button type="button" class="btn btn-light btn-sm p-1" @click="this.onChangeAmount()">
                                                                                        <i class="bi bi-box-arrow-in-down-left fs-2"></i>
                                                                                    </button>
                                                                                </span>
                                                                            </div>  
                                                                        </div>
                                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container" v-if="this.docketForm.currency !== this.localCurrency">
                                                                            <CurrencyInput type="text" class="form-control text-end" v-model="docketForm.amount_local" @focus="$event.target.select()" disabled />
                                                                            <ErrorMessage name="amount" class="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                                    <span v-tag="'transaction_number'" >{{ $t('Transaction') }}</span>
                                                                    <span v-tag="'ref_number'" >{{ $t(' / Ref Number') }}</span>
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <div class="row">
                                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">                                                                        
                                                                            <input type="text" class="form-control text-end" v-model="docketForm.transaction_number" :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0" name="transaction_number" v-custom >
                                                                        </div>
                                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                            <input type="text" class="form-control" v-model="docketForm.ref_number" :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0" name="ref_number" v-custom >
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6" v-tag="'description'" >
                                                                    <span>{{ $t('Description') }}</span>
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <div class="input-group">
                                                                        <input type="text" class="form-control" v-model="docketForm.description" name="description" v-custom >
                                                                        <div class="input-group-append" v-if="this.descriptionTypeList.length > 0">
                                                                            <div class="dropdown dropup">
                                                                                <button class="btn btn-secondary border border-gray-300 dropdown-toggle rounded-start-0" type="button" @click="this.isOpenDescriptionDropdown = !this.isOpenDescriptionDropdown;">
                                                                                    <i class="bi bi-plus-lg fs-5 fw-boldest"> </i>
                                                                                </button>
                                                                                <div class="dropdown-menu px-3 dropdown-menu-end" data-bs-popper="true" :class="{ 'show': this.isOpenDescriptionDropdown }">
                                                                                    <ul class="mh-250px mb-0 overflow-scroll list-unstyled"> 
                                                                                        <li v-for="(item, index) in descriptionTypeList" v-bind:key="item">
                                                                                            <a class="dropdown-item cursor-pointer p-2 mw-500px" @click="onSelectDescription(item)"
                                                                                                :class="{'text-success': this.docketForm.description == item.description && item.description !== ''}">
                                                                                                <i class="bi bi-dash fw-boldest" v-if="this.docketForm.description !== item.description"> </i> 
                                                                                                <i class="bi bi-check-lg fw-boldest text-success" v-if="this.docketForm.description == item.description && item.description !== ''"> </i>
                                                                                                {{item.text}}  
                                                                                            </a>
                                                                                            <div class="separator separator-dashed my-1" v-if="index !== descriptionTypeList.length - 1"></div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                                    <span v-tag="'branch_id'" >{{ $t('Branch') }}</span>
                                                                    <span v-tag="'expense_center_id'" >{{ $t(' / Expense Center') }}</span>
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <div class="row">
                                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                        <Select2 v-model="this.docketForm.branch_id" :options="this.branchList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="branch_id" v-custom />
                                                                        </div>
                                                                        <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                                        <Select2 v-model="this.docketForm.expense_center_id" :options="this.expenseCenterList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="expense_center_id" v-custom />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6 " v-tag="'due_date'" >
                                                                    {{ $t('Due Date') }}
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <Datepicker :required="this.$checkIfDateFieldRequired('due_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="docketForm.due_date"  format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" name="due_date" v-custom />
                                                                </div>
                                                            </div> 
                                                        </div> 
                                                    </div>
                                                    <div class="card card-xxl-stretch" v-if="this.modalType == 'addItem'">
                                                        <div class="card-body py-2">
                                                            <div class="row mb-2">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6" >
                                                                    <span v-if="this.docketForm.item_type == 2">{{ $t('Search Debit Account') }}</span>
                                                                    <span v-if="this.docketForm.item_type == 1">{{ $t('Search Credit Account') }}</span>
                                                                </label>
                                                                <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                                    <div class="input-group">
                                                                        <input type="search" class="form-control form-control-lg" v-model="docketForm.cross_account_name" @keyup.enter="getCrossAccounts()"  :placeholder="$t('Please type an account name and press enter for search account')"  name="cross_account_name" v-custom >
                                                                        <button type="button" class="btn btn-sm btn-light-primary border border-gray-300 z-index-0" @click="getCrossAccounts()" :disabled="(docketForm.cross_account_name === undefined || docketForm.cross_account_name === null || docketForm.cross_account_name.length < 1) && !this.$getUser().is_allow_list_all_data ">
                                                                            <span v-if="!isSearchingCrossAccount"><i class="fas fa-search fs-4 me-3"></i> {{ $t('Search') }}</span>
                                                                            <span v-if="isSearchingCrossAccount">{{ $t('Searching...') }}</span> 
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                                    <span v-tag="'cross_account_id'" v-if="this.docketForm.item_type == 2">{{ $t('Debit Account') }}</span>
                                                                    <span v-tag="'cross_account_id'" v-if="this.docketForm.item_type == 1">{{ $t('Credit Account') }}</span>
                                                                </label>
                                                                <div class="col-lg-4 fv-row fv-plugins-icon-container" 
                                                                    :class="{'col-lg-5': docketForm.cross_account_id == null || docketForm.cross_account_id == '-1' || docketForm.cross_account_id == '', 'pe-0': docketForm.cross_account_id !== null && docketForm.cross_account_id !== '-1' && docketForm.cross_account_id !== ''}">
                                                                    <Select2 id="cross_account_id" v-model="docketForm.cross_account_id" :options="crossAccountsList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" @select="onChangeCrossAccount($event)"
                                                                    :disabled="this.modalType == 'editItem' && this.docketForm.is_manuel == 0" name="cross_account_id" v-custom />      
                                                                </div>
                                                                <div class="col-lg-1 fv-row fv-plugins-icon-container ps-0" v-if="docketForm.cross_account_id !== null && docketForm.cross_account_id !== '-1' && docketForm.cross_account_id !== '' && docketForm.cross_account_id !== undefined">       
                                                                    <button type="button" class="btn rounded-start-0 btn-light btn-sm pb-3 text-hover-primary border border-1 border-gray-300" @click="onOpenAccountReport(docketForm.cross_account_id, true, null)">
                                                                        <i class="fa fa-eye text-gray-600 fs-3"></i>
                                                                    </button>
                                                                </div>
                                                                <div class="col-lg-3 fv-row fv-plugins-icon-container">
                                                                    <Select2 v-if="docketForm.item_type == 1" v-model="docketForm.cross_bc_type" :options="balanceClosingCreditList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="cross_account_id" v-custom />
                                                                    <Select2 v-if="docketForm.item_type == 2" v-model="docketForm.cross_bc_type" :options="balanceClosingDebitList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_item .modal-content' }" name="cross_account_id" v-custom />
                                                                </div>
                                                            </div> 
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4" v-if="this.modalType == 'addItem' || (this.modalType == 'editItem' && this.docketForm.is_manuel != 2)">
                                            <div class="card border border-gray-300 h-100 card-flush" id="kt_contacts_list" v-if="!this.isContactInfoVisible">
                                                <div class="card-header pt-3">
                                                    <div class="card-title">
                                                        <h4 class="text-gray-800">
                                                            {{ $t('Recently Used Accounts') }}
                                                        </h4> 
                                                    </div>
                                                    <div class="card-toolbar" v-if="this.docketForm.account_id !== null && this.docketForm.account_id !== '' && this.docketForm.account_id != '-1'">
                                                        <i class="bi bi-info-circle text-primary fs-3 ms-3 cursor-pointer" @click="this.isContactInfoVisible = !this.isContactInfoVisible;"></i>
                                                    </div>                                                    
                                                </div>
                                                <div class="card-body p-5" id="kt_contacts_list_body">
                                                    <div class="scroll-y me-n5 pe-5 mh-300px mh-lg-400px h-xl-auto" :class="{'mh-500px mh-lg-600px': this.modalType == 'addItem'}" v-if="accountLatestList">
                                                        <div v-for="(item, index) in accountLatestList" v-bind:key="item">
                                                            <div class="d-flex flex-stack py-2" v-if="item.id !== '-1'">
                                                                <div class="d-flex align-items-center">
                                                                    <div class="symbol symbol-40px symbol-circle cursor-pointer">
                                                                        <span v-if="this.docketForm.account_id !== item.id" class="symbol-label fs-6 fw-bolder">
                                                                            <a href="#" class="btn btn-sm btn-icon text-hover-primary btn-circle border border-1 border-gray-200" 
                                                                                @click="onOpenAccountReport(null, false, item)">
                                                                                <i class="fa fa-eye text-gray-600 fs-2"></i>
                                                                            </a>
                                                                        </span>
                                                                        <span v-if="this.docketForm.account_id == item.id" class="svg-icon svg-icon-success svg-icon-3x">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                                                                <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"/>
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                    <a class="ms-4 cursor-pointer" @click="onSelectAccount(item)">
                                                                        <span class="fs-6 fw-bold text-gray-800 text-hover-primary mb-2 cursor-pointer">
                                                                            {{item.name}}
                                                                        </span>
                                                                        <div class="fw-bold fs-7 text-muted cursor-pointer">{{item.account_type_name}}
                                                                            <span class="h-20px border-gray-400 border-start mx-3 mt-3"></span>
                                                                            <span v-if="this.docketForm.currency == this.localCurrency" class="fw-bold fs-7 text-muted cursor-pointer">{{item.local_balance}}</span>
                                                                            <span v-if="this.docketForm.currency !== this.localCurrency" class="fw-bold fs-7 text-muted cursor-pointer">{{item.balance}}</span>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="separator separator-dashed my-3" v-if="index !== accountLatestList.length - 1"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card border border-gray-300 card-flush" v-if="this.isContactInfoVisible">
                                                <div class="card-header pt-3">
                                                    <div class="card-title">
                                                        <h4 class="text-gray-800">
                                                            {{ $t('Account Info') }}
                                                        </h4> 
                                                    </div>
                                                    <div class="card-toolbar m-0 p-0">
                                                        <i class="bi bi-x-lg fs-5 ms-3 cursor-pointer" @click="this.isContactInfoVisible = !this.isContactInfoVisible;"></i>
                                                    </div>
                                                </div>
                                                <AccountInfo :selected-account-info="this.selectedAccount"></AccountInfo>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="row mt-5">
                                    <div class="col-lg-12">
                                        <div class="card card-flush" id="kt_contacts_list">                                        
                                            <div class="d-grid gap-2"> 
                                                <button class="btn btn-success btn-lg" v-if="this.modalType == 'addItem'" type="button"
                                                    @click="this.addTransactionItem()" :disabled="this.isModalButtonDisabled">
                                                    <span class="indicator-label">{{ $t('Add To Offset') }}</span> 
                                                </button>
                                                <button type="button" class="btn btn-primary btn-lg" v-if="this.modalType == 'editItem'"
                                                    @click="this.updateTransactionItem()" :disabled="this.isModalButtonDisabled">
                                                    <span class="indicator-label">{{ $t('Update') }}</span> 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div class="tab-pane fade" :class="{'show active': this.activeTab == 'Document'}">
                                <div class="row" v-if="this.activeTab == 'Document'">
                                    <FileManager :location="2" 
                                                    :locationtype="6" 
                                                    :is_transaction="1" 
                                                    :account_a="this.docketForm.account_id" 
                                                    :account_b="this.docketForm.cross_account_id" 
                                                    :uuid="this.fileUUID"
                                                    :upload_source="1">
                                    </FileManager>
                                </div>
                            </div>
                        </div> 
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isDocketItemSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Transaction List -->
        <div class="modal fade" id="kt_modal_transaction_list" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <TransactionListModal ref="TransactionListModal" :transaction-type="5" />
        </div>
        
        <!--AccountReports-->
        <div class="modal" :class="{'fade show d-block': isViewStatementModal, 'd-none': !isViewStatementModal}" id="kt_modal_account_report_selection" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <AccountReports ref="AccountReports" :account-name="statementAccountName" :account-id="statementAccountId" :account-type="statementAccountType" />
        </div>

    </div>
</template>

<script>
import axios from "axios";
// import Swal from "sweetalert2";
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxGroupItem } from 'devextreme-vue/data-grid';
import { uuid } from 'vue3-uuid';
import TransactionListModal from '../TransactionListModal.vue';
import $ from 'jquery';
 
export default {
  name: "OffsettingCreate",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxGroupItem, TransactionListModal
  },
  data() {
    return {
        userData: this.$getUser(),
        pageSize: null,
        pageIndex: 0,
        fileUUID: null,
        activeTab: 'Transaction',
        searchbar: '',
        isOpenDescriptionDropdown: false,
        accountingTransactionList: [],
        accountLatestList: [],
        currencyList: [],
        selectedAccount: {},
        accountList: [],
        crossAccountsList: [],
        docketItemsList: [], 
        debitCreditStatusList: [
            { id: 1, text: 'Debit'},
            { id: 2, text: 'Credit'},
        ],
        recordTypeList: [
            { id: 0, text: 'Automatic'},
            { id: 1, text: 'Manuel'},
            { id: 2, text: 'Mixed'},
        ],
        balanceClosingDebitList: [
            { id: 1, text: 'Collection List'},
            { id: 2, text: 'Paid List'}
        ], 
        balanceClosingCreditList: [
            { id: 1, text: 'Collected List'},
            { id: 2, text: 'Payment List'}
        ],
        offsettingTypeList: [],
        branchList: [],
        expenseCenterList: [],
        descriptionTypeList: [],
        localCurrency: null,
        form: {
            record_date: moment(),
            docket_number: null, 
            is_manuel: 1,
            docket_sub_type: 1
        },
        docketForm: {
            id: null,
            account_id: '-1',
            cross_account_id: '-1',
            account_name: '',
            cross_account_name: '',
            transaction_date: moment(),
            due_date: moment(),
            item_type: 1,
            currency: '-1',
            rate: 0.00,
            amount: 0.00,
            transaction_number: null,
            ref_number: null,
            description: null,
            rate_date: null,
            branch_id: '-1',
            expense_center_id: '-1',
            account_type: '-1',
            bc_type: 1,
            cross_bc_type: 1,
        },
        modalType: 'addItem',
        docketSummary: {},
        isDocketSaving: false,
        isContactInfoVisible: false,
        isItemSaving: false,
        isModalButtonDisabled: false,
        menuSelection: '/OffsettingTransactionHelppage',
        iconView : false,
        isSaving: false,
        isViewStatementModal: false,
        statementAccountId: null,
        statementAccountName: null,
        statementAccountType: 1,
        isSearchingAccount: false,
        isSearchingCrossAccount: false
    };
  },
  computed: {
    setColumnTemplateForLocal(){
        return (value)=>{
            const captionValue = value.column.caption.replace('Local ', '');
            return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);          
        }
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
   isItemButtonDisabled() {
       return this.docketItemsList.find(item =>item.isSelected == true );
    },
    showPageSizeSelector() {
        return this.docketItemsList.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.docketItemsList.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    },
    getSubCredentials() {
      return (operation)=>{
        let isView = false;
        if(this.form.docket_sub_type == 1) isView = this.$isCredentialActive(35, operation, 24);
        if(this.form.docket_sub_type == 2) isView = this.$isCredentialActive(36, operation, 24);
        if(this.form.docket_sub_type == 3) isView = this.$isCredentialActive(37, operation, 24);
        return isView;
      }
    },
    getMinTransactionDate(){
        return moment(new Date(this.userData.last_transaction_date)).add(1, 'days').format('DD.MM.YYYY');
    },
  },
  methods: {
    getData() {
        this.$Progress.start(); 
        this.isInvoiceProgressing = false;        
        this.offsettingTypeList = [];
        if(this.$isCredentialActive(35, 'R', 24)) this.offsettingTypeList.push({ id: 1, text: 'General'});
        if(this.$isCredentialActive(36, 'R', 24)) this.offsettingTypeList.push({ id: 2, text: 'Accured Payroll'});
        if(this.$isCredentialActive(37, 'R', 24)) this.offsettingTypeList.push({ id: 3, text: 'Amortization'});

        this.currencyList = this.currencyList.filter((element) => {
            return element.id !== -1;
        });
        const parameters = { 
            session_id: this.$getUser().session_id,
            list_name: ["currency"]
        }; 
        axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}).then((response) => {            
            this.currencyList = response.data.currency;
            this.localCurrency = this.$getUser().currency_code;
            this.$setSessionStorage('refreshList', true);
            if (this.localCurrency == null || this.localCurrency == '' || this.localCurrency == undefined) {
                this.currencyList.unshift({id: '-1', text: 'Select an option'})
            } else {
                this.docketForm.currency = this.$getUser().currency_code;
            }            
            this.$Progress.finish();        
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    handlePropertyChange(e) {
        if(e.name == "paging") {
            this.pageIndex = e.component.pageIndex();
        }
    },
    openDocketModal(modalType) {
        try {
            this.$refs.btnAddItem.blur();
            this.$refs.btnEditItem.blur();
        } catch(Err){ console.log(Err);}

        this.activeTab = 'Transaction';
        this.modalType = modalType;
        this.isContactInfoVisible = false;
        this.isItemSaving = false;
        this.isModalButtonDisabled = false;
        this.isOpenDescriptionDropdown = false;
        this.accountList = [{id: '-1', text: 'Select an option'}];
        this.crossAccountsList = [{id: '-1', text: 'Select an option'}];

        // Form öğeleri sıfırlanır.
        this.docketForm.account_id = '-1';
        this.docketForm.account_type = '-1';
        this.docketForm.account_name = ''; 
        this.docketForm.cross_account_name = ''; 
        this.docketForm.transaction_number = null; 
        this.docketForm.amount = 0.00;
        this.docketForm.rate = 0.00;
        this.docketForm.ref_number = null;
        this.docketForm.description = null;
        this.docketForm.item_type = 1;
        this.docketForm.branch_id = '-1';
        this.docketForm.expense_center_id = '-1';
        this.docketForm.currency = this.localCurrency;
        this.docketForm.is_manuel = this.form.is_manuel;
        this.docketForm.other_account_id = '-1';
        this.docketForm.cross_account_id = '-1';
        this.docketForm.transaction_date = moment(this.form.record_date);
        this.docketForm.due_date = moment(this.form.record_date);
        this.docketForm.bc_type = 1;
        this.docketForm.cross_bc_type = 1;
        
        // Open Docket Modal in Edit Mode.
        if(this.modalType == 'editItem'){ 
            this.docketItemsList.forEach((item) => { 
                if(item.isSelected) {
                    this.docketForm.id = item.id; 
                    this.docketForm.account_id = item.account_id;
                    this.docketForm.account_name = item.account_name;
                    this.docketForm.account_type = item.account_type; 
                    this.docketForm.transaction_number = item.transaction_number; 
                    this.docketForm.amount = Math.abs(parseFloat(item.amount.replace(/,/g, '')));
                    this.docketForm.rate = item.rate.replace(',', '.');
                    this.docketForm.ref_number = item.ref_number;
                    this.docketForm.description = item.description;
                    this.docketForm.transaction_date = moment(item.transaction_date);
                    this.docketForm.due_date = moment(item.due_date);
                    this.docketForm.item_type = item.item_type; 
                    this.docketForm.currency = item.currency; 
                    this.docketForm.branch_id = item.branch_id; 
                    this.docketForm.expense_center_id = item.expense_center_id; 
                    this.docketForm.is_manuel = item.is_manuel; 
                    this.docketForm.cross_account_id = item.cross_account_id; 
                    this.docketForm.bc_type = item.bc_type;
                    this.docketForm.cross_bc_type = item.cross_bc_type;
                    if(item.expense_center_id == ''){ this.docketForm.expense_center_id = -1; }
                    if(item.branch_id == ''){ this.docketForm.branch_id = -1; }
                    this.fileUUID = item.file_id == null ? uuid.v4() : item.file_id; 
                }
            });
            this.getAccounts(true);
        }
        else{ 
            this.fileUUID = uuid.v4(); 
        }

        const parameters = {
            session_id: this.$getUser().session_id,
            list_name: ["branch", "expense_center"]
        };
        const parametersTwo = {
            session_id: this.$getUser().session_id,
            account_type: -1
        };   
        const parametersThree = {
            route: this.modalType == 'addItem'  ? 'OffsettingItemCreate' : 'OffsettingItemUpdate',
            session_id: this.$getUser().session_id
        };        
        const requestOne =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Invoice/GetLatestUsedAccounts', parametersTwo, {'Content-Type': 'application/json'});
        const requestThree =  axios.post('/api/FormValidations/GetFormFields', parametersThree, {'Content-Type': 'application/json'});
        axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
            this.branchList = responses[0].data.branch;
            this.expenseCenterList = responses[0].data.expense_center; 
             if(responses[2].data != null){  
                sessionStorage.setItem('frm_val', JSON.stringify(responses[2].data));
            }   
            if(this.modalType !== 'editItem'){ 
                this.branchList.forEach((value) => {
                    if(value.is_default){
                        this.docketForm.branch_id = value.id;
                    }
                });
                this.expenseCenterList.forEach((value) => {
                    if(value.is_default){
                        this.docketForm.expense_center_id = value.id;
                    }
                });
            }
            this.accountLatestList = responses[1].data;
            this.getDescriptions(function() {});
            this.onChangeCurrency(true);
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    getAccounts(isEditMode) {
        if((this.docketForm.account_name === null || this.docketForm.account_name === undefined || this.docketForm.account_name.length < 1) && !this.$getUser().is_allow_list_all_data) return;
        if(!isEditMode) this.docketForm.account_id = '-1';
        this.isSearchingAccount = true;
        const accountParams = {
            session_id : this.$getUser().session_id,
            account_type : -1,            
            search_text: this.docketForm.account_name == null ? "" : this.docketForm.account_name,
            search_parameter: 1  // 1: Search By Account Name
        };  
        const that = this;
        axios.post('api/CompanyAccounts/GetAccounts', accountParams, {'Content-Type': 'application/json'}).then((response) => { 
            this.accountList = response.data.account_data;
            this.accountList.forEach(element => {
                element.name = this.$unlock(element.name);
                element.account_id = this.$unlock(element.account_id);
                element.text = this.$unlock(element.text);
            });

            this.accountList.forEach((item) => {
                item.text = item.text + ' (' + item.account_type_name + ')';
            });
            this.accountList.unshift({id: '-1', name: 'Select an option', text: 'Select an option'});
            
            if(!isEditMode){
                var $optionBox = $("#account_id").select2();
                setTimeout(() => {$optionBox.select2("open");}, 100);
            }
        })
        .catch(function(error) {
            console.log(error);
        })
        .finally(function () { 
            that.isSearchingAccount = false;
        });
    },
    getCrossAccounts() {
        if((this.docketForm.cross_account_name === null ||this.docketForm.cross_account_name === '' || this.docketForm.cross_account_name === undefined || this.docketForm.cross_account_name.length < 1) && !this.$getUser().is_allow_list_all_data) return;
        this.docketForm.cross_account_id = '-1';
        this.isSearchingCrossAccount = true;
        const accountParams = {
            session_id : this.$getUser().session_id,
            account_type : -1,
            search_text: this.docketForm.cross_account_name == null ? "" : this.docketForm.cross_account_name,
            search_parameter: 1  // 1: Search By Account Name
        };
        const that = this;
        axios.post('api/CompanyAccounts/GetAccounts', accountParams, {'Content-Type': 'application/json'}).then((response) => { 
            this.crossAccountsList = response.data.account_data;
            this.crossAccountsList.forEach(element => {
                element.name = this.$unlock(element.name);
                element.account_id = this.$unlock(element.account_id);
                element.text = this.$unlock(element.text);
            });

            this.crossAccountsList.forEach((item) => {
                item.text = item.text + ' (' + item.account_type_name + ')';
            });
            this.crossAccountsList.unshift({id: '-1', name: 'Select an option', text: 'Select an option'});

            var $optionBox = $("#cross_account_id").select2();
            setTimeout(() => {$optionBox.select2("open");}, 100);
        })
        .catch(function(error) {
            console.log(error);
        })
        .finally(function () { 
            that.isSearchingCrossAccount = false;
        });
    },
    onChangeCrossAccount(event) {
        this.docketForm.cross_account_name = event.name;
    },
    onChangeAccount(event) {
        this.docketForm.account_name = event.name;
        this.docketForm.amount = 0.00;
        this.docketForm.branch_id = (event.branch_id == '' || event.branch_id == null) ? '-1' : event.branch_id;
        this.docketForm.expense_center_id = (event.expense_center_id == '' || event.expense_center_id == null) ? '-1' : event.expense_center_id;
        if (event.account_type == 3) {
            this.docketForm.currency = event.cash_currency;
            this.onChangeCurrency();
        }
        else if (event.account_type == 4) {
            this.docketForm.currency = event.bank_currency;
            this.onChangeCurrency();
        }
        else {
            this.docketForm.currency = this.localCurrency;
            this.docketForm.rate = 0;
            this.docketForm.rate_date = null;
        }
        this.getAccountDetail(event.id);
    },    
    getAccountDetail(id) { 
        if (id == '-1' || id == null || id == undefined) return;
        const params = {
            id: id,
            session_id: this.$getUser().session_id
        }; 
        axios.post('api/CompanyAccounts/GetAccountById', params, {'Content-Type': 'application/json'})
        .then((response) => {            
            response.data.account_id = this.$unlock(response.data.account_id);
            response.data.name = this.$unlock(response.data.name);
            response.data.qr_code = this.$unlock(response.data.qr_code);
            response.data.bank_short_code = this.$unlock(response.data.bank_short_code);
            response.data.bank_account_number = this.$unlock(response.data.bank_account_number);
            response.data.customer_account_number = this.$unlock(response.data.customer_account_number);
            response.data.customer_contact_name = this.$unlock(response.data.customer_contact_name);
            response.data.customer_email = this.$unlock(response.data.customer_email);
            response.data.customer_mobile_phone = this.$unlock(response.data.customer_mobile_phone);
            response.data.customer_phone = this.$unlock(response.data.customer_phone);
            response.data.customer_postcode = this.$unlock(response.data.customer_postcode);
            response.data.customer_address = this.$unlock(response.data.customer_address);
            response.data.customer_country = this.$unlock(response.data.customer_country);
            response.data.customer_city = this.$unlock(response.data.customer_city);
            response.data.customer_vat_number = this.$unlock(response.data.customer_vat_number);
            response.data.customer_phone_country_code = this.$unlock(response.data.customer_phone_country_code);
            response.data.customer_land_phone_country_code = this.$unlock(response.data.customer_land_phone_country_code);
            response.data.customer_district = this.$unlock(response.data.customer_district);
            response.data.customer_x_coordinate = this.$unlock(response.data.customer_x_coordinate);
            response.data.customer_y_coordinate = this.$unlock(response.data.customer_y_coordinate);
            response.data.customer_uprn = this.$unlock(response.data.customer_uprn);
            response.data.customer_udprn = this.$unlock(response.data.customer_udprn);
            response.data.personnel_account_number = this.$unlock(response.data.personnel_account_number);
            response.data.personnel_contact_name = this.$unlock(response.data.personnel_contact_name);
            response.data.personnel_email = this.$unlock(response.data.personnel_email);
            response.data.personnel_mobile_phone = this.$unlock(response.data.personnel_mobile_phone);
            response.data.personnel_phone = this.$unlock(response.data.personnel_phone);
            response.data.personnel_postcode = this.$unlock(response.data.personnel_postcode);
            response.data.personnel_address = this.$unlock(response.data.personnel_address);
            response.data.personnel_country = this.$unlock(response.data.personnel_country);
            response.data.personnel_city = this.$unlock(response.data.personnel_city);
            response.data.personnel_nino = this.$unlock(response.data.personnel_nino);
            response.data.personnel_phone_country_code = this.$unlock(response.data.personnel_phone_country_code);
            response.data.personnel_land_phone_country_code = this.$unlock(response.data.personnel_land_phone_country_code);
            response.data.personnel_district = this.$unlock(response.data.personnel_district);
            response.data.personnel_x_coordinate = this.$unlock(response.data.personnel_x_coordinate);
            response.data.personnel_y_coordinate = this.$unlock(response.data.personnel_y_coordinate);
            response.data.personnel_uprn = this.$unlock(response.data.personnel_uprn);
            response.data.personnel_udprn = this.$unlock(response.data.personnel_udprn);
            response.data.personnel_position_name = this.$unlock(response.data.personnel_position_name);
            response.data.supplier_account_number = this.$unlock(response.data.supplier_account_number);
            response.data.supplier_contact_name = this.$unlock(response.data.supplier_contact_name);
            response.data.supplier_email = this.$unlock(response.data.supplier_email);
            response.data.supplier_mobile_phone = this.$unlock(response.data.supplier_mobile_phone);
            response.data.supplier_phone = this.$unlock(response.data.supplier_phone);
            response.data.supplier_postcode = this.$unlock(response.data.supplier_postcode);
            response.data.supplier_address = this.$unlock(response.data.supplier_address);
            response.data.supplier_country = this.$unlock(response.data.supplier_country);
            response.data.supplier_city = this.$unlock(response.data.supplier_city);
            response.data.supplier_vat_number = this.$unlock(response.data.supplier_vat_number);
            response.data.supplier_phone_country_code = this.$unlock(response.data.supplier_phone_country_code);
            response.data.supplier_land_phone_country_code = this.$unlock(response.data.supplier_land_phone_country_code);
            response.data.supplier_district = this.$unlock(response.data.supplier_district);
            response.data.supplier_x_coordinate = this.$unlock(response.data.supplier_x_coordinate);
            response.data.supplier_y_coordinate = this.$unlock(response.data.supplier_y_coordinate);
            response.data.supplier_uprn = this.$unlock(response.data.supplier_uprn);
            response.data.supplier_udprn = this.$unlock(response.data.supplier_udprn);
            response.data.other_contact_name = this.$unlock(response.data.other_contact_name);
            response.data.other_email = this.$unlock(response.data.other_email);
            response.data.other_mobile_phone = this.$unlock(response.data.other_mobile_phone);
            response.data.other_phone = this.$unlock(response.data.other_phone);
            response.data.other_phone_country_code = this.$unlock(response.data.other_phone_country_code);
            response.data.other_land_phone_country_code = this.$unlock(response.data.other_land_phone_country_code);
            this.selectedAccount = response.data;
        })
        .catch(function () {
            this.$Progress.finish();
        });
    },
    onSelectItem(item) {
        this.docketItemsList.forEach((value) => { 
            if (item.id !== value.id) {
                value.isSelected = false;
            }
        });
    },
    onChangeAmount() {
        if(this.docketForm.account_id == null || this.docketForm.account_id == '' || this.docketForm.account_id == '-1' || this.docketForm.account_id == undefined) return;
        const parameters = {
          session_id: this.$getUser().session_id,
          account_id: this.docketForm.account_id
        };  
        axios.post('/api/CompanyAccounts/GetAccountBalance', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.docketForm.amount = this.docketForm.currency !== this.localCurrency ? Math.abs(parseFloat(response.data.total_balance.replaceAll(',', ''))) : Math.abs(parseFloat(response.data.total_balance_local.replaceAll(',', '')));
                this.calculateLocalAmount(function() {});
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    onSelectAccount(item) {
        this.docketForm.account_id = item.id;
        this.docketForm.account_name = item.name;
        this.docketForm.amount = 0.00;
        this.docketForm.branch_id = (item.branch_id == '' || item.branch_id == null) ? '-1' : item.branch_id;
        this.docketForm.expense_center_id = (item.expense_center_id == '' || item.expense_center_id == null) ? '-1' : item.expense_center_id;
        this.getAccounts(true);

        if (item.account_type == 3) {
            this.docketForm.currency = item.cash_currency;
            this.onChangeCurrency();
        }
        else if (item.account_type == 4) {
            this.docketForm.currency = item.bank_currency;
            this.onChangeCurrency();
        }
        else {
            this.docketForm.currency = this.localCurrency;
            this.docketForm.rate = 0;
            this.docketForm.rate_date = null;
        }
        this.getAccountDetail(item.id);
    }, 
    addTransactionItem(){
        this.$checkSelect2Validation('offsetItemForm');
        if(!document.forms['offsetItemForm'].reportValidity()){
            return;
        }
        if(this.docketForm.transaction_date == null || this.docketForm.transaction_date == '' || this.docketForm.transaction_date == undefined) {
            this.$swal("Warning", "Transaction Date must be valid..!", 'info');
            return;
        }
        if(this.docketForm.due_date == null || this.docketForm.due_date == '' || this.docketForm.due_date == undefined) {
            this.$swal("Warning", "Due Date must be valid..!", 'info');
            return;
        }
        if (this.docketForm.amount == null || this.docketForm.amount == '' || this.docketForm.amount == undefined) { this.docketForm.amount = 0; }
        this.isModalButtonDisabled = true;
        this.isItemSaving = true;
        const that = this;
        const params = {
            tmp_session_id: this.$getSessionStorage('tmp_session_id'), //İlk açılışta `null`
            session_id: this.$getUser().session_id,
            record_date: moment(this.form.record_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            item_type: this.docketForm.item_type,
            account_id: this.docketForm.account_id,
            cross_account_id: this.docketForm.cross_account_id,
            transaction_date: moment(this.docketForm.transaction_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            due_date: moment(this.docketForm.due_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            transaction_number: this.docketForm.transaction_number,
            currency: this.docketForm.currency,
            rate: this.docketForm.rate,
            amount: this.docketForm.amount,
            ref_number: this.docketForm.ref_number,
            description: this.docketForm.description,
            branch_id: this.docketForm.branch_id,
            expense_center_id: this.docketForm.expense_center_id,
            bc_type: this.docketForm.bc_type,
            cross_bc_type: this.docketForm.cross_bc_type,
            file_id: this.fileUUID
        };  
        if(params.expense_center_id == '-1'){ params.expense_center_id = null; }
        if(params.branch_id == '-1'){ params.branch_id = null; }
        if(params.cross_account_id == '-1'){ params.cross_account_id = null; }
        params.file_id = params.file_id == '' ? params.file_id = null :  params.file_id;

        axios.post('api/Offsetting/AddOffsetItem', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                this.$setSessionStorage('tmp_session_id', response.data.items[0].session_id);
                this.docketItemsList = response.data.items;
                this.docketSummary = response.data.totals;
                this.docketLocalBalance = response.data.local_balance;    
                this.isItemSaving = false;
                this.$refs.closeModal.click();                
            }
            this.isItemSaving = false;
        })
        .catch(function () {
            this.isItemSaving = false;
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
                that.isModalButtonDisabled = false;
            }, 500);
        });
    },
    updateTransactionItem(){ 
        this.$checkSelect2Validation('offsetItemForm');
        if(!document.forms['offsetItemForm'].reportValidity()){
            return;
        }
        if(this.docketForm.transaction_date == null || this.docketForm.transaction_date == '' || this.docketForm.transaction_date == undefined) {
            this.$swal("Warning", "Transaction Date must be valid..!", 'info');
            return;
        }
        if(this.docketForm.due_date == null || this.docketForm.due_date == '' || this.docketForm.due_date == undefined) {
            this.$swal("Warning", "Due Date must be valid..!", 'info');
            return;
        }
        if (this.docketForm.amount == null || this.docketForm.amount == '' || this.docketForm.amount == undefined) { this.docketForm.amount = 0; }
        this.isModalButtonDisabled = true;
        this.isItemSaving = true;
        const that = this;
        const params = {
            tmp_session_id: this.$getSessionStorage('tmp_session_id'), //İlk açılışta `null`
            session_id: this.$getUser().session_id,
            record_date: moment(this.form.record_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            item_type: this.docketForm.item_type,
            account_id: this.docketForm.account_id,
            transaction_date: moment(this.docketForm.transaction_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            due_date: moment(this.docketForm.due_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            transaction_number: this.docketForm.transaction_number,
            currency: this.docketForm.currency,
            rate: this.docketForm.rate,
            amount: this.docketForm.amount,
            ref_number: this.docketForm.ref_number,
            description: this.docketForm.description,
            branch_id: this.docketForm.branch_id,
            expense_center_id: this.docketForm.expense_center_id,
            id: this.docketForm.id,
            bc_type: this.docketForm.bc_type,
            cross_bc_type: this.docketForm.cross_bc_type,
            file_id: this.fileUUID
        };  
        if(params.expense_center_id == '-1'){ params.expense_center_id = null; }
        if(params.branch_id == '-1'){ params.branch_id = null; }
        if(params.cross_account_id == '-1'){ params.cross_account_id = null; }
        params.file_id = params.file_id == '' ? params.file_id = null :  params.file_id;
        axios.post('api/Offsetting/EditOffsetItem', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                this.$setSessionStorage('tmp_session_id', response.data.items[0].session_id);
                this.docketItemsList = response.data.items;
                this.docketSummary = response.data.totals;
                this.docketLocalBalance = response.data.local_balance; 
                this.$refs.closeModal.click();
                this.$Progress.finish();
            } 
            this.isItemSaving = false;
        })
        .catch(function () {
            this.isItemSaving = false;
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
                that.isModalButtonDisabled = false;
            }, 500);
        });
    },
    deleteTransactionItem() { 
        this.docketItemsList.forEach((item) => { 
                if(item.isSelected) { this.docketForm.id = item.id; }
         });
         this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                this.$Progress.start(); 
                const params = {
                        tmp_session_id: this.$getSessionStorage('tmp_session_id'), //İlk açılışta `null`
                        session_id: this.$getUser().session_id, 
                        id: this.docketForm.id
                };   
                axios.post('api/Offsetting/DeleteOffsetItem', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){   
                        if(response.data.items.length > 0){
                            this.$setSessionStorage('tmp_session_id', response.data.items[0].session_id);
                        } 
                        this.docketItemsList = response.data.items;
                        this.docketSummary = response.data.totals;
                        this.docketLocalBalance = response.data.local_balance;  
                        this.$Progress.finish();
                    } 
                    this.$Progress.finish();
                })
                .catch(function () {
                    this.$Progress.finish();
                });    
            }
        }); 
    },
    deleteAllTransactionItems() {
         this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {  
                 this.$Progress.start(); 
                const params = {
                        tmp_session_id: this.$getSessionStorage('tmp_session_id'), //İlk açılışta `null`
                        session_id: this.$getUser().session_id
                };   
                axios.post('api/Offsetting/DeleteAllOffsetItems', params, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.$removeSessionStorage('tmp_session_id');
                        this.docketItemsList = [];
                        this.docketSummary = [];
                        this.docketLocalBalance = {
                            total_credit_local: 0,
                            total_balance_local: 0,
                            total_debit_local: 0
                        };
                        this.$Progress.finish();
                    } 
                    this.$Progress.finish();
                })
                .catch(function () {
                    this.$Progress.finish();
                }); 
            }
        }); 
    },
    createOffsetHeader(){
        this.$checkSelect2Validation('poliform');
        if(!document.forms['poliform'].reportValidity()){
            return;
        }
        this.isDocketSaving = false;
        this.$swal.fire({
            title: "Are you sure you want to save this Transaction?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "SAVE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                this.$Progress.start();
                this.isDocketSaving = true;
                const params = {
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'), //İlk açılışta `null`
                    session_id: this.$getUser().session_id,
                    record_date: moment(this.form.record_date).format('YYYY-MM-DD[T]HH:mm:ss'),
                    docket_sub_type: this.form.docket_sub_type
                };  
                axios.post('api/Offsetting/CreateOffsetHeader', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){   
                        this.$setSessionStorage('record_id', response.data);
                        this.$swal("Success", "Data saved succesfully..!", 'success');   
                        this.isDocketSaving = false;  
                        this.$router.push({ name: 'OffsettingUpdate' });
                        this.$Progress.finish();
                    }  
                    this.$setSessionStorage('refreshList', true); 
                    this.$Progress.finish();
                    this.isDocketSaving = false; 
                })
                .catch(function () {
                    this.isDocketSaving = false; 
                    this.$Progress.finish();
                });
                 
            }
        }); 
    },    
    onChangeRateType(){
        const params = {
                session_id: this.$getUser().session_id,
                rate_date: moment(this.docketForm.transaction_date),
                bank_id: 1, //EUROPEAN BANK
                currency: this.docketForm.currency
        };
        params.rate_date = moment(params.rate_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
        var source = this.typeList.find((element) => element.id == this.typeSelection);
        params.bank_id = source.type; // 1: European Bank 2: HMRC 3: Live Rate
        axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.length > 0){
                this.rateList = response.data;
                this.form.rate_list_id = response.data[0].id;
                this.docketForm.rate = parseFloat(response.data[0].rate.replace(',', '.'));
                this.docketForm.rate_date = response.data[0].rate_date;
                this.calculateLocalAmount(function() {});
            }
            else{
                this.docketForm.rate = 1;
                this.docketForm.rate_date = null;
                this.rateList = null;
                this.calculateLocalAmount(function() {});
            }
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });   
    },
    onChangeCurrency(noWriteRate) {
        let originalRate = this.docketForm.rate;
        this.docketForm.rate = 0;
        if(this.docketForm.currency !== this.localCurrency){
            axios.post('/api/Rate/RateTypes', { session_id: this.$getUser().session_id }, {'Content-Type': 'application/json'})
                .then((typeResponse) => {
                        this.typeList = typeResponse.data;
                        this.typeList.forEach((item) => { 
                            if(item.is_default == 1){
                                this.typeSelection = item.id;
                            }
                        });  

                        this.$Progress.start();
                        const params = {
                                session_id: this.$getUser().session_id,
                                rate_date: moment(this.docketForm.transaction_date),
                                bank_id: 1, //EUROPEAN BANK
                                currency: this.docketForm.currency
                        };
                        params.rate_date = moment(params.rate_date).format('YYYY-MM-DD[T]HH:mm:ss'); 
                        var source = this.typeList.find((element) => element.id == this.typeSelection);
                        params.bank_id = source.type; // 1: European Bank 2: HMRC 3: Live Rate
                        axios.post('/api/Rate/GetRates', params, {'Content-Type': 'application/json'})
                        .then((response) => {
                            if(response.data.length > 0){
                                this.rateList = response.data;
                                this.form.rate_list_id = response.data[0].id;
                                this.docketForm.rate = parseFloat(response.data[0].rate.replace(',', '.'));
                                this.docketForm.rate_date = response.data[0].rate_date;
                                this.calculateLocalAmount(function() {});
                            }
                            else{
                                this.docketForm.rate = 1;
                                this.docketForm.rate_date = null;
                                this.calculateLocalAmount(function() {});
                            }
                            if(noWriteRate){
                                this.docketForm.rate = originalRate;
                            }
                            this.$Progress.finish();
                        })
                        .catch(function (error) {
                            console.log(error);
                            this.$Progress.finish();
                        });  

                })
                .catch(function (error) {
                    console.log(error); 
                }); 
        } 
    },
    calculateLocalAmount(callback){ 
        if(this.$getUser().currency_search_type == 1) {
            this.docketForm.amount_local = this.docketForm.amount / this.docketForm.rate;
        } else {
            this.docketForm.amount_local = this.docketForm.amount * this.docketForm.rate;
        }
        if(typeof callback == 'function'){
            callback();
        }
    },
    onPageIndexChanged(e) {
      this.pageIndex = e.component.pageIndex();
    },
    onPageSizeChanged(e) {
      this.pageSize = e.component.pageSize();
    },
    getRowNumber(data) {
      return (this.pageIndex * this.pageSize) + data.rowIndex + 1;
    },

    getDescriptions(callback) {
        this.isOpenDescriptionDropdown = false;
        if (this.modalType == 'addItem') { this.docketForm.description = null; }
        const params = {
            session_id: this.$getUser().session_id,
            description_location: 7, // Offsetting
            location_detail: this.docketForm.item_type,
            account_type: this.docketForm.account_type !== '-1' ? this.docketForm.account_type : 0,
            sales_type: 0
        };
        if (params.account_type == 88) {params.account_type = 8;}
        axios.post('/api/Helper/GetDescriptionTypes', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.descriptionTypeList = response.data;
            this.descriptionTypeList.forEach(element => {
                if (element.type_id == 2 && this.modalType == 'addItem') {
                    this.docketForm.description = element.description;
                }
            });
            if(typeof callback == 'function'){
                callback();
            }
        });
    },
    onSelectDescription(item) {
        this.docketForm.description = item.description;
        this.isOpenDescriptionDropdown = false;
    },
    onCustomizeColumn(e){
        if(e){
            if(!e._isUpdated){
                if(e.length > 0){ 
                    e._isUpdated = true;
                }
                e.forEach((column) => {   
                    if(column.dataField == 'amount_local'){ 
                        column.caption =  'Local Amount';
                        column.headerCellTemplate = 'headerLocalTemplate';
                    }
                });  
            } 
        } 
    },
    onActivateDocumentsTab(){
        this.activeTab = 'Document';
        if(this.fileUUID == null || this.fileUUID == '' || this.fileUUID == undefined){
            this.fileUUID = uuid.v4();         
        }
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/OffsettingTransactionHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    setSummaries(options){
        if(options.name == "amount_local"){
            if(options.summaryProcess === 'start'){
                options.totalValue = 0;
            }
            else if(options.summaryProcess === 'calculate'){
                var amount_local = parseFloat(options.value.amount_local.replaceAll(',', ''));
                (options.totalValue += amount_local);
            }
            else if(options.summaryProcess === 'finalize'){ 
                options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
            }
        }
    },
    onOpenTransactionListModal() {
        this.$refs.TransactionListModal.getData();
    },
    onOpenAccountReport(accountId, isCrossAccount, item){
        this.statementAccountId = accountId;
        if(isCrossAccount) {
            this.crossAccountsList.forEach(element => { 
                if(element.id == accountId) { 
                    this.statementAccountName = element.name; 
                    this.statementAccountType = element.account_type; 
                } 
            });
        }
        else {
            this.accountList.forEach(element => { 
                if(element.id == accountId) {
                    this.statementAccountName = element.name;                     
                    this.statementAccountType = element.account_type; 
                } 
            });
        }

        if(item !== null && item !== undefined && item !== '') {
            this.statementAccountId = item.id;
            this.statementAccountName = item.name;
            this.statementAccountType = item.account_type; 
        }

        let accountInfo = {
            id: this.statementAccountId, 
            name: this.statementAccountName
        };
        this.isViewStatementModal = true;
        this.$refs.AccountReports.getData(accountInfo);
        this.$refs.AccountReports.setModalCriteriasToDefault();
    }
  },
  created() {
    this.helpPageViewIcon();
    this.$setSessionStorage('refreshList', true);
    this.$removeSessionStorage('tmp_session_id');
    this.fileUUID = uuid.v4(); 
    this.getData();
  },
};
</script>