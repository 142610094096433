<template> 
  <div> 
    <div class="ms-4">
        <div class="page-title flex-row me-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Account Transactions') }}</h1> 
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-primary">{{ $t('Reports') }}</li>    
                <li class="breadcrumb-item text-primary">{{ $t('Account Reports') }}</li>  
                <li class="breadcrumb-item text-gray-600">{{ $t('Account Transactions') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                </li> 
            </ul> 
        </div>  
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
        <div class="card">
            <div class="row fv-row border-0 pt-6 px-9">
                <div class="col-lg-9">
                    <div class="fv-row mb-4 row"> 
                        <div class="col-lg">
                            <label class="fs-6 form-label mt-3">
                                <span>{{ $t('Start Date') }}</span>
                            </label>
                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.first_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                        </div>  
                         <div class="col-lg">
                            <label class="fs-6 form-label mt-3">
                                <span>{{ $t('End Date') }}</span>
                            </label>
                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.last_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                        </div>   
                        <div class="col-lg">
                            <label class="fs-6 form-label mt-3">
                                <span>{{ $t('Transaction Type') }}</span>
                            </label>
                            <Select2 v-model="this.form.transaction_type" :options="this.transactionTypeList" :settings="{ width: '100%' }" @select="onChangeTransactionType($event, true)"/>  
                        </div>
                        <div class="col-lg">
                            <label class="fs-6 form-label mt-3">
                                <span>{{ $t('Transaction Sub Type') }}</span>
                            </label>
                            <Select2 v-model="this.form.transaction_subtype" :options="this.transactionSubTypeList" :settings="{ width: '100%' }"/>  
                        </div>
                         <div class="col-lg">
                            <label class="fs-6 form-label mt-3">
                                <span>{{ $t('Account Type') }}</span>
                            </label>
                            <Select2 v-model="this.form.account_type" :options="this.accountTypeList" :settings="{ width: '100%' }"/>  
                        </div>
                        <div class="col-lg">
                            <label class="fs-6 form-label mt-3">
                                <span>{{ $t('Currency') }}</span>
                            </label>
                            <Select2 v-model="this.form.currency" :options="this.currencyList" :settings="{ width: '100%' }"/>  
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base" v-if="this.$isCredentialActive(66,'R')">  
                        <Popper hover placement="top">
                                    <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                        <i class="bi bi-eraser-fill fs-4"> </i>
                                    </button>
                                    <template #content>
                                        <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                                    </template>
                                </Popper>
                        <button type="button" class="btn btn-light-primary border border-primary" @click="getListData()" data-kt-indicator="on">
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span> 
                            <span v-if="!this.isListProgressing"> {{ $t('List Transactions') }} </span> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>
                    </div> 
                </div>
            </div> 
            <div class="card-body pt-3">  
                <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">

                        <ListOptions :name="this.$options.name" @click="SaveDXStateToStorage" ref="listOptionComponent"></ListOptions>
                         
                         <DxDataGrid id="gridContainer" :show-borders="true" 
                                     :data-source="lists" 
                                     :allow-column-reordering="true" 
                                     :allow-column-resizing="true" 
                                     :column-auto-width="true"  
                                     :customize-columns="onCustomizeColumn"
                                     :row-alternatin-enabled="true"
                                     :hover-state-enabled="true" 
                                     :show-row-lines="true" 
                                     :ref="'dataGrid'"
                                     @exporting="this.$onExporting($event , 'Transaction-List')">  


                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="pageSize"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>    
                                <DxSearchPanel :visible="true"/>
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                />
                                <DxColumn data-field="record_date" data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Record Date')" header-cell-template="record-date-header"/>
                                <DxColumn data-field="account_code" :caption="$t('Account Code')" header-cell-template="account-code-header"/>
                                <DxColumn data-field="account_name" :caption="$t('Account Name')" header-cell-template="account-name-header"/>
                                <DxColumn data-field="account_type_name" :caption="$t('Account Type')" header-cell-template="account-type-header"/>
                                <DxColumn data-field="transaction_type_name" :caption="$t('Transaction Type')" header-cell-template="transaction-type-header"/>
                                <DxColumn data-field="transaction_subtype_name" :caption="$t('Transaction Sub Type')" header-cell-template="transaction-subtype-header"/>
                                <DxColumn data-field="transaction_no" :caption="$t('Transaction No')" header-cell-template="transaction-no-header"/>
                                <DxColumn data-field="transaction_date"  data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Transaction Date')" header-cell-template="transaction-date-header"/>
                                <DxColumn  data-field="amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" alignment="right"/>
                                <DxColumn data-field="currency" :caption="$t('Curr.')"/>
                                <DxColumn data-field="debit_credit" :caption="$t('Debit/Credit')" header-cell-template="debit-credit-header"/>
                                <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right"/>
                                <DxColumn cssClass="bg-light-indigo" data-field="amount_local" :sorting-method="this.$numericSort" :caption="$t('Local Amount')"  alignment="right" header-cell-template="local-amount-header"/>
                                <DxColumn data-field="transaction_due_date"  data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Due Date')" header-cell-template="due-date-header"/> 
                                <DxColumn data-field="description" :caption="$t('Description')"/> 

                                <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur --> 
                                <template #headerLocalTemplate="{ data: data }">
                                    <div v-html="setColumnTemplateForLocal(data)"></div> 
                                </template>

                                <template #record-date-header>
                                    <div>{{ $t('Record') }}</div><div>{{ $t('Date') }}</div>
                                </template>
                                <template #account-name-header>
                                    <div>{{ $t('Account') }}</div><div>{{ $t('Name') }}</div>
                                </template>
                                <template #account-code-header>
                                    <div>{{ $t('Account') }}</div><div>{{ $t('Code') }}</div>
                                </template>
                                <template #account-type-header>
                                    <div>{{ $t('Account') }}</div><div>{{ $t('Type') }}</div>
                                </template>
                                <template #transaction-type-header>
                                    <div>{{ $t('Transaction') }}</div><div>{{ $t('Type') }}</div>
                                </template>
                                <template #transaction-subtype-header>
                                    <div>{{ $t('Transaction Sub') }}</div><div>{{ $t('Type') }}</div>
                                </template>
                                <template #transaction-no-header>
                                    <div>{{ $t('Transaction') }}</div><div>{{ $t('No') }}</div>
                                </template>
                                <template #transaction-date-header>
                                    <div>{{ $t('Transaction') }}</div><div>{{ $t('Date') }}</div>
                                </template>
                                <template #local-amount-header>
                                    <div>{{ $t('Local') }}</div><div>{{ $t('Amount') }}</div>
                                </template>
                                <template #due-date-header>
                                    <div>{{ $t('Due') }}</div><div>{{ $t('Date') }}</div>
                                </template>
                                <template #debit-credit-header>
                                    <div>{{ $t('Debit') }}</div><div>{{ $t('Credit') }}</div>
                                </template>

                                <DxSummary :calculate-custom-summary="setDetailSummaries">
                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="record_date"/>
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="t_amount_local"
                                        summary-type="custom"
                                        show-in-column="amount_local"
                                        display-format="{0}"
                                    /> 
                                    <DxTotalItem
                                        name="t_amount_local"
                                        summary-type="custom"
                                        show-in-column="amount_local"
                                        display-format="{0}"
                                    />
                                </DxSummary>

                                <DxStateStoring
                                        :enabled="true"
                                        type="custom"
                                        :storage-key="this.storageKey"
                                        :ref="dataGrid" 
                                        :saving-timeout="1000"
                                        :custom-load="LoadDXState"
                                    />
                        </DxDataGrid>
                    </div>
                </div> 
            </div>
        </div> 
    </div>  
  </div>  
</template>
<script>
import axios from 'axios' 
// import Swal from 'sweetalert2';
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSummary, DxTotalItem, DxSearchPanel, DxGroupItem, DxStateStoring, DxScrolling } from 'devextreme-vue/data-grid';
 
export default {
  name: "AccountTransactionList",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSummary, DxTotalItem, DxSearchPanel, DxGroupItem, DxStateStoring, DxScrolling
  },
  data() {
    return { 
        storageKey: 'dx_' + this.$options.name,
        isFilterOpen: false,
        pageSize: 100,
        page: 1, 
        page_size: 20,
        lists: [],
        transactionTypeList: [],
        transactionSubTypeList: [],
        accountTypeList: [],
        currencyList: [],
        localCurrency: null,
        form:  {
            first_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            last_date: moment(),
            transaction_type: 0,
            transaction_subtype: 0,
            account_type: 0,
            currency: '-1'
        },
        isListProgressing: false,
        menuSelection: '/AccountTransactionsHelppage',
        iconView : false
    };
  },
  computed: {
    setColumnTemplateForLocal(){
        return (value)=>{
            const captionValue = value.column.caption.replace('Local ', '');
            return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);          
        }
    },
    searchableList() {
      return this.lists.slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    showPageSizeSelector() {
        return this.lists.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.lists.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(1000);
        if(loop > 1) arr.push('all');
        return arr;
    }
  },
  methods: {
    getData() {
        this.$Progress.start();
        this.transactionTypeList = []; 
        this.transactionSubTypeList = [];
        this.accountTypeList = [];
        this.currencyList = []; 

        const parameters1 = {
            session_id: this.$getUser().session_id,
            tip: 2 //Transaction Types
        };  
        const parameters2 = {
            session_id: this.$getUser().session_id,
            tip: 16 //Account Types
        };
        const parameters3 = {
            session_id: this.$getUser().session_id,
            list_name: ["currency"]
        };
        
        const requestOne =  axios.post('/api/Helper/GetConst', parameters1, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GetConst', parameters2, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/Helper/GeneralLists', parameters3, {'Content-Type': 'application/json'}); 
        
        axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {
                this.transactionTypeList = responses[0].data; 
                this.accountTypeList = responses[1].data;
                this.accountTypeList = this.accountTypeList.filter(account => !( account.deger === "7"));
                this.currencyList = responses[2].data.currency;
                this.transactionTypeList.unshift({ id: 0, text: 'ALL'});
                this.transactionSubTypeList.unshift({ id: 0, text: 'ALL'}); 
                this.accountTypeList.unshift({ id: 0, text: 'ALL'});
                this.currencyList.unshift({ id: '-1', text: 'ALL'}); 
                this.localCurrency = this.$getUser().currency_code; 
                this.$Progress.finish(); 
            })
          )
          .catch(errors => { 
            console.error(errors); 
            this.$Progress.finish(); 
        });   
    }, 
    getListData() {
        this.$Progress.start();
        this.isListProgressing = true;
        this.form.first_date = (this.form.first_date == null || this.form.first_date == '') ? moment() : this.form.first_date;
        this.form.last_date = ( this.form.last_date == null || this.form.last_date == '') ? moment () : this.form.last_date;
        const params = {
            session_id: this.$getUser().session_id,
            first_date: moment(this.form.first_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            last_date: moment(this.form.last_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            transaction_type: this.form.transaction_type,
            transaction_subtype: this.form.transaction_subtype,
            account_type: this.form.account_type,
            currency: this.form.currency,
            is_accounted: '-1',
            ct: 1
        };
        params.currency = this.form.currency == '-1' ? null : this.form.currency;
        axios.post('/api/Transaction/GetAllTransactionList', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.lists = response.data;
            this.page = 1;
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        });  
    }, 
    setCriteriasToDefault() {   
        this.form = {
            first_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            last_date: moment(),
            transaction_type: 0,
            transaction_subtype: 0,
            account_type: 0,
            currency: '-1'
        };
        this.lists = [];
    },
    setDetailSummaries(options){  
        if(options.name == "t_amount_local"){
            if(options.summaryProcess === 'start'){
                options.totalValue = 0;
            }
            else if(options.summaryProcess === 'calculate'){
                var amountLocalValue = parseFloat(options.value.amount_local.replaceAll(',', ''));
                (options.totalValue += amountLocalValue);
            }
            else if(options.summaryProcess === 'finalize'){ 
                options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
            }
        }   
    },
    onChangeTransactionType(e, isSetDefault){
        var finalDeger = 0;
        if(e.tip == 2 && e.deger == 5){
            finalDeger = 1; // Invoice için
        }
        if(e.tip == 2 && e.deger == 6){
            finalDeger = 3; // Bank için
        }
        if(e.tip == 2 && e.deger == 7){
            finalDeger = 3; // Cash için
        }
        if(e.tip == 2 && e.deger == 10){
            finalDeger = 10; // Cheque için
        } 
        if(e.tip == 2 && e.deger == 15){
            finalDeger = 27; // Policy için
        } 
        if(finalDeger == 0){
            this.transactionSubTypeList = [];
            this.transactionSubTypeList.unshift({ id: 0, text: 'ALL'}); 
            if(isSetDefault) {
                this.form.transaction_subtype = 0;
            }
            return;
        }
        this.$Progress.start();
        this.transactionSubTypeList = [];
        const params = {
            session_id: this.$getUser().session_id,
            tip: finalDeger //Transaction Types
        };
        axios.post('/api/Helper/GetConst', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.transactionSubTypeList = response.data; 
            this.transactionSubTypeList.unshift({ id: 0, text: 'ALL'}); 
            if(isSetDefault) {
                this.form.transaction_subtype = 0;
            }
            this.$Progress.finish();

        })
        .catch(function (error) {
            console.log(error); 
            this.$Progress.finish(); 
        });
    },
    onCustomizeColumn(e){
        if(e){
            if(!e._isUpdated){
                if(e.length > 0){ 
                    e._isUpdated = true;
                }
                e.forEach((column) => {   
                     if(column.dataField == 'amount_local'){ 
                         column.caption =  'Local Amount';
                         column.headerCellTemplate = 'headerLocalTemplate';
                     }
                });  
            } 
        } 
    },
    /** DX STATE OPERATIONS **/  
    LoadDXState() {
        return JSON.parse(localStorage.getItem(this.storageKey));
    }, 
    SaveDXStateToStorage() { 
        const state = this.$refs.dataGrid.instance.state();
        localStorage.setItem(this.storageKey, JSON.stringify(state));
        this.$refs.listOptionComponent.getState();        
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/AccountTransactionsHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
  },
  created() { 
    this.helpPageViewIcon();
    this.$parent.$parent.isFullWidth = true;
    this.lists = [];
    this.getData();
  }
}; 
</script>