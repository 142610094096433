<template>
  <div>
    <div class="content flex-row-fluid mt-2" id="kt_content">
        <div class="card" v-if="viewType !== 1">
            <div class="card-body pt-0">
                <div class="row px-0">
                    <div class="col-lg-9">
                        <form class="form fv-plugins-bootstrap5 fv-plugins-framework rounded-2">
                            <div class="fv-row mb-7 row">
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Start Date') }}</span>
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.start_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers @update:modelValue="getData(true)" :monthChangeOnScroll="'inverse'" :enableTimePicker="false" ></Datepicker>
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('End Date') }}</span>
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers @update:modelValue="getData(true)" :monthChangeOnScroll="'inverse'" :enableTimePicker="false" ></Datepicker>
                                </div>
                                <div class="col-lg" v-if="this.viewType !== 1">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Invoice Type') }}</span>
                                    </label>
                                    <Select2 v-model="form.invoice_type" :options="invoiceTypeList" @select="getData(true)" :settings="{width: '100%', dropdownParent: '#kt_modal_transaction_list .modal-content'}" />
                                </div>
                                <div class="col-lg" v-if="this.viewType !== 1">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Sales Type') }}</span>
                                    </label>
                                    <Select2 v-model="form.sales_type" :options="salesTypeList" @select="getData(true)" :settings="{width: '100%', dropdownParent: '#kt_modal_transaction_list .modal-content'}" />
                                </div>
                                <div class="col-lg" v-if="this.viewType !== 1">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Process Type') }}</span>
                                    </label>
                                    <Select2 v-model="form.process_type" :options="processList" @select="getData(true)" :settings="{width: '100%', dropdownParent: '#kt_modal_transaction_list .modal-content'}" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-3" v-if="this.$isCredentialActive(20,'R')">
                        <div class="d-flex justify-content-end align-items-center mt-8" data-kt-customer-table-toolbar="base">
                            <Popper hover placement="bottom" v-if="viewType !== 1">
                                <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                    <i class="bi bi-eraser-fill fs-4"> </i>
                                </button>
                                <template #content>
                                    <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                                </template>
                            </Popper>
                            <button v-if="this.$getUser().is_allow_list_all_data" type="button" class="btn btn-light-primary border border-primary" @click="getData(false)" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <span v-if="!this.isListProgressing"> {{ $t('List Invoices') }}</span>
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="separator my-5 mt-0 border-gray-300"></div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <DxDataGrid id="gridContainer" :show-borders="true"
                        key-expr="id"
                        :data-source="invoiceList"
                        :allow-column-reordering="true"
                        :allow-column-resizing="true"
                        :column-auto-width="true"
                        :row-alternatin-enabled="true"
                        :hover-state-enabled="true"
                        :show-row-lines="true"
                        @exporting="this.$onExporting($event , 'Invoice-Transaction-List')"
                        :ref="'dataGrid'"
                        :customize-columns="onCustomizeColumn">

                        <DxFilterRow :visible="true"/>
                        <DxHeaderFilter :visible="true"/>
                        <DxGroupPanel :visible="true"/>
                        <DxExport :enabled="false" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                        <DxPaging :page-size="10"/>
                        <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                        <DxSearchPanel :visible="false"/>
                        <!-- <DxScrolling
                            mode="virtual"
                            row-rendering-mode="virtual"
                        /> -->
                        <DxColumn v-if="this.viewType == 1" data-field="#" caption="#" alignment="center" cell-template="select-cell-template"/>
                        <DxColumn data-field="invoice_date" :caption="$t('Record Date')" data-type="date" alignment="right" format="dd.MM.yyyy" header-cell-template="record-date-header" cell-template="invoice_date-cell-template"/>
                        <DxColumn data-field="invoice_type_name" :caption="$t('Invoice Type')" header-cell-template="invoice-type-header" cell-template="invoice_type_name-cell-template"/>
                        <DxColumn data-field="sales_type" :caption="$t('Sales Type')" alignment="left" header-cell-template="sales-type-header" cell-template="sales_type-cell-template"/>
                        <DxColumn data-field="invoice_no" :caption="$t('Invoice Number')" header-cell-template="invoice-number-header" cell-template="invoice_no-cell-template"/>
                        <DxColumn data-field="account_name" :caption="$t('Account')" cell-template="account_name-cell-template"/>
                        <DxColumn data-field="gross_amount" :sorting-method="this.$numericSort" :caption="$t('Grand Total')" header-cell-template="grand-total-header" alignment="right" cell-template="gross_amount-cell-template"/>
                        <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right" cell-template="rate-cell-template"/>
                        <DxColumn data-field="gross_amount_local" :sorting-method="this.$numericSort" :caption="$t('Grand Total Local')" header-cell-template="grand-total-local-header" alignment="right" cell-template="gross_amount_local-cell-template"/>
                        <DxColumn data-field="process_type_name" :caption="$t('Process Type')" header-cell-template="process_type_name-header" cell-template="process_type_name-cell-template"/>
                        <!-- <DxColumn data-field="isPopover" :caption="$t('Security')" cell-template="is-popover-cell-template"/> -->
                        <DxColumn data-field="created_by" :caption="$t('Created By')" cell-template="created-by-cell-template"/>

                        <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur -->
                        <template #headerLocalTemplate="{ data: data }">
                            <div v-html="setColumnTemplateForLocal(data)"></div>
                        </template>

                        <template #record-date-header>
                            <div>{{ $t('Record') }}</div><div>{{ $t('Date') }}</div>
                        </template>
                        <template #invoice-type-header>
                            <div>{{ $t('Invoice') }}</div><div>{{ $t('Type') }}</div>
                        </template>
                        <template #sales-type-header>
                            <div>{{ $t('Sales') }}</div><div>{{ $t('Type') }}</div>
                        </template>
                        <template #invoice-number-header>
                            <div>{{ $t('Invoice') }}</div><div>{{ $t('Number') }}</div>
                        </template>
                        <template #grand-total-header>
                            <div>{{ $t('Grand') }}</div><div>{{ $t('Total') }}</div>
                        </template>
                        <template #grand-total-local-header>
                            <div>{{ $t('Grand Total') }}</div><div>{{ $t('Local') }}</div>
                        </template>
                        <template #process_type_name-header>
                            <div>{{ $t('Process') }}</div><div>{{ $t('Type') }}</div>
                        </template>

                        <!-- Cell Templates -->
                        <template #select-cell-template="{ data }" v-if="this.viewType == 1">
                            <button type="button" class="btn btn-xs btn-primary" @click="onSelectInvoice(data.data)">{{ $t('Select') }}</button>
                        </template>
                        <template #invoice_date-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                {{ previewDate(data.data.invoice_date) }}
                            </a>
                        </template>
                        <template #invoice_type_name-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                <div>
                                    <span class="badge badge-circle badge-light me-3" v-if="viewType !== 1">
                                        <i class="bi bi-pencil text-gray-900"></i>
                                    </span>
                                    <span class="fw-bolder" :class="{ 'text-danger': data.data.invoice_type == 4 || data.data.invoice_type == 3 }">
                                        {{ data.data.invoice_type_name }}
                                    </span>
                                </div>
                            </a>
                        </template>
                        <template #sales_type-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                <span v-if="data.data.sales_type == 1"> {{ $t('Domestic') }} </span>
                                <span v-if="data.data.sales_type == 2"> {{ $t('Overseas') }} </span>
                            </a>
                        </template>
                        <template #invoice_no-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                {{ data.data.invoice_no }}
                            </a>
                        </template>
                        <template #account_name-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                {{ data.data.account_name }}
                            </a>
                        </template>
                        <template #gross_amount-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                {{ data.data.gross_amount }} {{ data.data.currency }}
                            </a>
                        </template>
                        <template #rate-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                {{ data.data.rate }}
                            </a>
                        </template>
                        <template #gross_amount_local-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                {{ data.data.gross_amount_local }} {{ data.data.local_currency }}
                            </a>
                        </template>
                        <template #process_type_name-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                {{ data.data.process_type_name }}
                            </a>
                        </template>
                        <template #created-by-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                {{ data.data.created_by }}
                            </a>
                        </template>

                        <DxSummary :calculate-custom-summary="setSummaries">
                            <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="invoice_date"/>
                            <DxGroupItem
                                :show-in-group-footer="true"
                                name="gross_amount_local"
                                summary-type="custom"
                                show-in-column="gross_amount_local"
                                display-format="{0}"
                            />
                            <DxTotalItem
                                name="gross_amount_local"
                                summary-type="custom"
                                show-in-column="gross_amount_local"
                                display-format="{0}"
                            />
                        </DxSummary>
                        <DxStateStoring :enabled="true" type="custom" :storage-key="this.storageKey" :ref="dataGrid"  :saving-timeout="1000" :custom-load="LoadDXState" />
                    </DxDataGrid>
                </div>
            </div>
        </div>

        <div class="card" v-if="viewType == 1">
            <!-- Fatura listesi -->
            <div class="card-body pt-0" v-if="(selectedInvoice == null || selectedInvoice == undefined || selectedInvoice == '')">
                <div class="row px-0">
                    <div class="col-lg-9">
                        <form class="form fv-plugins-bootstrap5 fv-plugins-framework rounded-2">
                            <div class="fv-row mb-7 row">
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Start Date') }}</span>
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.start_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers @update:modelValue="getData(true)" :monthChangeOnScroll="'inverse'" :enableTimePicker="false" ></Datepicker>
                                </div>
                                <div class="col-lg">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('End Date') }}</span>
                                    </label>
                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers @update:modelValue="getData(true)" :monthChangeOnScroll="'inverse'" :enableTimePicker="false" ></Datepicker>
                                </div>
                                <div class="col-lg" v-if="this.viewType !== 1">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Invoice Type') }}</span>
                                    </label>
                                    <Select2 v-model="form.invoice_type" :options="invoiceTypeList" @select="getData(true)" :settings="{width: '100%', dropdownParent: '#kt_modal_transaction_list .modal-content'}" />
                                </div>
                                <div class="col-lg" v-if="this.viewType !== 1">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Sales Type') }}</span>
                                    </label>
                                    <Select2 v-model="form.sales_type" :options="salesTypeList" @select="getData(true)" :settings="{width: '100%', dropdownParent: '#kt_modal_transaction_list .modal-content'}" />
                                </div>
                                <div class="col-lg" v-if="this.viewType !== 1">
                                    <label class="fs-6 fw-bold form-label mt-3">
                                        <span>{{ $t('Process Type') }}</span>
                                    </label>
                                    <Select2 v-model="form.process_type" :options="processList" @select="getData(true)" :settings="{width: '100%', dropdownParent: '#kt_modal_transaction_list .modal-content'}" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-3" v-if="this.$isCredentialActive(20,'R')">
                        <div class="d-flex justify-content-end align-items-center mt-8" data-kt-customer-table-toolbar="base">
                            <Popper hover placement="bottom" v-if="viewType !== 1">
                                <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                    <i class="bi bi-eraser-fill fs-4"> </i>
                                </button>
                                <template #content>
                                    <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                                </template>
                            </Popper>
                            <button v-if="this.$getUser().is_allow_list_all_data" type="button" class="btn btn-light-primary border border-primary" @click="getData(false)" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                </svg>
                                </span>
                                <span v-if="!this.isListProgressing"> {{ $t('List Invoices') }}</span>
                                <span class="indicator-progress" v-if="this.isListProgressing">
                                    {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="separator my-5 mt-0 border-gray-300"></div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <DxDataGrid id="gridContainer" :show-borders="true"
                        key-expr="id"
                        :data-source="invoiceList"
                        :allow-column-reordering="true"
                        :allow-column-resizing="true"
                        :column-auto-width="true"
                        :row-alternatin-enabled="true"
                        :hover-state-enabled="true"
                        :show-row-lines="true"
                        @exporting="this.$onExporting($event , 'Invoice-Transaction-List')"
                        :ref="'dataGrid'"
                        :customize-columns="onCustomizeColumn">

                        <DxFilterRow :visible="true"/>
                        <DxHeaderFilter :visible="true"/>
                        <DxGroupPanel :visible="true"/>
                        <DxExport :enabled="false" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                        <DxPaging :page-size="10"/>
                        <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                        <DxSearchPanel :visible="false"/>
                        <!-- <DxScrolling
                            mode="virtual"
                            row-rendering-mode="virtual"
                        /> -->
                        <DxColumn v-if="this.viewType == 1" data-field="#" caption="#" alignment="center" cell-template="select-cell-template"/>
                        <DxColumn data-field="invoice_date" :caption="$t('Record Date')" data-type="date" alignment="right" format="dd.MM.yyyy" header-cell-template="record-date-header" cell-template="invoice_date-cell-template"/>
                        <DxColumn data-field="invoice_type_name" :caption="$t('Invoice Type')" header-cell-template="invoice-type-header" cell-template="invoice_type_name-cell-template"/>
                        <DxColumn data-field="sales_type" :caption="$t('Sales Type')" alignment="left" header-cell-template="sales-type-header" cell-template="sales_type-cell-template"/>
                        <DxColumn data-field="invoice_no" :caption="$t('Invoice Number')" header-cell-template="invoice-number-header" cell-template="invoice_no-cell-template"/>
                        <DxColumn data-field="account_name" :caption="$t('Account')" cell-template="account_name-cell-template"/>
                        <DxColumn data-field="gross_amount" :sorting-method="this.$numericSort" :caption="$t('Grand Total')" header-cell-template="grand-total-header" alignment="right" cell-template="gross_amount-cell-template"/>
                        <DxColumn data-field="rate" :caption="$t('Rate')" alignment="right" cell-template="rate-cell-template"/>
                        <DxColumn data-field="gross_amount_local" :sorting-method="this.$numericSort" :caption="$t('Grand Total Local')" header-cell-template="grand-total-local-header" alignment="right" cell-template="gross_amount_local-cell-template"/>
                        <DxColumn data-field="process_type_name" :caption="$t('Process Type')" header-cell-template="process_type_name-header" cell-template="process_type_name-cell-template"/>
                        <!-- <DxColumn data-field="isPopover" :caption="$t('Security')" cell-template="is-popover-cell-template"/> -->
                        <DxColumn data-field="created_by" :caption="$t('Created By')" cell-template="created-by-cell-template"/>

                        <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur -->
                        <template #headerLocalTemplate="{ data: data }">
                            <div v-html="setColumnTemplateForLocal(data)"></div>
                        </template>

                        <template #record-date-header>
                            <div>{{ $t('Record') }}</div><div>{{ $t('Date') }}</div>
                        </template>
                        <template #invoice-type-header>
                            <div>{{ $t('Invoice') }}</div><div>{{ $t('Type') }}</div>
                        </template>
                        <template #sales-type-header>
                            <div>{{ $t('Sales') }}</div><div>{{ $t('Type') }}</div>
                        </template>
                        <template #invoice-number-header>
                            <div>{{ $t('Invoice') }}</div><div>{{ $t('Number') }}</div>
                        </template>
                        <template #grand-total-header>
                            <div>{{ $t('Grand') }}</div><div>{{ $t('Total') }}</div>
                        </template>
                        <template #grand-total-local-header>
                            <div>{{ $t('Grand Total') }}</div><div>{{ $t('Local') }}</div>
                        </template>
                        <template #process_type_name-header>
                            <div>{{ $t('Process') }}</div><div>{{ $t('Type') }}</div>
                        </template>

                        <!-- Cell Templates -->
                        <template #select-cell-template="{ data }" v-if="this.viewType == 1">
                            <button type="button" class="btn btn-xs btn-primary" @click="onSelectInvoice(data.data)">{{ $t('Select') }}</button>
                        </template>
                        <template #invoice_date-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                {{ previewDate(data.data.invoice_date) }}
                            </a>
                        </template>
                        <template #invoice_type_name-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                <div>
                                    <span class="badge badge-circle badge-light me-3" v-if="viewType !== 1">
                                        <i class="bi bi-pencil text-gray-900"></i>
                                    </span>
                                    <span class="fw-bolder" :class="{ 'text-danger': data.data.invoice_type == 4 || data.data.invoice_type == 3 }">
                                        {{ data.data.invoice_type_name }}
                                    </span>
                                </div>
                            </a>
                        </template>
                        <template #sales_type-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                <span v-if="data.data.sales_type == 1"> {{ $t('Domestic') }} </span>
                                <span v-if="data.data.sales_type == 2"> {{ $t('Overseas') }} </span>
                            </a>
                        </template>
                        <template #invoice_no-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                {{ data.data.invoice_no }}
                            </a>
                        </template>
                        <template #account_name-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                {{ data.data.account_name }}
                            </a>
                        </template>
                        <template #gross_amount-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                {{ data.data.gross_amount }} {{ data.data.currency }}
                            </a>
                        </template>
                        <template #rate-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                {{ data.data.rate }}
                            </a>
                        </template>
                        <template #gross_amount_local-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                {{ data.data.gross_amount_local }} {{ data.data.local_currency }}
                            </a>
                        </template>
                        <template #process_type_name-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                {{ data.data.process_type_name }}
                            </a>
                        </template>
                        <template #created-by-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary" @click="updateInvoice(data.data.id)">
                                {{ data.data.created_by }}
                            </a>
                        </template>

                        <DxSummary :calculate-custom-summary="setSummaries">
                            <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="invoice_date"/>
                            <DxGroupItem
                                :show-in-group-footer="true"
                                name="gross_amount_local"
                                summary-type="custom"
                                show-in-column="gross_amount_local"
                                display-format="{0}"
                            />
                            <DxTotalItem
                                name="gross_amount_local"
                                summary-type="custom"
                                show-in-column="gross_amount_local"
                                display-format="{0}"
                            />
                        </DxSummary>
                        <DxStateStoring :enabled="true" type="custom" :storage-key="this.storageKey" :ref="dataGrid"  :saving-timeout="1000" :custom-load="LoadDXState" />
                    </DxDataGrid>
                </div>
            </div>


            <!-- Fatura itemlerinin listesi -->
            <div class="card-header" v-if="(selectedInvoice !== null && selectedInvoice !== undefined && selectedInvoice !== '')">
                <div class="card-title">
                    {{ $t('Select Items from')}} &nbsp; <b> {{previewDate(selectedInvoice.invoice_date)}} {{selectedInvoice.invoice_type_name}} ({{selectedInvoice.invoice_no}}) </b>
                </div>
            </div>
            <div class="card-body" v-if="(selectedInvoice !== null && selectedInvoice !== undefined && selectedInvoice !== '')">
                <div class="table-responsive-lg">
                    <DxDataGrid id="gridContainer" :show-borders="true"
                        key-expr="id"
                        :data-source="invoiceItemList"
                        :allow-column-reordering="true"
                        :allow-column-resizing="true"
                        :column-auto-width="true"
                        :row-alternatin-enabled="true"
                        :hover-state-enabled="true"
                        :show-row-lines="true"
                        @exporting="this.$onExporting($event , 'Invoice-items')"
                        :scrolling="{ mode: 'virtual' }"
                        @row-updating="onRowUpdating"
                        @editing-start="onEditingStart">
                        <DxEditing :allow-updating="true" mode="cell"/>

                        <DxFilterRow :visible="true"/>
                        <DxHeaderFilter :visible="true"/>
                        <DxGroupPanel :visible="true"/>
                        <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                        <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                        <DxSearchPanel :visible="true"/>

                        <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"  :allow-editing="false"/>
                        <DxColumn data-field="stock_account_code" :caption="$t('Code')" cell-template="stock_account_code-cell-template" :allow-editing="false"/>
                        <DxColumn data-field="stock_account_name" :caption="$t('Item')" cell-template="stock_account_name-cell-template" :allow-editing="false"/>
                        <DxColumn data-field="quantity1" :sorting-method="this.$numericSort" :caption="$t('Quantity')" alignment="right"
                        :editor-options="columnEditorOptionsQuantity" data-type="number" :format="decimalFormat" cell-template="quantity1-cell-template">
                            <DxFormat type="fixedPoint" :precision="2"/>
                        </DxColumn>
                        <DxColumn data-field="returned_quantity" :sorting-method="this.$numericSort" :caption="$t('Returned Qty')" :allow-editing="false" alignment="right" cell-template="returned_quantity-cell-template"/>
                        <DxColumn data-field="unit_name" :caption="$t('Unit')" cell-template="unit_name-cell-template"  :allow-editing="false"/>
                        <DxColumn :caption="$t('Currency')" alignment="left" cell-template="Currency-cell-template"  :allow-editing="false"/>
                        <DxColumn data-field="price" :sorting-method="this.$numericSort" :caption="$t('Price')" alignment="right" :width="150"
                        :editor-options="columnEditorOptions" :format="decimalFormat" data-type="number" cell-template="price-cell-template">
                            <DxFormat type="fixedPoint" :precision="2"/>
                        </DxColumn>

                        <DxColumn data-field="total_price" :sorting-method="this.$numericSort" :caption="$t('Total')" :allow-editing="false" alignment="right" cell-template="total_price-cell-template"/>
                        <DxColumn data-field="discount_amount" :sorting-method="this.$numericSort" :caption="$t('Discount')" :allow-editing="false" alignment="right" cell-template="discount_amount-cell-template"/>
                        <DxColumn data-field="net_price" :sorting-method="this.$numericSort" :caption="$t('Net Total')" :allow-editing="false" alignment="right" header-cell-template="net-total-header" cell-template="net_price-cell-template"/>
                        <DxColumn data-field="tax_rate" :sorting-method="this.$numericSort" :caption="$t('VAT Rate')" :allow-editing="false" alignment="right" header-cell-template="vat-rate-header" cell-template="tax_rate-cell-template"/>
                        <DxColumn data-field="tax_amount" :sorting-method="this.$numericSort" :caption="$t('VAT Amount')" :allow-editing="false" alignment="right" header-cell-template="vat-amount-header" cell-template="tax_amount-cell-template"/>
                        <DxColumn data-field="gross_amount" :sorting-method="this.$numericSort" :caption="$t('Grand Total')" :allow-editing="false" alignment="right" header-cell-template="grand-total-header" cell-template="gross_amount-cell-template"/>

                        <template #net-total-header>
                            <div>{{ $t('Net') }}</div><div>{{ $t('Total') }}</div>
                        </template>
                        <template #grand-total-header>
                            <div>{{ $t('Grand') }}</div><div>{{ $t('Total') }}</div>
                        </template>
                        <template #vat-rate-header>
                            <div>{{ $t('VAT') }}</div><div>{{ $t('Rate') }}</div>
                        </template>
                        <template #vat-amount-header>
                            <div>{{ $t('VAT') }}</div><div>{{ $t('Amount') }}</div>
                        </template>

                        <template #is-selected-cell-template="{ data }">
                            <span>
                                <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                    <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" :disabled="data.data.quantity1 == 0" />
                                </div>
                            </span>
                        </template>
                        <!-- <template #id-cell-template="{ data }">
                            <a href="#" class="text-gray-800 text-hover-primary">
                                {{ getRowNumber(data) }}
                            </a>
                        </template>                                                     -->
                        <template #stock_account_code-cell-template="{ data }">
                            <span class="p-3 align-middle text-gray-900 text-start">{{data.data.stock_account_code}}</span>
                        </template>
                        <template #stock_account_name-cell-template="{ data }">
                            <span class="p-3 align-middle text-gray-900 text-start">{{data.data.stock_account_name}}</span>
                        </template>
                        <template #quantity1-cell-template="{ data }">
                            <div>
                                <span class="badge badge-circle badge-light">
                                    <i class="bi bi-pencil text-gray-900"></i>
                                </span>
                                <span class="p-1 align-middle text-gray-700 text-end">
                                    {{data.data.quantity1}}
                                </span>
                            </div>
                        </template>
                        <template #returned_quantity-cell-template="{ data }">
                            <span class="p-3 align-middle text-gray-700 text-end">{{data.data.returned_quantity}}</span>
                        </template>
                        <template #unit_name-cell-template="{ data }">
                            <span class="p-3 align-middle text-gray-600 text-start">{{data.data.unit_name}}</span>
                        </template>
                        <template #Currency-cell-template>
                            <span class="p-3 align-middle text-gray-700 text-start">{{this.form.currency}}</span>
                        </template>
                        <template #price-cell-template="{ data }">
                            <div>
                                <span class="badge badge-circle badge-light ">
                                    <i class="bi bi-pencil text-gray-900"></i>
                                </span>
                                <span class="p-1 align-middle text-gray-700 text-end">
                                    {{data.data.price}}
                                </span>
                            </div>
                        </template>
                        <template #total_price-cell-template="{ data }">
                            <span class="p-3 align-middle text-gray-600 text-end">{{data.data.total_price }}</span>
                        </template>
                        <template #discount_amount-cell-template="{ data }">
                            <span class="p-3 align-middle text-gray-600 text-end">{{data.data.discount_amount}}</span>
                        </template>
                        <template #net_price-cell-template="{ data }">
                            <span class="p-3 align-middle text-gray-600 text-end">{{data.data.net_price}}</span>
                        </template>
                        <template #tax_rate-cell-template="{ data }">
                            <span class="p-3 align-middle text-gray-600 text-end" v-if="data.data.vat_status_id == 1">None</span>
                            <span class="p-3 align-middle text-gray-600 text-end" v-else>{{data.data.tax_rate }}</span>
                        </template>
                        <template #tax_amount-cell-template="{ data }">
                            <span class="p-3 align-middle text-gray-600 text-end">{{data.data.tax_amount }}</span>
                        </template>
                        <template #gross_amount-cell-template="{ data }">
                            <span class="p-3 align-middle text-gray-600 text-end">{{data.data.gross_amount}}</span>
                        </template>
                        <DxSummary>
                            <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="isSelected"/>
                        </DxSummary>
                    </DxDataGrid>
                </div>
            </div>
            <div class="card-footer">
                <button class="btn btn-light" type="button" @click="onBackStep()" v-if="!isInvoiceItemAdded"> {{ $t('Back') }} </button>
                <button class="btn btn-success float-end" type="button" @click="onSelectItems()" :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined"> {{ $t('Add Items') }} </button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxGroupItem, DxStateStoring, DxEditing } from 'devextreme-vue/data-grid';

export default {
  name: "InvoiceList",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxGroupItem, DxStateStoring, DxEditing
  },
 data() {
    return {
        columnEditorOptions: { format: "#,##0.00", precision: 2 },
        decimalFormat: "#,##0.00",
        storageKey: 'dx_' + this.$options.name,
        localCurrency: null,
        isOpenActionsDropdown: false,
        page: 1,
        page_size: 10,
        form:  {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            sales_type: 0,
            invoice_type: 0,
            process_type: 0,
            account_id: null
        },
        invoiceList: [],
        searchbar: '',
        pageSize: 10,
        pageIndex: 0,
        isListProgressing: false,
        invoiceTypeList: [
            { id: 0, text: 'ALL'},
            { id: 1, text: 'Sales Invoice'},
            { id: 2, text: 'Purchase Invoice'},
            { id: 3, text: 'Sales Return Invoice'},
            { id: 4, text: 'Purchase Return Invoice'},
        ],
        salesTypeList: [
            { id: 0, text: 'ALL'},
            { id: 1, text: 'Domestic'},
            { id: 2, text: 'Overseas'},
        ],
        processList:[
            { id: 0, text: 'ALL'},
            { id: 1, text: 'Standard'},
            { id: 2, text: 'Fixed Asset'}
        ],
        isAccountIdRequired: false,
        viewType: 0,
        invoiceItemList: [],
        selectedInvoice: null,
        isInvoiceItemAdded: false,
        columnEditorOptionsQuantity: { format: "#,##0.00", precision: 2 },

    };
  },
  computed: {
    setColumnTemplateForLocal(){
        return (value)=>{
            const captionValue = value.column.caption.replace(' Local', '');
            return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);
        }
   },
     searchableList() {
        return this.invoiceList.filter(p => {
            return p.account_name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1 ||
                p.invoice_type_name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1 ||
                p.invoice_no.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
            }
        ).slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    showPageSizeSelector() {
        return this.invoiceList.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.invoiceList.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    },
    isItemButtonDisabled() {
       return this.invoiceItemList.find(item =>item.isSelected == true );
    },
  },
  methods: {
    getData(isAuto) {
        this.selectedInvoice = null;
        this.isInvoiceItemAdded = false;
        this.invoiceItemList = [];
        this.invoiceTypeList = [{ id: 0, text: 'ALL'}];
        if(this.$isCredentialActive(27, 'R', 20)) this.invoiceTypeList.push({ id: 1, text: 'Sales Invoice'});
        if(this.$isCredentialActive(28, 'R', 20)) this.invoiceTypeList.push({ id: 2, text: 'Purchase Invoice'});
        if(this.$isCredentialActive(29, 'R', 20)) this.invoiceTypeList.push({ id: 3, text: 'Sales Return Invoice'});
        if(this.$isCredentialActive(30, 'R', 20)) this.invoiceTypeList.push({ id: 4, text: 'Purchase Return Invoice'});
        if(isAuto && this.$getUser().is_allow_list_all_data) return;
        this.invoiceList = [];
        this.$Progress.start();
        this.isListProgressing = true;
        this.form.start_date = (this.form.start_date == null || this.form.start_date == '') ? moment() : this.form.start_date;
        this.form.end_date = (this.form.end_date == null || this.form.end_date == '') ? moment() : this.form.end_date;
        const params = {
            session_id: this.$getUser().session_id,
            start_date: this.form.start_date,
            end_date: this.form.end_date,
            invoice_type: this.form.invoice_type,
            sales_type: this.form.sales_type,
            process_type: this.form.process_type,
            account_id: this.form.account_id,
            invoiceTypes: []
        };
        if(params.invoice_type == 0) { params.invoice_type = null }
        if(params.sales_type == 0) { params.sales_type = null }
        if(params.process_type == 0) { params.process_type = null }
        params.start_date = moment(params.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
        params.end_date = moment(params.end_date).format('YYYY-MM-DD[T]HH:mm:ss');
        axios.post('api/Invoice/GetInvoicesByFilter', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.invoiceList = response.data;
            this.page = 1;
            this.localCurrency = this.$getUser().currency_code;
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function () {
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
    },
    updateInvoice(id){
        if(this.viewType == 1) return;

        this.$setSessionStorage('record_id', id);
        if(!this.isAccountIdRequired) this.$parent.$refs.closeTransactionListModal.click();
        if (this.$route.name == 'InvoiceUpdate') {
            this.$router.go(0);
        } else {
            this.$router.push({ name: 'InvoiceUpdate' });
        }
    },
    setCriteriasToDefault(accountId, viewType, selectedInvoice) {
        this.viewType = viewType;
        if(this.isAccountIdRequired || this.viewType == 1) { accountId = this.form.account_id; }
        if(viewType == 1 && selectedInvoice !== null && selectedInvoice !== '' && selectedInvoice !== undefined) {
            this.selectedInvoice = selectedInvoice;
            this.isInvoiceItemAdded = true;
            this.onSelectInvoice(selectedInvoice);
            return;
        }

        this.invoiceList = [];
        this.invoiceItemList = [];
        if((accountId === null || accountId === undefined || accountId === '-1')  && !this.$getUser().is_allow_list_all_data) return;
        this.form = {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            sales_type: 0,
            process_type: 0,
            invoice_type: viewType == 1 ? viewType : 0,
            account_id: (accountId !== undefined && accountId !== '' && accountId !== '-1') ? accountId : null
        };
        this.getData();
    },
    onClickDropdown(item) {
        this.invoiceList.forEach(element => {
            if (item.id !== element.id) {
                element.isOpenActionsDropdown = false;
            }
        });
        item.isOpenActionsDropdown = !item.isOpenActionsDropdown;
    },
    onCustomizeColumn(e){
        if(e){
            if(!e._isUpdated){
                if(e.length > 0){
                    e._isUpdated = true;
                }
                e.forEach((column) => {
                     if(column.dataField == 'gross_amount_local'){
                         column.caption =  'Grand Total Local';
                         column.headerCellTemplate = 'headerLocalTemplate';
                     }
                });
            }
        }
    },
    onEnterPopover(item, type){
         this.invoiceList.forEach((value) => {
            value.type = type;
            if (item.id === value.id) {
                value.isPopover = true;
                if (type == 'company') {
                    value.isVisibleCompany = true;
                    value.isVisibleController = false;
                    value.isVisibleSystem = false;
                }
                if (type == 'controller') {
                    value.isVisibleCompany = false;
                    value.isVisibleController = true;
                    value.isVisibleSystem = false;
                }
                if (type == 'system') {
                    value.isVisibleCompany = false;
                    value.isVisibleController = false;
                    value.isVisibleSystem = true;
                }
            }
        });
    },
    onLeavePopover(value){
        value.isPopover = false;
        value.isVisibleCompany = false;
        value.isVisibleController = false;
        value.isVisibleSystem = false;
    },
    setSummaries(options){
        if(options.name == "gross_amount_local"){
            if(options.summaryProcess === 'start'){
                options.totalValue = 0;
            }
            else if(options.summaryProcess === 'calculate'){
                var gross_amount_local = parseFloat(options.value.gross_amount_local.replaceAll(',', ''));
                (options.totalValue += gross_amount_local);
            }
            else if(options.summaryProcess === 'finalize'){
                var currencyInfo = this.localCurrency == null || this.localCurrency == '' ? '' : this.localCurrency;
                options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue) +' '+ currencyInfo;
            }
        }
    },
    /** DX STATE OPERATIONS **/
    LoadDXState() {
        return JSON.parse(localStorage.getItem(this.storageKey));
    },
    SaveDXStateToStorage() {
        const state = this.$refs.dataGrid.instance.state();
        localStorage.setItem(this.storageKey, JSON.stringify(state));
        this.$refs.listOptionComponent.getState();
    },
    onSelectInvoice(item) {
        this.selectedInvoice = item;
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            invoice_header_id: item.id
        };
        axios.post('/api/Invoice/GetInvoiceItems', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.invoiceItemList = response.data;
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    onSelectItems() {
        const selectedItems = [];
        this.invoiceItemList.forEach(element => {
            if(element.isSelected) {
                selectedItems.push({
                    id: element.id,
                    quantity1: element.quantity1,
                    price: Math.abs(parseFloat(element.price.replace(/,/g, '')))
                });
            }
        });
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            invoice_header_id: this.selectedInvoice.id,
            itemIds: selectedItems
        };
        axios.post('/api/Invoice/AddSalesReturnItems', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                let itemList = [];
                let totals = {
                    totalAmount: 0,
                    grossAmount: 0,
                    netAmount: 0,
                    discountAmount: 0,
                    taxAmount: 0,
                    totalAmountLocal: 0,
                    grossAmountLocal: 0,
                    netAmountLocal: 0,
                    discountAmountLocal: 0,
                    taxAmountLocal: 0,
                };
                response.data.invoice_items.forEach((item) => {
                    itemList.push({
                        created_at: item.created_at,
                        description: item.description,
                        discount: item.discount,
                        discount_amount: item.discount_amount,
                        discount_amount_local: item.discount_amount_local,
                        gross_amount: item.gross_amount,
                        gross_amount_local: item.gross_amount_local,
                        id: item.id,
                        net_price: item.net_price,
                        net_price_local: item.net_price_local,
                        price: item.price,
                        price_local: item.price_local,
                        quantity1: item.quantity1,
                        quantity2: item.quantity2,
                        session_id: item.session_id,
                        stock_account_id: item.stock_account_id,
                        stock_account_code: item.stock_account_code,
                        stock_account_name: item.stock_account_name,
                        tax_amount: item.tax_amount,
                        tax_amount_local: item.tax_amount_local,
                        tax_rate: item.tax_rate,
                        tmp_session_id: item.tmp_session_id,
                        total_price: item.total_price,
                        total_price_local: item.total_price_local,
                        unit_name: item.unit_name,
                        unit_2_name: item.unit_2_name,
                        unit_id: item.unit_id,
                        unit_2_id: item.unit_2_id,
                        sales_channel_id: item.sales_channel_id !== '' ? item.sales_channel_id : '-1',
                        campaign_id: item.campaign_id !== '' ? item.campaign_id : '-1',
                        stock_currency: item.stock_currency,
                        stock_price: item.stock_price,
                        stock_rate: item.stock_rate,
                        sales_channel_name: item.sales_channel_name,
                        campaign_name: item.campaign_name,
                        vat_status_id: item.vat_status_id,
                        vendor_commission: item.vendor_commission,
                        vendor_commission_rate: item.vendor_commission_rate,
                        isPopover: false,
                    });
                });

                totals = {
                    totalAmount: response.data.invoice_summary.totalAmount,
                    grossAmount: response.data.invoice_summary.grossAmount,
                    netAmount: response.data.invoice_summary.netAmount,
                    discountAmount: response.data.invoice_summary.discountAmount,
                    taxAmount: response.data.invoice_summary.taxAmount,
                    totalAmountLocal: response.data.invoice_summary.totalAmountLocal,
                    grossAmountLocal: response.data.invoice_summary.grossAmountLocal,
                    netAmountLocal: response.data.invoice_summary.netAmountLocal,
                    discountAmountLocal: response.data.invoice_summary.discountAmountLocal,
                    taxAmountLocal: response.data.invoice_summary.taxAmountLocal,
                }
                if(itemList.length > 0){
                    this.$setSessionStorage('tmp_session_id', itemList[0].tmp_session_id);
                }

                let itemData = {
                    "items": itemList,
                    "totals": totals,
                    "selectedInvoice": this.selectedInvoice
                };
                this.emitter.emit('RefreshInvoiceItems', itemData);
                this.$parent.$refs.closeTransactionListModal.click();
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    onBackStep() {
        this.invoiceItemList = [];
        this.selectedInvoice = null;
        this.getData();
    },
     firstValueQty(id){
        return this.invoiceItemList.find(item => item.id === id);
     },
    onEditingStart(e){

        e.data.quantity1 = e.data.quantity1.replace(/,/g, '');
        e.data.price = e.data.price.replace(/,/g, '');
    },
    onRowUpdating(e) {
        let newQuantity = e.oldData.quantity1;
        let newPrice = e.oldData.price;
        if (e.newData.quantity1) {
            const qty = this.firstValueQty(e.oldData.id);
            if (e.newData.quantity1 < 0 ) e.newData.quantity1 = 1;
            e.newData.quantity1 = Math.min(e.newData.quantity1,qty.valid_quantity);
            this.columnEditorOptionsQuantity = {
                max:qty.valid_quantity
            };
        }
        if(e.newData.quantity1 !== null && e.newData.quantity1 !== undefined && e.newData.quantity1 !== '')  newQuantity = parseFloat(e.newData.quantity1);
        if(e.newData.price !== null && e.newData.price !== undefined && e.newData.price !== '')  newPrice = parseFloat(e.newData.price);

        e.oldData.total_price = parseFloat(newQuantity * newPrice).toFixed(2);
        e.oldData.discount_amount = parseFloat((e.oldData.total_price / 100) * parseFloat(e.oldData.discount)).toFixed(2);
        e.oldData.net_price = parseFloat(e.oldData.total_price - e.oldData.discount_amount).toFixed(2);

        if (e.oldData.vat_status_id == 3 && e.oldData.account_type != 6) {
            e.oldData.net_price = parseFloat(e.oldData.net_price / (1 + (parseFloat(e.oldData.tax_rate) / 100))).toFixed(2);
        }
        e.oldData.tax_amount = parseFloat((e.oldData.net_price / 100) * parseFloat(e.oldData.tax_rate)).toFixed(2);

        if (e.oldData.vat_status_id == 3 && e.oldData.account_type == 6) {
            e.oldData.net_price = parseFloat(e.oldData.net_price - e.oldData.tax_amount).toFixed(2);
        }
        e.oldData.gross_amount = parseFloat(e.oldData.net_price + e.oldData.tax_amount).toFixed(2);

        const formatNumber = (num) => {
        return parseFloat(num).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        };

        e.oldData.total_price = formatNumber(e.oldData.total_price);
        e.oldData.discount_amount = formatNumber(e.oldData.discount_amount);
        e.oldData.net_price = formatNumber(e.oldData.net_price);
        e.oldData.tax_amount = formatNumber(e.oldData.tax_amount);
        e.oldData.gross_amount = formatNumber(e.oldData.gross_amount);

        e.newData.quantity1 = formatNumber(newQuantity);
        e.newData.price = formatNumber(newPrice);


        // e.newData.quantity1 = parseFloat(newQuantity).toFixed(2);
        // e.newData.price = parseFloat(newPrice).toFixed(2);
    },
  }
};
</script>