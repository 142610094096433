
<template>
    <div class="modal-dialog modal-dialog-centered modal-xxl-90" >
        <div class="modal-content"  >
            <div class="modal-header bg-primary py-3">
                <div class="modal-title col-md-10 text-white">
                    <h3 class="fw-bold text-white ms-5" v-if="this.selectedAccountName !== null">
                        <span class="text-white fw-boldest">{{this.selectedAccountName}}</span>
                        <span v-if="this.modalForm.report_selection == 1"> {{ $t(' \'s Statements') }} </span> 
                        <span v-if="this.modalForm.report_selection == 2"> {{ $t(' \'s Statement Report') }} </span> 
                    </h3>  
                </div>
                <div class="btn btn-icon btn-sm"
                    data-bs-dismiss="modal" aria-label="Close" ref="closeModal" @click="onCloseModal()">
                    <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg>
                    </span>
                </div>
            </div>
            <div class="modal-body scroll-y" :class="{'min-h-700px': isViewReportDesign, 'overlay overlay-block': isViewReportDesign && this.isFrameLoading == true && (this.designSelection !== null && this.designSelection !== '' && this.designSelection !== '-1')}">
                <div class="row"> 
                    <div class="card p-2">
                        <div class="card-body p-3">
                            <div class="row fv-row " v-if="!isViewReportDesign">
                                <div class="col-lg-6">
                                    <div class="fv-row row"> 
                                        <div class="col-lg">
                                            <label class="fs-6 form-label mt-5">
                                                <span>{{ $t('Report Type') }} </span>
                                            </label>
                                            <Select2 v-model="this.modalForm.report_selection" :options="this.reportSelectionFilteredList" :settings="{ width: '100%', dropdownParent: '#kt_modal_account_report_selection' }"/>  
                                        </div>
                                        <div class="col-lg">
                                            <label class="fs-6 form-label mt-5">
                                                <span>{{ $t('Start Date') }}</span>
                                            </label>
                                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="modalForm.start_date" inputClassName="form-control"
                                                format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                                        </div>  
                                        <div class="col-lg">
                                            <label class="fs-6 form-label mt-5">
                                                <span>{{ $t('End Date') }}</span>
                                            </label>
                                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="modalForm.end_date" inputClassName="form-control"
                                                format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                                        </div>
                                        <div class="col-lg">
                                            <label class="fs-6 form-label mt-5">
                                                <span>{{ $t('Currency') }}</span>
                                            </label>
                                            <Select2 v-model="this.modalForm.currency" :options="this.currencyList" :settings="{ width: '100%', dropdownParent: '#kt_modal_account_report_selection' }"/>  
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 mt-lg-10">
                                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">  
                                        <Popper hover placement="top">
                                            <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setModalCriteriasToDefault()">
                                                <i class="bi bi-eraser-fill fs-4"> </i>
                                            </button>
                                            <template #content>
                                                <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                                            </template>
                                        </Popper>
                                        <button type="button" class="btn btn-light-primary border border-primary" @click="onGetReportByType()" data-kt-indicator="on">
                                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                            </svg>
                                            </span> 
                                            <span v-if="!this.isListProgressing"> 
                                                <span v-if="modalForm.report_selection == 2">{{ $t('View Report') }}</span>
                                                <span v-else>List Transactions</span>
                                            </span> 
                                            <span class="indicator-progress" v-if="this.isListProgressing">
                                                {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span> 
                                        </button>
                                    </div> 
                                </div>
                                <div class="col-lg-4" v-if="balanceList.length > 0">
                                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base"> 
                                        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div class="table-responsive">
                                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                                    key-expr="currency" 
                                                    :data-source="balanceList" 
                                                    :allow-column-reordering="true"  
                                                    :allow-column-resizing="true" 
                                                    :column-auto-width="true" 
                                                    :row-alternatin-enabled="true"
                                                    :hover-state-enabled="true" 
                                                    :show-row-lines="true" 
                                                    :ref="'dataGrid'">                                                    
                                                    <DxColumn data-field="currency" :caption="$t('Currency')" alignment="right" cell-template="currency-cell-template"/>
                                                    <DxColumn data-field="total_balance" :caption="$t('Balance')" alignment="right" cell-template="total_balance-cell-template"/>
                                                    <DxColumn data-field="total_balance_local" :caption="$t('Balance Local')" alignment="right" cell-template="total_balance_local-cell-template" header-cell-template="total_balance_local-header"/>
                                                    <DxColumn data-field="average_due_state" :caption="$t('Average Due Date')" alignment="right" cell-template="average-due-state-cell-template" header-cell-template="average-due-state-header"/>

                                                    <template #total_balance_local-header>
                                                        <div>{{ $t('Balance') }} ({{localCurrency}})</div>
                                                    </template>
                                                    <template #average-due-state-header>
                                                        <div>{{ $t('Average') }}</div><div>{{ $t('Due Date') }}</div>
                                                    </template>

                                                    <!-- Cell Templates -->
                                                    <template #currency-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="modalForm.currency = data.data.currency, onGetReportByType()">
                                                            <i class="bi bi-cursor-fill me-3"></i> <span>{{ data.data.currency }}</span>
                                                        </a>
                                                    </template>
                                                    <template #total_balance-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="modalForm.currency = data.data.currency">
                                                            {{ data.data.total_balance }}
                                                        </a>
                                                    </template>
                                                    <template #total_balance_local-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="modalForm.currency = data.data.currency">
                                                            {{ data.data.total_balance_local }}
                                                        </a>
                                                    </template>
                                                    <template #average-due-state-cell-template="{ data }">
                                                        <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="balanceForm.currency = data.data.currency">
                                                            <span>{{ data.data.average_due_state }}</span>
                                                            <span v-if="data.data.average_due_state != 0" class="fs-8 text-gray-600 ps-1">
                                                                ({{ previewDate(data.data.average_due_date) }})
                                                            </span>
                                                        </a>
                                                    </template>
                                                        
                                                    <DxSummary :calculate-custom-summary="setDetailSummaries">
                                                        <DxTotalItem
                                                            name="total_balance_local"
                                                            summary-type="custom"
                                                            show-in-column="total_balance_local"
                                                            display-format="{0}"
                                                        />
                                                        <DxTotalItem
                                                            name="average_due_state"
                                                            summary-type="custom"
                                                            show-in-column="average_due_state"
                                                            display-format="{0}"
                                                        />
                                                    </DxSummary>
                                                </DxDataGrid>   
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Statement Report -->
                        <div v-if="modalForm.report_selection == 2 && isViewReportDesign" class="card-body p-2">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="input-group mb-5">
                                        <Select2 v-model="designSelection" :options="designList" @select="onChangeReport($event)" :settings="{ width: '300px', dropdownParent: '#kt_modal_account_report_selection .modal-content' }"/>  
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="alert alert-warning d-flex align-items-center p-2" v-if="designDescription !== '' && designDescription !== null && designDescription !== undefined">
                                        <span class="svg-icon svg-icon-muted svg-icon-2x">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                            <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
                                            <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"/>
                                            </svg>
                                        </span>
                                        <div class="d-flex flex-column ms-3">
                                            {{designDescription}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                                        <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3" @click="onBackStep()">
                                            <span class="ms-2 fs-7"> {{ $t('Return back to Criterias') }}</span>
                                        </button>
                                        <button type="button" class="btn btn-primary" @click="downloadStatement()">
                                            <i class="fa fa-file fs-5"></i> {{ $t('Download PDF') }}
                                        </button>
                                    </div> 
                                </div>
                            </div>
                            <div class="alert alert-warning d-flex align-items-center" v-if="this.designSelection == '-1' || this.designSelection == null || this.designSelection == '' || this.designSelection == undefined">
                                <span class="svg-icon svg-icon-muted svg-icon-2x">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                    <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"/>
                                    <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor"/>
                                    </svg>
                                </span>
                                <div class="d-flex flex-column ms-3">
                                    {{ $t('No default report template specified') }}!
                                </div>
                            </div>
                            <iframe @load="iframeloadhelper" v-show="!isFrameLoading" :src="'https://localhost:5001/Home/Viewer?id=' + this.selectedAccountId + '&report_id='+ this.designSelection + '&report_type=' + this.report_type + '&currency=' + this.modalForm.currency + '&start_date=' + this.modalForm.start_date + '&end_date=' + this.modalForm.end_date" 
                                    style="width:100% !important; height: 100% !important;min-height:700px;"
                                    v-if="this.is_local && this.designSelection !== null && this.designSelection !== '' && this.designSelection !== '-1'">
                            </iframe>

                            <iframe @load="iframeloadhelper" v-show="!isFrameLoading" :src="'/reports/Home/Viewer?id=' + this.selectedAccountId + '&report_id='+ this.designSelection + '&report_type=' + this.report_type + '&currency=' + this.modalForm.currency + '&start_date=' + this.modalForm.start_date + '&end_date=' + this.modalForm.end_date" 
                                    style="width:100% !important; height: 100% !important;min-height:700px;"
                                    v-if="!this.is_local && this.designSelection !== null && this.designSelection !== '' && this.designSelection !== '-1'">
                            </iframe>
                            
                        </div>  

                        <!-- Statement List -->
                        <div v-if="modalForm.report_selection == 1" class="card-body p-2">
                            <div class="separator mb-5"></div>
                            <div class="table-responsive min-h-300px">
                                <DxDataGrid id="gridContainer" :show-borders="true" 
                                    :data-source="detailList" 
                                    :allow-column-reordering="true" 
                                    :allow-column-resizing="true" 
                                    :column-auto-width="true"  
                                    :customize-columns="onCustomizeColumn"
                                    :row-alternatin-enabled="true"
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true" 
                                    :ref="'dataGrid'"
                                    @exporting="this.$onExporting($event , this.selectedAccountName + '-Statements')">

                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="true"/>
                                    <DxGroupPanel :visible="true"/>      
                                    <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                    <DxPaging :page-size="100"/>
                                    <DxPager :visible="true" :allowed-page-sizes="pageSizes(detailList, 100)" :show-page-size-selector="showPageSizeSelector(detailList, 100)" :show-info="true" :show-navigation-buttons="true"/>
                                    <DxSearchPanel :visible="true"/>

                                    <DxColumn data-field="transaction_date" cell-template="transaction_date-cell-template"  data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Transaction Date')" header-cell-template="transaction-date-header"/>
                                    <DxColumn data-field="transaction_type_name" cell-template="transaction_type_name-cell-template" :caption="$t('Transaction Type')" header-cell-template="transaction-type-header"/>
                                    <DxColumn data-field="transaction_subtype_name" cell-template="transaction_subtype_name-cell-template" :caption="$t('Transaction Sub Type')" header-cell-template="transaction-subtype-header"/>
                                    <DxColumn data-field="transaction_number" cell-template="transaction_number-cell-template" :caption="$t('Transaction No')" header-cell-template="transaction-no-header"/>
                                    <DxColumn data-field="amount" :sorting-method="this.$numericSort" cell-template="amount-cell-template" :caption="$t('Amount')" alignment="right" />
                                    <DxColumn data-field="balance" :sorting-method="this.$numericSort" cell-template="balance-cell-template" :caption="$t('Balance')" alignment="right" :visible="this.modalForm.currency !== '-1'"/>
                                    <DxColumn data-field="currency" cell-template="currency-cell-template" :caption="$t('Curr.')" />
                                    <DxColumn data-field="rate" cell-template="rate-cell-template" :caption="$t('Rate')" alignment="right" />
                                    <DxColumn data-field="amount_local" :sorting-method="this.$numericSort" cell-template="amount_local-cell-template" :caption="$t('Local Amount')"  alignment="right" header-cell-template="local-amount-header"/>
                                    <DxColumn data-field="balance_local" :sorting-method="this.$numericSort" cell-template="balance_local-cell-template" :caption="$t('Local Balance')"  alignment="right" header-cell-template="local-balance-header"/>
                                    <DxColumn data-field="transaction_due_date" cell-template="transaction_due_date-cell-template"  data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Due Date')" header-cell-template="due-date-header"/> 
                                    <DxColumn data-field="debit_credit" :sorting-method="this.$numericSort" cell-template="debit_credit-cell-template" :caption="$t('Debit/Credit')" header-cell-template="debit-credit-header"/>
                                    <DxColumn data-field="record_date" cell-template="record_date-cell-template" data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Record Date')" header-cell-template="record-date-header"/>
                                    <DxColumn data-field="description" cell-template="description-cell-template" :caption="$t('Description')" /> 

                                    <template #headerLocalTemplate="{ data: data }">
                                        <div v-html="setColumnTemplateForLocal(data)"></div> 
                                    </template>

                                    <template #record-date-header>
                                        <div>{{ $t('Record') }}</div><div>{{ $t('Date') }}</div>
                                    </template>
                                    <template #transaction-type-header>
                                        <div> {{ $t('Transaction') }}</div><div>{{ $t('Type') }}</div>
                                    </template>
                                    <template #transaction-subtype-header>
                                        <div>{{ $t('Transaction Sub') }}</div><div>{{ $t('Type') }}</div>
                                    </template>
                                    <template #transaction-no-header>
                                        <div>{{ $t('Transaction') }}</div><div>{{ $t('No') }}</div>
                                    </template>
                                    <template #local-amount-header>
                                        <div>{{ $t('Local') }}</div><div>{{ $t('Amount') }}</div>
                                    </template>
                                    <template #local-balance-header>
                                        <div>{{ $t('Local') }}</div><div>{{ $t('Balance') }}</div>
                                    </template>
                                    <template #due-date-header>
                                        <div>{{ $t('Due') }}</div><div>{{ $t('Date') }}</div>
                                    </template>
                                    <template #debit-credit-header>
                                        <div>{{ $t('Debit') }}</div><div>{{ $t('Credit') }}</div>
                                    </template>
                                    <template #transaction-date-header>
                                        <div>{{ $t('Transaction') }}</div><div>{{ $t('Date') }}</div>
                                    </template>
                                    <!-- :class="{ 'p-2 bg-material-gray-200': data.data.transaction_subtype_name == 'Turnover Balance'}" -->

                                    <template #transaction_date-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                            {{ previewDate(data.data.transaction_date)}}
                                        </a>
                                    </template>
                                    <template #transaction_type_name-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                            {{ data.data.transaction_type_name}}
                                        </a>
                                    </template>
                                    <template #transaction_subtype_name-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                            {{ data.data.transaction_subtype_name}}
                                        </a>
                                    </template>
                                    <template #transaction_number-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                            {{ data.data.transaction_number}}
                                        </a>
                                    </template>
                                    <template #amount-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                            {{ data.data.amount}}
                                        </a>
                                    </template>
                                    <template #balance-cell-template="{ data }" v-if="this.modalForm.currency !== '-1'">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                            {{ data.data.balance }}
                                        </a>
                                    </template>
                                    <template #currency-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                            {{ data.data.currency}}
                                        </a>
                                    </template>
                                    <template #rate-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                            {{ data.data.rate}}
                                        </a>
                                    </template>
                                    <template #amount_local-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                            {{ data.data.amount_local}}
                                        </a>
                                    </template>
                                    <template #balance_local-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                            {{ data.data.balance_local}}
                                        </a>
                                    </template>
                                    <template #transaction_due_date-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                            {{previewDate( data.data.transaction_due_date)}}
                                        </a>
                                    </template>
                                    <template #debit_credit-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                            {{data.data.debit_credit}}
                                        </a>
                                    </template>
                                    <template #record_date-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                            {{previewDate( data.data.record_date)}}
                                        </a>
                                    </template>
                                    <template #description-cell-template="{ data }">
                                        <a href="#" class="text-gray-800 text-hover-primary mb-1">
                                            {{ data.data.description}}
                                        </a>
                                    </template>

                                    <DxSummary :calculate-custom-summary="setDetailSummaries">
                                        <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="transaction_date"/>
                                        <DxGroupItem
                                            :show-in-group-footer="true"
                                            name="amount"
                                            summary-type="custom"
                                            show-in-column="amount"
                                            display-format="{0}"
                                        /> 
                                        <DxGroupItem
                                            :show-in-group-footer="true"
                                            name="balance"
                                            summary-type="custom"
                                            show-in-column="balance"
                                            display-format="{0}"
                                        /> 
                                        <DxGroupItem
                                            :show-in-group-footer="true"
                                            name="amount_local"
                                            summary-type="custom"
                                            show-in-column="amount_local"
                                            display-format="{0}"
                                        /> 
                                        <!-- <DxGroupItem
                                            :show-in-group-footer="true"
                                            name="balance_local"
                                            summary-type="custom"
                                            show-in-column="balance_local"
                                            display-format="{0}"
                                        /> 
                                        <DxTotalItem
                                            name="balance_local"
                                            summary-type="custom"
                                            show-in-column="balance_local"
                                            display-format="{0}"
                                        /> -->
                                    </DxSummary>

                                    <DxStateStoring
                                        :enabled="true"
                                        type="custom"
                                        :storage-key="this.storageKey"
                                        :ref="dataGrid" 
                                        :saving-timeout="1000"
                                        :custom-load="LoadDXState"
                                    />
                                </DxDataGrid>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isFrameLoading == true && (this.designSelection !== null && this.designSelection !== '' && this.designSelection !== '-1')">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">{{ $t('Loading...') }}</span>
                    </div>
                    <span class="d-flex text-primary ms-5">{{ $t('Document loading. Please wait') }}...</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import { saveAs } from 'file-saver';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSummary, DxTotalItem, DxSearchPanel, DxStateStoring, DxGroupItem } from 'devextreme-vue/data-grid';
export default {
  name: "AccountReports",
  props: {
    accountName: String,
    accountId: String,
    accountType: Number
  },
  components: {
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSummary, DxTotalItem, DxSearchPanel, DxStateStoring, DxGroupItem
  },
  data() {
    return { 
        localCurrency: this.$getUser().currency_code,
        id: this.$getSessionStorage('record_id'),
        designSelection: '-1',
        designDescription: null,
        designList: [{id: '-1', text: 'Select an option'}],
        is_local: false,
        report_type: 3, //Statement
        modalForm: {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            currency: '-1',
            report_selection: 1
        },
        isViewReportDesign: false,
        isFrameLoading: false,
        currencyList: [],
        balanceList: [],
        detailList: [],
        reportSelectionList: [
            {id: 1, text: 'Statement List'},
            {id: 2, text: 'Statement Report'}
        ],
        selectedAccountId: null,
        selectedAccountName: null,
        summary: {
            total_average_due_state: 0,
            total_average_due_date: null
        }
    };
  },
  computed: {
    previewDate(){
        return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            }
        }
    },
    showPageSizeSelector() {
        return(list, pageSize) => {
            return list.length > pageSize;
        }
    },
    pageSizes() {
        return(list, pageSize) => {
            let arr = [];
            let x = 1;
            let loop = (list.length / pageSize);
            for (let i = 1; i <= loop && i <= 5; i++) {
                arr.push(pageSize * x);
                x++;
            }
            if(loop > 5) arr.push(1000);
            if(loop > 1) arr.push('all');
            return arr;
        }
    },
    reportSelectionFilteredList() {
        let module = 0;
        switch (this.accountType) {
        case 1: //Customer
            module = 10; break;
        case 2: //Supplier
            module = 11; break;
        case 3: //Cash
            module = 12; break;
        case 4: //Bank
            module = 13; break;
        case 5: //Personnel
            module = 14; break;
        case 6: //Expense
            module = 15; break;
        case 7: //Stock
            module = 16; break;
        case 8: //Fixed Asset
            module = 17; break;
        case 15: //Other
            module = 18; break;
        case 16: //Cheque Account
            module = 19; break;
        }
        if((module !== 0 && module !== undefined && module !== null) && !this.$isCredentialActive(module, 'P')) {
            return this.reportSelectionList.filter(p => p.id != 2);
        }    
        return this.reportSelectionList;
    }
  },
  methods: {
    getData(accountInfo) {
        this.detailList = [];
        this.selectedAccountId = this.accountId;
        this.selectedAccountName = this.accountName;
        this.modalForm.currency = this.$getUser().currency_code;
        if(accountInfo) {
            this.selectedAccountId = accountInfo.id;
            this.selectedAccountName = accountInfo.name;
        }
        this.isViewReportDesign = false;
        const parameters = {
            session_id: this.$getUser().session_id,
            account_id: this.selectedAccountId
        };
         const parametersThree = {
            session_id: this.$getUser().session_id,
            category: this.report_type,
            account_id: this.selectedAccountId,
            view_option: (this.localCurrency == this.modalForm.currency) ? 1 : 2
        };
        if(this.modalForm.currency == '-1' || this.modalForm.currency == null || this.modalForm.currency == '') {
            parametersThree.view_option = 0;
        }      
        const requestOne =  axios.post('/api/Helper/GetStatementCurrencies', parameters, {'Content-Type': 'application/json'}); 
        const requestTwo =  axios.post('/api/Helper/GetAccountCurrencyBalances', parameters, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/Reports/GetControllerReportDesigns', parametersThree, {'Content-Type': 'application/json'}); 
        
        axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {       
            this.currencyList = responses[0].data;
            this.modalForm.currency = this.currencyList.length > 0 ? this.currencyList[0].id : null;
            this.balanceList = responses[1].data.balance;
            this.summary = responses[1].data.summary;
            if(this.currencyList.length == 0) { this.currencyList.unshift({id: '-1', text: 'Select an option'}); }
            this.currencyList.forEach(element => {
                if(element.id == this.$getUser().currency_code) {
                    this.modalForm.currency = element.id;
                }
            });

            this.designList = responses[2].data.report_designs;
            this.designSelection = !responses[2].data.default_report_design ? '-1' : responses[2].data.default_report_design;
            this.designList.forEach((item) => { 
                if(item.id == this.designSelection){
                    this.designDescription = item.description;
                    this.report_type = item.category;
                }
            });
            this.designList.unshift({id: '-1', text: 'Select an option'});
            if (location.hostname === "localhost" || location.hostname === "127.0.0.1"){ this.is_local = true; }
            else{ this.is_local = false; }

            this.getStatementList();
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    onGetReportByType() {
        if(this.modalForm.report_selection == 1) {
            this.getStatementList();
        } else {
            this.getStatementReport();
        }
    },
    getStatementList(){ 
        this.isViewReportDesign = false;
        this.detailList = [];
        const params = {
            session_id: this.$getUser().session_id,
            account_id: this.selectedAccountId,
            currency: this.modalForm.currency,
            start_date: moment(this.modalForm.start_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            end_date: moment(this.modalForm.end_date).format('YYYY-MM-DD[T]HH:mm:ss')
        };
        params.currency = this.modalForm.currency == '-1' ? null : this.modalForm.currency;
        axios.post('/api/GetAccountsDetails/GetAccountDetailTransactions', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.detailList = response.data.transactions;
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    getStatementReport () {
        this.isFrameLoading = true;
        if(this.modalForm.currency == '-1') {this.modalForm.currency = '';}        
        this.modalForm.start_date = moment(this.modalForm.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
        this.modalForm.end_date = moment(this.modalForm.end_date).format('YYYY-MM-DD[T]HH:mm:ss');
        this.isViewReportDesign = true;
        this.saveLogs(1);
    },
    saveLogs(viewType) {
        const parameters = {
            log_type: 7,
            log_location: 99,
            session_id: this.$getUser().session_id,
            log_location_detail: null,
            log_operation: viewType == 1 ? 22 : 19,
            document_no: null,
            account_id: null,
            description: this.selectedAccountName + ' Statement ' + (viewType == 1 ? 'viewed.' : 'downloaded.'),
            criteria_data: moment(this.modalForm.start_date).format('YYYY-MM-DD') + ' ;' + moment(this.modalForm.end_date).format('YYYY-MM-DD') + ' ;' + (this.modalForm.currency !== '-1' ? this.modalForm.currency : 'ALL Currencies')
        };  
        axios.post('/api/GetCompanyAccounts/SaveLogs', parameters, {'Content-Type': 'application/json'});
    },
    getReportTemplates() { 
        const parameters = {
            session_id: this.$getUser().session_id,
            category: this.report_type,
            account_id: this.selectedAccountId,
            view_option: (this.localCurrency == this.modalForm.currency) ? 1 : 2
        };
        if(this.modalForm.currency == '-1' || this.modalForm.currency == null || this.modalForm.currency == '') {
            parameters.view_option = 0;
        }        
        const requestOne =  axios.post('/api/Reports/GetControllerReportDesigns', parameters, {'Content-Type': 'application/json'});        
        axios.all([requestOne]).then(axios.spread((...responses) => {
            this.designList = responses[0].data.report_designs;
            this.designSelection = !responses[0].data.default_report_design ? '-1' : responses[0].data.default_report_design;
            this.designList.forEach((item) => { 
                if(item.id == this.designSelection){
                    this.designDescription = item.description;
                    this.report_type = item.category;
                }
            });
            this.designList.unshift({id: '-1', text: 'Select an option'});
            if (location.hostname === "localhost" || location.hostname === "127.0.0.1"){ this.is_local = true; }
            else{ this.is_local = false; }
        }))
        .catch(errors => { 
            console.error(errors); 
        });
    },
    onChangeReport(event) {
        this.isFrameLoading = true;
        this.designSelection = event.id;
        this.designDescription = event.description;
        this.report_type = event.category;
    },
    onCloseModal(){
        this.$parent.isViewStatementModal = false;
        this.$parent.isViewAddTurnoverModal = true;
    },
    setModalCriteriasToDefault() {
        this.modalForm = {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            currency: '-1',
            report_selection: 1
        };
        this.isViewReportDesign = false;
        this.currencyList.forEach(element => {
            if(element.id == this.$getUser().currency_code) {
                this.modalForm.currency = element.id;
            }
        });
    },
    onBackStep() {
        this.isFrameLoading = false;
        if(this.modalForm.currency == null || this.modalForm.currency == '') this.modalForm.currency = '-1';
        this.isViewReportDesign = false;
    },
    downloadStatement(){
        if(this.modalForm.currency == '-1') {this.modalForm.currency = '';}
        this.modalForm.start_date = moment(this.modalForm.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
        this.modalForm.end_date = moment(this.modalForm.end_date).format('YYYY-MM-DD[T]HH:mm:ss');

        if (location.hostname === "localhost" || location.hostname === "127.0.0.1")
        {
            fetch('https://localhost:5001/Home/Downloader?id=' + this.selectedAccountId + '&report_id='+ this.designSelection + "&report_type=" + this.report_type + '&currency=' + this.modalForm.currency + '&start_date=' + this.modalForm.start_date + '&end_date=' + this.modalForm.end_date)
                .then(response => response.blob())
                .then(data => {
                    saveAs(data, this.selectedAccountName + ' Statement' + '.pdf');
                    this.saveLogs(2);
                });
        }
        else{
            fetch('/reports/Home/Downloader?id=' + this.selectedAccountId + '&report_id='+ this.designSelection + "&report_type=" + this.report_type + '&currency=' + this.modalForm.currency + '&start_date=' + this.modalForm.start_date + '&end_date=' + this.modalForm.end_date)
                .then(response => response.blob())
                .then(data => {
                    saveAs(data, this.selectedAccountName + ' Statement' + '.pdf');
                    this.saveLogs(2);
                });
        } 
    },
    iframeloadhelper() {
      this.isFrameLoading = false;
    },
    setDetailSummaries(options){  
        const optionNames = ["amount", "balance", "amount_local", "balance_local", "total_balance_local"]; 
        optionNames.forEach(item => {
            if(options.name == item){
                if(options.summaryProcess === 'start'){ options.totalValue = 0; }
                else if(options.summaryProcess === 'calculate'){
                    var itemValue = parseFloat(options.value[item].replaceAll(',', ''));
                    (options.totalValue += itemValue);
                }
                else if(options.summaryProcess === 'finalize'){ 
                    options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
                }
            }
        });
    },
  }
};
</script>