<template>
  <div>
        <div class="d-flex flex-stack flex-wrap">
          <div class="page-title d-flex flex-column me-3 mt-3"> 
              <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Accounting Settings') }} </h1>  
              <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                  <li class="breadcrumb-item text-gray-600">
                      <a href="#" class="text-gray-600 text-hover-primary">
                          <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                      </a>
                  </li> 
                  <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>     
                  <li class="breadcrumb-item text-gray-600">{{ $t('Create Accounting Settings') }}
                  <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                  </li>   
              </ul> 
          </div>  
          <div class="d-flex align-items-center py-1">
              <router-link to="/Settings">
                  <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                  <span class="ms-2 fs-7"> {{ $t('Return back to Settings') }} </span>
                  </button>
              </router-link> 
          </div>
        </div>
      <div class="content flex-row-fluid mt-4" id="kt_content">
        <RequiredField></RequiredField>
        <div class="card card-xxl-stretch">
          <div class="card-header">
            <div class="card-title">
              <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                <li class="nav-item">
                    <a class="nav-link " @click="this.activeView = 'General'" :class="{ 'text-active-primary active': activeView == 'General' }"  data-bs-toggle="tab" href="#accounting_general" target="_self">
                        {{ $t('General') }}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" @click="this.activeView = 'AccountingPeriod', getAccountingYears()" :class="{ 'text-active-primary active': activeView == 'AccountingPeriod' }" data-bs-toggle="tab" href="#accounting_period" target="_self">
                        {{ $t('Accounting Period') }}
                    </a>
                </li>
              </ul>
            </div>           
          </div>
          <div v-if="this.activeView == 'General'" class="tab-pane fade show active" id="accounting_general" role="tabpanel"> 
            <div class="card card-xxl-stretch">
                <div class="row p-10">
                    <div class="d-flex flex-row row">
                        <div class="d-flex flex-column flex-row-auto col-lg-6">
                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0" style="border-radius: 0.95rem; height: 286px;">
                                    <form id="kt_ecommerce_settings_general_form"
                                        class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                      <div class="fv-row row">
                                        <div class="col-lg-12"> 
                                            <label class="fs-6 fw-bold form-label mt-3">{{ $t('Incorporated In') }}
                                              <small v-if="form.incorporated_at == null" class="text-danger ms-3"> {{ $t('Please enter a valid date') }} </small>
                                            </label>
                                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.incorporated_at" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'"></Datepicker>  
                                        </div>
                                        <div class="col-lg-12"> 
                                            <label class="fs-6 fw-bold form-label mt-3">{{ $t('Accounting Start Period') }}
                                              <small v-if="form.accounting_start_period == null" class="text-danger ms-3"> {{ $t('Please enter a valid date') }} </small>
                                            </label>
                                            <Datepicker v-model="form.accounting_start_period" inputClassName="form-control" format="dd MMM" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :flow="['month', 'calendar']"></Datepicker>  
                                        </div>
                                        <div class="col-lg-12">
                                            <label class="fs-6 fw-bold form-label mt-3">{{ $t('Accounting End Period') }}
                                              <small v-if="form.accounting_end_period == null" class="text-danger ms-3"> {{ $t('Please enter a valid date') }} </small>
                                            </label>
                                            <Datepicker v-model="form.accounting_end_period" inputClassName="form-control" format="dd MMM" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :flow="['month', 'calendar']"></Datepicker>  
                                        </div>
                                      </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-row-auto col-lg-6">
                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0" style="border-radius: 0.95rem; height: 286px;">
                                  <form id="kt_ecommerce_settings_general_form" class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                    <div class="fv-row row">
                                      <div class="col-lg-12"> 
                                          <label class="fs-6 fw-bold form-label mt-3 ">{{ $t('Accounting Fee Selection') }}</label>
                                          <br>
                                          <div class="form-check form-check-inline my-2">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="monthly" v-model="form.accountingFeeSelection">
                                            <label class="form-check-label" for="inlineRadio1">{{ $t('Monthly') }}</label>
                                          </div>
                                          <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="yearly" v-model="form.accountingFeeSelection">
                                            <label class="form-check-label" for="inlineRadio2">{{ $t('Yearly') }}</label>
                                          </div>
                                      </div>
                                      <div class="col-lg-12" v-if="form.accountingFeeSelection == 'monthly'"> 
                                          <label class="fs-6 fw-bold form-label mt-3 ">{{ $t('Accounting Fee') }} ({{ $t('Monthly') }})</label>
                                          <CurrencyInput type="text" step="any" class="form-control text-end" v-model="form.accounting_fee_monthly" @focus="$event.target.select()"/>
                                      </div>
                                      <div class="col-lg-12" v-if="form.accountingFeeSelection == 'yearly'">
                                          <label class="fs-6 fw-bold form-label mt-3 ">{{ $t('Accounting Fee') }} ({{ $t('Yearly') }})</label>
                                          <CurrencyInput type="text" step="any" class="form-control text-end" v-model="form.accounting_fee_yearly" @focus="$event.target.select()"/>
                                      </div>  
                                      <div class="col-lg-12" v-if="form.accountingFeeSelection == 'monthly'"> 
                                          <label class="fs-6 fw-bold form-label mt-3 ">{{ $t('Accounting Fee Monthly Date') }}
                                            <small v-if="form.accounting_fee_monthly_date == null" class="text-danger ms-3"> {{ $t('Please enter a valid date') }} </small>
                                          </label>
                                          <Datepicker v-model="form.accounting_fee_monthly_date" inputClassName="form-control" format="dd MMM" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :flow="['month', 'calendar']"></Datepicker>
                                      </div>
                                      <div class="col-lg-12" v-if="form.accountingFeeSelection == 'yearly'">
                                          <label class="fs-6 fw-bold form-label mt-3 ">{{ $t('Accounting Fee Yearly Date') }}
                                            <small v-if="form.accounting_fee_yearly_date == null" class="text-danger ms-3"> {{ $t('Please enter a valid date') }} </small>
                                          </label>
                                          <Datepicker v-model="form.accounting_fee_yearly_date" inputClassName="form-control" format="dd MMM" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :flow="['month', 'calendar']"></Datepicker>  
                                      </div> 
                                    </div>
                                  </form>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-row-auto col-lg-6">
                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0" style="border-radius: 0.95rem; height: 286px;">
                                    <form id="kt_ecommerce_settings_general_form"
                                        class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                        <div class="fv-row row">
                                          <div class="col-lg-12"> 
                                              <label class="fs-6 fw-bold form-label mt-3">{{ $t('VAT Periods') }}</label> 
                                                <Select2 v-model="form.vat_period" :options="vatPeriodList" :settings="{width: '100%'}"/>
                                          </div>
                                          <div class="col-lg-6"> 
                                              <label class="fs-6 fw-bold form-label mt-3 ">{{ $t('VAT Start Period') }}
                                                  <small v-if="form.vat_start_period == null" class="text-danger ms-3"> {{ $t('Please enter a valid date') }} </small>
                                              </label>
                                              <Datepicker v-model="form.vat_start_period" inputClassName="form-control" format="dd MMM" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :flow="['month', 'calendar']"></Datepicker>                          
                                          </div>
                                          <div class="col-lg-6"> 
                                              <label class="fs-6 fw-bold form-label mt-3 ">{{ $t('VAT End Period') }}
                                                  <small v-if="form.vat_end_period == null" class="text-danger ms-3"> {{ $t('Please enter a valid date') }} </small>
                                              </label>
                                              <Datepicker v-model="form.vat_end_period" inputClassName="form-control" format="dd MMM" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :flow="['month', 'calendar']"></Datepicker>  
                                          </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-row-auto col-lg-6">
                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                <div class="card-body pb-0" style="border-radius: 0.95rem; height: 286px;">
                                    <form id="kt_ecommerce_settings_general_form"
                                        class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                        <div class="fv-row row">
                                          <div class="col-lg-12"> 
                                              <label class="fs-6 fw-bold form-label mt-3 required">
                                                {{ $t('Account Office Reference') }}
                                              </label>
                                              <input type="text" class="form-control" placeholder="" name="name" v-model="form.account_office_reference"> 
                                          </div>
                                          <div class="col-lg-12">
                                            <label class="fs-6 fw-bold form-label mt-3">{{ $t('Payroll Periods') }}</label> 
                                            <Select2 v-model="form.payroll_period" :options="payrollPeriodList" :settings="{width: '100%'}"/>
                                          </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-0">
                          <div class="d-grid gap-2">
                            <button type="button" class="btn btn-success" @click="this.saveData()" data-kt-indicator="on">
                                <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                </svg>
                                </span>
                                <strong v-if="!this.isProgressing"> {{ $t('Save Accounting Settings') }} </strong> 
                                <span class="indicator-progress" v-if="this.isProgressing">
                                    {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span> 
                            </button>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div v-if="this.activeView == 'AccountingPeriod'" class="tab-pane fade show active" id="accounting_period" role="tabpanel">
            <div class="card"> 
              <div class="card-header px-3">
                  <div class="card-title"> 
                    <button type="button" class="btn btn-light-primary border border-primary "
                      ref="btnAddItem"
                      data-bs-toggle="modal" 
                      data-bs-target="#kt_modal_add_year" 
                      @click="openAccountingPeriodModal('addItem')"
                      data-kt-menu-trigger="click" 
                      data-kt-menu-placement="bottom-end">

                      <span class="svg-icon svg-icon-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                              viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                  transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                              <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                  fill="currentColor">
                              </rect>
                          </svg>
                      </span> 
                      {{ $t('Add Accounting Periods') }}
                    </button>
                  </div>
                  <div class="card-toolbar">
                    <div class="d-flex justify-content-end">
                      <div class="text-gray-600 fw-bold my-10 text-end"> {{ $t('Online Accounting') }}:                                       
                        <span v-if="form.online_accounting == true" class="badge badge-light-success"> {{ $t('Active') }} </span>
                        <span v-if="form.online_accounting == false" class="badge badge-light-danger"> {{ $t('Passive') }} </span>
                      </div>
                    </div>                          
                    <div class="d-flex justify-content-end ms-5">
                      <button type="button" class="btn btn-light-primary border border-primary" @click="getAccountingYears()" data-kt-indicator="on">
                        <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                        <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                        <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                        </svg>
                        </span>
                        <strong v-if="!this.isListProgressing"> {{ $t('List Accounting Periods') }} </strong> 
                        <span class="indicator-progress" v-if="this.isListProgressing">
                            {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span> 
                      </button>
                    </div> 
                  </div>
              </div>
              <div class="card-body p-3">
                <DxDataGrid id="gridContainer" :show-borders="true" key-expr="id" :data-source="companyAccountingPeriods" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true"
                  :hover-state-enabled="true" :show-row-lines="true"  @exporting="this.$onExporting($event , 'Accounting-period')"> 
                  <DxFilterRow :visible="true"/>
                  <DxHeaderFilter :visible="true"/>
                  <DxGroupPanel :visible="true"/>      
                  <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                  <DxPaging :page-size="10"/>
                  <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                  <DxSearchPanel :visible="true"/>
                  <DxScrolling
                      mode="virtual"
                      row-rendering-mode="virtual"
                  />
                  <DxColumn data-field="period_year_short" :caption="$t('Period')" cell-template="period_year_short-cell-template" /> 
                  <DxColumn data-field="start_date" :caption="$t('Start Date')" alignment="right" :allow-filtering="false" cell-template="start_date-cell-template" /> 
                  <DxColumn data-field="end_date" :caption="$t('End Date')" alignment="right" :allow-filtering="false" cell-template="end_date-cell-template" />
                  <DxColumn data-field="month_count" :caption="$t('Total Months')" alignment="right" :allow-filtering="false" cell-template="month_count-cell-template" /> 
                  <DxColumn data-field="is_active" :caption="$t('Status')" :allow-filtering="false" alignment="center" cell-template="is_active-cell-template"  />
                  <DxColumn data-field="is_online_accounting_name" :caption="$t('Online Accounting')" :allow-filtering="false" alignment="center" cell-template="is_online_accounting-cell-template" header-cell-template="is_online_accounting_name-header" />
                  <DxColumn data-field="is_vat_active_name" :caption="$t('Is VAT Active?')" :allow-filtering="false" alignment="center" cell-template="is_vat_active-cell-template" header-cell-template="is_vat_active_name-header" />
                  <DxColumn data-field="is_accounting_period_closed" :caption="$t('Accounting Period Closed?')" alignment="center" cell-template="is_accounting_period_closed-cell-template" header-cell-template="is_accounting_period_closed-header" /> 
                  <DxColumn data-field="cost_of_sales_method_name" :caption="$t('Cost Of Sales / Method')" :allow-filtering="false" alignment= "left" cell-template="cost_of_sales_method-cell-template" header-cell-template="cost_of_sales_method_name-header" />
                  <DxColumn data-field="plan_template_name" :caption="$t('Plan Template')" :allow-filtering="false" alignment= "left" cell-template="plan_template_name-cell-template"/>

                  <template #is_accounting_period_closed-header>
                    <div>{{ $t('Accounting Period') }}</div><div>{{ $t('Closed') }}</div>
                  </template>
                  <template #cost_of_sales_method_name-header>
                    <div>{{ $t('Cost Of Sales') }}</div><div>{{ $t('Method') }}</div>
                  </template>
                  <template #is_online_accounting_name-header>
                    <div>{{ $t('Online') }}</div><div>{{ $t('Accounting') }}</div>
                  </template>
                  <template #is_vat_active_name-header>
                    <div>{{ $t('Vat') }}</div><div>{{ $t('Active?') }}</div>
                  </template>

                  <template #period_year_short-cell-template = "{ data }"> 
                      <span> 
                        <a href="#" class="text-gray-800 fw-bolder text-hover-primary mb-1" data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="openAccountingPeriodModal('editItem', data.data)"> 
                          <div>
                            <span class="badge badge-circle badge-light me-3">
                              <i class="bi bi-pencil text-gray-900"></i> 
                            </span> 
                            <span class="text-gray-800"> 
                              {{ data.data.period_year_short }}
                            </span> 
                          </div>
                        </a>
                      </span>
                  </template>
                  <template #start_date-cell-template = "{ data }"> 
                      <span> 
                        <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="openAccountingPeriodModal('editItem', data.data)">
                          {{ previewPeriodDate(data.data.start_date) }} 
                        </a>
                      </span>
                  </template>
                  <template #end_date-cell-template="{ data }"> 
                      <span> 
                        <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="openAccountingPeriodModal('editItem', data.data)">
                          {{ previewPeriodDate(data.data.end_date) }}
                        </a>
                      </span>
                  </template>
                  <template #month_count-cell-template="{ data }"> 
                      <span> 
                        <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="openAccountingPeriodModal('editItem', data.data)">
                          {{ data.data.month_count }} 
                        </a>
                      </span>
                  </template>
                  <template #is_active-cell-template="{ data }"> 
                      <span> 
                        <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="openAccountingPeriodModal('editItem', data.data)">
                          <span v-if="data.data.is_active == 1" class="badge badge-light-success"> Active </span>
                          <span v-if="data.data.is_active == 0" class="badge badge-light-danger"> Passive </span>
                        </a>
                      </span>
                  </template>
                  <template #is_online_accounting-cell-template="{ data }"> 
                      <span> 
                        <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="openAccountingPeriodModal('editItem', data.data)">
                          <span :class="data.data.is_online_accounting == 1 ? 'badge badge-light-success' : 'badge badge-light-danger'">
                            {{ data.data.is_online_accounting_name }}
                          </span>
                        </a>
                      </span>
                  </template>
                  <template #is_vat_active-cell-template="{ data }"> 
                      <span> 
                        <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="openAccountingPeriodModal('editItem', data.data)">
                          <span :class="data.data.is_vat_active == 1 ? 'badge badge-light-success' : 'badge badge-light-danger'">
                            {{ data.data.is_vat_active_name }}
                          </span>
                        </a>
                      </span>
                  </template>
                  <template #cost_of_sales_method-cell-template="{ data }"> 
                      <span> 
                        <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="openAccountingPeriodModal('editItem', data.data)">
                          {{ data.data.cost_of_sales_name }} / {{ data.data.cost_of_sales_method_name }}
                        </a>
                      </span>
                  </template>
                  <template #plan_template_name-cell-template="{ data }"> 
                      <span> 
                        <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="openAccountingPeriodModal('editItem', data.data)">
                            {{ data.data.plan_template_name }}
                        </a>
                      </span>
                  </template>
                  <template #is_accounting_period_closed-cell-template="{ data } ">
                      <span> 
                        <a href="#" class="text-gray-800 text-hover-primary mb-1"  data-bs-toggle="modal"  data-bs-target="#kt_modal_add_year"  data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" @click="openAccountingPeriodModal('editItem', data.data)">
                          <span v-if="data.data.is_accounting_period_closed == 0" class="badge badge-light-success"> Active </span>
                          <span v-if="data.data.is_accounting_period_closed == 1" class="badge badge-light-danger"> Closed </span>
                        </a>
                      </span>
                  </template>
                </DxDataGrid>
              </div> 
            </div>
          </div>
        <!-- ADD ACCOUNTING YEAR MODAL -->
        <div class="modal fade" id="kt_modal_add_year" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content"  >
                    <div class="modal-header bg-primary" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-11 text-white">
                            <span v-if="this.modalType == 'addItem'"> {{ $t('Add') }} </span>
                            <span v-if="this.modalType == 'editItem'"> {{accountingYearModalForm.period_year_short}} </span> {{ $t('Accounting Periods') }}
                        </h2>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary">
                          <i class="bi bi-gear fs-2 mt-1 cursor-pointer text-white text-hover-dark"></i>  
                        </div> 
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closePeriodModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y" :class="{'overlay overlay-block': this.isAccountingYearSaving == true}">
                        <form class="form fv-plugins-bootstrap5 fv-plugins-framework"> 
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="card card-xxl-stretch mb-6">
                                                <div class="card-body p-4">
                                                    <div class="row mb-2" v-if="this.modalType == 'addItem'">
                                                        <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                              {{ $t('Year') }}
                                                        </label>
                                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                            <Datepicker :textInputOptions="{ format: 'yyyy' }" v-maska="'####'" v-model="accountingYearModalForm.year" yearPicker autoApply :clearable="false"></Datepicker>  
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2" v-if="this.modalType == 'addItem' && this.filteredCompanyAccountingPeriods.length > 0">
                                                        <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                            {{ $t('Accounting Plan Option') }}
                                                        </label>
                                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                          <Select2 v-model="accountingYearModalForm.account_plan_option"
                                                            :options="planOptionsList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_year .modal-content' }"/>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2" v-if="accountingYearModalForm.account_plan_option == 2">
                                                        <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                            {{ $t('Accounting Plan Templates') }}
                                                        </label>
                                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                          <Select2 v-model="accountingYearModalForm.plan_template_id" :options="planTemplateList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_year .modal-content' }"/>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                            {{ $t('Cost Of Sales') }}
                                                        </label>
                                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                          <Select2 v-model="accountingYearModalForm.cost_of_sales" :options="costOfSalesList" 
                                                          :disabled="accountingYearModalForm.cost_of_sales_method_status == true" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_year .modal-content' }"/>
                                                          <small class="ps-1 text-gray-600" v-if="accountingYearModalForm.cost_of_sales_method_status == true"> {{ $t('Cost Of Sales cannot be change because this accounting period has transactions.') }}..! </small>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                          {{ $t('Cost Of Sales Method') }}
                                                        </label>
                                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                          <Select2 v-model="accountingYearModalForm.cost_of_sales_method" :options="costOfSalesMethodList" 
                                                            :disabled="accountingYearModalForm.cost_of_sales_method_status == true"  :settings="{ width: '100%', dropdownParent: '#kt_modal_add_year .modal-content' }"/> 
                                                            <small class="ps-1 text-gray-600" v-if="accountingYearModalForm.cost_of_sales_method_status == true"> {{ $t('Cost Of Sales Method cannot be change because this accounting period has transactions.') }}..! </small>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2" v-if="this.modalType == 'editItem'">
                                                        <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                              {{ $t('Active') }}
                                                        </label>
                                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                          <input class="form-check-input mt-3" type="checkbox" value="" id="isDefault" v-model="accountingYearModalForm.is_active">
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2" v-if="this.modalType == 'editItem'">
                                                        <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                              {{ $t('Online Accounting') }}
                                                        </label>
                                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                          <input class="form-check-input mt-3" type="checkbox" value="" id="isDefault" v-model="accountingYearModalForm.is_online_accounting">
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2" v-if="this.modalType == 'editItem'">
                                                        <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                            {{ $t('Is VAT Active?') }}
                                                        </label>
                                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                          <input class="form-check-input mt-3" type="checkbox" value="" id="isDefault" v-model="accountingYearModalForm.is_vat_active">
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2" v-if="this.modalType == 'editItem'">
                                                        <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                            {{ $t('Is Accounting Perid Closed') }}
                                                        </label>
                                                        <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                          <input class="form-check-input mt-3" type="checkbox" value="" id="isDefault" v-model="accountingYearModalForm.is_accounting_period_closed">
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="d-grid gap-2"> 
                                            <button type="button" class="btn btn-success btn-lg" v-if="this.modalType == 'addItem'" 
                                                @click="this.addAccountingPeriod()">
                                                <strong>{{ $t('Save') }}</strong> 
                                            </button>
                                            <button type="button" class="btn btn-primary btn-lg" v-if="this.modalType == 'editItem'" 
                                                @click="this.updateAccountingPeriod()">
                                                <strong>{{ $t('Update') }}</strong> 
                                            </button>
                                            <button type="button" class="btn btn-danger float-start btn-lg" v-if="this.modalType == 'editItem'" 
                                                @click="this.removeAccountingPeriod(this.accountingYearModalForm.id)">
                                                <strong>{{ $t('Delete') }}</strong> 
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isAccountingYearSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios' 
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';
// import Swal from "sweetalert2";
 
export default {
  name: "AccountingSettings", 
  components:{
      DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
  },
  data() {
    return {
      isProgressing: false,
      menuSelection: '/AccountingSettingsHelppage',
      iconView : false,
      userData: this.$getUser(),
      lists: [],
      companyTypes: [],
      data: {},
      activeView: "General", 
      addressList: [{id: '-1', text: 'Select an option'}],
      searchbar: '',
      modalType: 'addItem',
      costOfSalesMethodList: [
        { id: 1, text: 'First In First Out'},
        { id: 2, text: 'Moving Average Cost'}, /* hareketli ağırlıklı ortalama maliyet */
      ],
      costOfSalesList: [
        { id: 1, text: 'Apply'},
        { id: 0, text: 'Not Apply'},
      ],
      automaticTaskOptionList: [
        {id: 0, text: 'No'},
        {id: 1, text: 'Yes'},
      ],
      isHide: true,
      form: { 
        id: null,
        incorporated_at: new Date(),
        accounting_start_period: new Date(),
        accounting_end_period: new Date(),
        vat_start_period: new Date(),
        vat_end_period: new Date(),
        accounting_fee_monthly: 0.00,
        accounting_fee_yearly: 0.00,
        account_office_reference: null,
        vat_period: 1,
        payroll_period: 1,
        accounting_fee_monthly_date: new Date(),
        accounting_fee_yearly_date: new Date(),
        accountingFeeSelection: 'monthly',
        accounting_fee_period: 1,
        company_country_code: null,
        company_type_id: '-1',
        company_type_plan_id: null,
        is_vat_active: 'false',
        cost_of_sales_method_default: 1,
        cost_of_sales_default: 0
      },
      accountingYearModalForm: {
        year: moment().year(),
        month: 12,
        is_online_accounting: false,
        is_active: false,
        is_vat_active: false,
        cost_of_sales_method_status: false,
        plan_template_id: '-1',
        is_automatic_task: 0,
        account_plan_option: 1,
        cost_of_sales_method: 1,
        cost_of_sales: 0,
        is_accounting_period_closed: false
      },
      companyAccountingPeriods: [],      
      page_size: 10,
      page: 1,
      isAccountingYearSaving: false,
      planTemplateList: [],
      planOptionsList: [{id: 1, text: 'From the previous year'}, {id: 2, text: 'From the template'}],    
      vatPeriodList: [ {id: 1, text: 'Monthly'}, {id: 2, text: 'Periodic'}, {id: 3, text: 'Yearly'}],
      payrollPeriodList: [ {id: 1, text: 'Monthly'}, {id: 2, text: 'Periodic'}],
      pageSize: 10,
      isListProgressing: false
    };
  }, 
  computed: {    
    previewPeriodDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    filteredCompanyAccountingPeriods(){
        return this.companyAccountingPeriods.filter(p => { 
            return p.id != '-1';
        });
    },
    showPageSizeSelector() {
        return this.companyAccountingPeriods.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.companyAccountingPeriods.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    },
    isAccountingPeriodClosed(){
      return this.companyAccountingPeriods.is_accounting_period_closed == 1;
    }
  },
  methods: {
    getData() {            
      this.lists = []; 
      this.searchbar = '';       
      this.$Progress.start();
      this.$setSessionStorage('refreshList', true);   
      const queryParameter = {
        session_id: this.$getUser().session_id,
      };
      axios.post('/api/CompanySettings/Get', queryParameter, {'Content-Type': 'application/json'})
      .then((response) => {
        this.form = { 
            id: response.data.id,
            company_member_type: response.data.company_member_type,
            incorporated_at: response.data.incorporated_at,
            accounting_start_period: response.data.accounting_start_period,
            accounting_end_period: response.data.accounting_end_period,
            vat_start_period: response.data.vat_start_period,
            vat_end_period: response.data.vat_end_period,
            is_vat_active: response.data.is_vat_active == 1 ? 'true' : 'false',
            accounting_fee_monthly: parseFloat(response.data.accounting_fee_monthly.replace(/,/g, '')),
            accounting_fee_yearly: parseFloat(response.data.accounting_fee_yearly.replace(/,/g, '')),
            accountingFeeSelection: 'monthly',
            account_office_reference: response.data.account_office_reference,
            vat_period: response.data.vat_period,
            payroll_period: response.data.payroll_period,
            accounting_fee_monthly_date: response.data.accounting_fee_monthly_date,
            accounting_fee_yearly_date: response.data.accounting_fee_yearly_date,
            accounting_fee_period: response.data.accounting_fee_period,
            company_type_id: response.data.company_type_id,
            company_country_code: response.data.company_country_code,
            company_type_plan_id: response.data.company_type_plan_id,
            cost_of_sales_method_default: response.data.cost_of_sales_method_default,
            cost_of_sales_default: response.data.cost_of_sales_default,
        };                        

        if(this.form.accounting_fee_period == 1){ this.form.accountingFeeSelection = 'monthly'; }
        if(this.form.accounting_fee_period == 3){ this.form.accountingFeeSelection = 'yearly'; }
        this.$Progress.finish();
      })
      .catch(function (error) {
        console.log(error);
        this.$Progress.finish();
      });  
    },
    removeAccountingPeriod(id){
      this.$swal.fire({
        title: 'Are you sure you want to delete this Accounting Period with Accounting Plans?',           
        icon: 'warning',
        confirmButtonColor: '#f1416c', //'#E53935',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete it!'
        }).then((result) => { 
        if (result.isConfirmed) { 
          const params = {
              session_id : this.$getUser().session_id,
              id: id
          };
          axios.post("/api/Auth/DeleteCompanyAccountingYear", params, {'Content-Type': 'application/json'})  
          .then((response) => { 
            if(!response.data.error){
              this.$refs.closePeriodModal.click();
              this.getAccountingYears();
            } 
          })
          .catch(function (error) {
              console.log(error);
          });
        }
      })  
    },    
    saveData(){
        this.isProgressing = true;
        if (this.form.accounting_fee_monthly == null || this.form.accounting_fee_monthly == '' || this.form.accounting_fee_monthly == undefined) { this.form.accounting_fee_monthly = 0; }
        if (this.form.accounting_fee_yearly == null || this.form.accounting_fee_yearly == '' || this.form.accounting_fee_yearly == undefined) { this.form.accounting_fee_yearly = 0; }
        const params = this.form;
        const that = this;
        params.incorporated_at = params.incorporated_at !== null ? moment(params.incorporated_at).format('YYYY-MM-DD[T]HH:mm:ss') : null;
        params.accounting_start_period = params.accounting_start_period !== null ? moment(params.accounting_start_period).format('YYYY-MM-DD[T]HH:mm:ss') : null;
        params.accounting_end_period = params.accounting_end_period !== null ? moment(params.accounting_end_period).format('YYYY-MM-DD[T]HH:mm:ss') : null;
        params.vat_start_period = params.vat_start_period !== null ? moment(params.vat_start_period).format('YYYY-MM-DD[T]HH:mm:ss') : null;
        params.vat_end_period = params.vat_end_period !== null ? moment(params.vat_end_period).format('YYYY-MM-DD[T]HH:mm:ss') : null;
        params.accounting_fee_yearly_date = params.accounting_fee_yearly_date !== null ? moment(params.accounting_fee_yearly_date).format('YYYY-MM-DD[T]HH:mm:ss') : null;
        params.accounting_fee_monthly_date = params.accounting_fee_monthly_date !== null ? moment(params.accounting_fee_monthly_date).format('YYYY-MM-DD[T]HH:mm:ss') : null;
        params.session_id = this.$getUser().session_id;
        if(params.accountingFeeSelection == 'monthly'){
          params.accounting_fee_period = 1;
          params.accounting_fee_yearly = 0.00;
          params.accounting_fee_yearly_date = null;
        }
        if(params.accountingFeeSelection == 'yearly'){
          params.accounting_fee_period = 3;
          params.accounting_fee_monthly = 0.00;
          params.accounting_fee_monthly_date = null;
        }
        this.form.is_vat_active == 'true' ? params.is_vat_active = 1 : params.is_vat_active = 0; 
        axios.post('/api/CompanySettings/UpdateAccountingSettings', params, {'Content-Type': 'application/json'})
          .then((response) => { 
              if(!response.data.error && response.data != null && response.data.length == 36){
                params.is_vat_active == 1 ? this.form.is_vat_active = 'true' : this.form.is_vat_active = 'false'; 
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.$setSessionStorage('refreshList', true);   
              }
          })
          .catch(function (error) {
              console.log(error);
          })
          .finally(function () { 
              setTimeout(() => {
                  that.isProgressing = false;
              }, 500);
          }); 
    },
    getAccountingYears() {   
      this.$Progress.start(); 
      this.isListProgressing = true;
      const parameters = {
        session_id : this.$getUser().session_id
      };   
      const parametersTwo = {
        session_id: this.$getUser().session_id,
        country_code: this.form.company_country_code,
        get_access_status: 0,
        member_type: this.form.company_member_type
      };
      const that = this;
      const requestOne =  axios.post('/api/Auth/GetAllCompanyAccountingYears', parameters, {'Content-Type': 'application/json'});
      const requestTwo =  axios.post('/api/NewCompany/GetCompanyTypes', parametersTwo, {'Content-Type': 'application/json'});
      axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
        this.companyAccountingPeriods = responses[0].data;
        if(this.companyAccountingPeriods.length > 0) {
          this.$setSessionStorage('activeYear', responses[0].data[0].company_active_period_id);
        }
        this.companyTypes = responses[1].data;
        this.$Progress.finish();
      }))
      .catch(errors => { 
        console.error(errors); 
        that.isListProgressing = false;
        this.$Progress.finish();
      })
      .finally(function() {
          that.isListProgressing = false;
          that.$Progress.finish();
      });
    },
    addAccountingPeriod() {
      this.isAccountingYearSaving = true;
      const that = this;
      if(this.accountingYearModalForm.year == null || !(this.accountingYearModalForm.month > 0)){
          this.$swal.fire({
            icon: 'error',
            title: 'An Error Occured !!',
            text: 'Year should be defined..!'
          });
      }
      else {  
      const params = {
        year: parseInt(this.accountingYearModalForm.year),
        session_id : this.$getUser().session_id,
        cost_of_sales_method: this.accountingYearModalForm.cost_of_sales_method,
        plan_template_id: this.accountingYearModalForm.plan_template_id,
        is_automatic_task: this.accountingYearModalForm.is_automatic_task,
        account_plan_option: this.accountingYearModalForm.account_plan_option,
        cost_of_sales: this.accountingYearModalForm.cost_of_sales,
      };
      if (params.plan_template_id == '-1') { params.plan_template_id = null; }
      axios.post('/api/Auth/CreateCompanyAccountingYears', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error && response.data != null && response.data.length == 36){
              this.isAccountingYearSaving = false;
              this.$refs.closePeriodModal.click();
            }
            this.getAccountingYears();
        })
        .catch(function (error) {
            console.log(error);
            this.isAccountingYearSaving = false;
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function(){            
            that.isAccountingYearSaving = false;
        });
      } 
    },
    updateAccountingPeriod() {
      this.isAccountingYearSaving = true;
      const that = this;
      const params = this.accountingYearModalForm;
      params.session_id = this.$getUser().session_id,
      params.is_online_accounting = params.is_online_accounting ? 1 : 0;
      params.is_vat_active = params.is_vat_active ? 1 : 0;
      params.is_active = params.is_active ? 1 : 0;
      params.is_accounting_period_closed = params.is_accounting_period_closed ? 1 : 0;
      if (params.plan_template_id == '-1') { params.plan_template_id = null; }
      axios.post('/api/Auth/UpdateCompanyAccountingYears', params, {'Content-Type': 'application/json'}) 
      .then((response) => {
        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
          this.$setSessionStorage('activeYear', response.data);
          this.$swal("Success", "Accounting Period saved succesfully..!", 'success');
          this.isAccountingYearSaving = false;
          this.$refs.closePeriodModal.click();
          this.getAccountingYears();
        }
      })
      .catch(function (error) {
          console.log(error);
          this.isAccountingYearSaving = false;
          this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
      })
      .finally(function(){
          that.isAccountingYearSaving = false;
      });
    },
    openAccountingPeriodModal(modalType, data) {
      this.modalType = modalType;   
      this.accountingYearModalForm = {
        year: moment().year(),
        month: 12,
        cost_of_sales_method: this.form.cost_of_sales_method_default,
        is_online_accounting: false,
        is_active: false,
        is_vat_active: false,
        cost_of_sales_method_status: false,
        plan_template_id: '-1',
        is_automatic_task: 0,
        period_year_short: null,
        account_plan_option: 1,
        cost_of_sales: this.form.cost_of_sales_default,
        is_accounting_period_closed: false
      }       
      if (modalType == 'editItem') {
        this.accountingYearModalForm = {
          id: data.id,
          year: data.year,
          month: data.month,
          cost_of_sales_method: data.cost_of_sales_method,
          is_online_accounting: data.is_online_accounting == 1 ? true : false,
          is_active: data.is_active == 1 ? true : false,
          is_vat_active: data.is_vat_active == 1 ? true : false,
          cost_of_sales_method_status: data.cost_of_sales_method_status,
          plan_template_id: data.plan_template_id == '' ? '-1' : data.plan_template_id,
          is_automatic_task: data.is_automatic_task,
          period_year_short: data.period_year_short,
          cost_of_sales: data.cost_of_sales,
          is_accounting_period_closed: data.is_accounting_period_closed == 1 ? true : false,

        }
      }
      if(this.filteredCompanyAccountingPeriods.length == 0) {this.accountingYearModalForm.account_plan_option = 2;}
      const params = {
        session_id : this.$getUser().session_id,
        get_access_status: 0
      };
      axios.post("/api/Auth/GetPlanTemplates", params, {'Content-Type': 'application/json'}).then((templateResponse) => {
          this.planTemplateList = templateResponse.data;
          this.planTemplateList.unshift({id: '-1', text: 'Select an option'});

          if (modalType == 'addItem') {
            this.planTemplateList.forEach(planTemplates => {
              if (this.form.company_type_plan_id == planTemplates.id) {
                this.accountingYearModalForm.plan_template_id = planTemplates.id;
              }
            });
          }
      })
      .catch(function (error) {
          console.log(error);
      });
    },
    async helpPageViewIcon(){
      const queryparameter = {
          session_id : this.$getUser().session_id,
          menu_selection : '/AccountingSettingsHelppage'
      };
      axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
      .then((response) => { 
          if(!response.data.error){ 
              this.iconView = response.data;
          }
      })
    }
  }, 
  created() {
      this.helpPageViewIcon();
      this.getData();
  }
}; 
</script>