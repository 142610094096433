<template> 
  <div> 
    <div class="ms-4">
        <div class="page-title flex-row me-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Collected List') }}
            </h1>  
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-primary">{{ $t('Reports') }}</li>   
                <li class="breadcrumb-item text-primary">{{ $t('Account Reports') }}</li>  
                <li class="breadcrumb-item text-gray-600">{{ $t('Collected List') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                </li> 
            </ul> 
        </div>
    </div>  
    <div class="content flex-row-fluid mt-4" id="kt_content">
        <div class="card">
            <div class="card-header border-0 pt-6 px-7">
               <div class="card-title">
                    <div class="fv-row mb-4 row ">
                        <div class="col-lg">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>{{ $t('Currency') }}</span>
                            </label>
                            <Select2 v-model="form.currency" :options="currencyList" :settings="{width: '100%'}" />
                        </div>
                        <div class="col-lg">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>{{ $t('Date Type') }}</span>
                            </label>
                            <Select2 v-model="form.date_type" :options="dateTypeList" :settings="{width: '100%'}" />
                        </div>
                        <div class="col-lg">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span v-if="form.date_type == 1">{{ $t('Start Due Date') }}</span>
                                <span v-if="form.date_type == 2">{{ $t('Start Date') }}</span>
                            </label>
                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.first_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                        </div> 
                        <div class="col-lg">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span v-if="form.date_type == 1">{{ $t('End Due Date') }}</span>
                                <span v-if="form.date_type == 2">{{ $t('End Date') }}</span>
                            </label>
                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.last_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                        </div> 
                        <div class="col-lg">
                            <label class="fs-6 fw-bold form-label mt-3">
                                <span>{{ $t('Remain Status') }}</span>
                            </label>
                            <Select2 v-model="form.remain_status" :options="isRemainStatusList" :settings="{width: '100%'}" />
                        </div>
                    </div>
                </div>
                <div class="card-toolbar" v-if="this.$isCredentialActive(69,'R')">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                        <Popper hover placement="top">
                            <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                <i class="bi bi-eraser-fill fs-4"> </i>
                            </button>
                            <template #content>
                                <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                            </template>
                        </Popper> 
                        <button type="button" class="btn btn-light-primary border border-primary" @click="getData()" data-kt-indicator="on">
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span>
                            <span v-if="!this.isListProgressing"> {{ $t('List') }} </span> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button> 
                    </div> 
                </div>
            </div>
            <div class="card-body pt-3">
                <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px"> 
                        <ListOptions :name="this.$options.name" @click="SaveDXStateToStorage" ref="listOptionComponent"></ListOptions>
                        <DxDataGrid id="gridContainer"
                                :show-borders="true"
                                key-expr="id"
                                :data-source="lists"
                                :allow-column-reordering="true" 
                                :allow-column-resizing="true"
                                :column-auto-width="true"
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" 
                                :ref="'dataGrid'"
                                :customize-columns="onCustomizeColumn"
                                @exporting="this.$onExporting($event , 'Collected-List')">   
                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="pageSize"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizesLists" :show-page-size-selector="showPageSizeSelectorLists" :show-info="true" :show-navigation-buttons="true"/>    
                                <DxSearchPanel :visible="true"/>
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                />
                                <DxColumn data-field="transaction_date" data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Transaction Date')" header-cell-template="transaction_date-header"/>
                                <DxColumn data-field="transaction_due_date" :caption="$t('Trns. Due Date')" data-type="date" alignment="right" format="dd.MM.yyyy" header-cell-template="transaction-duedate-header"/> 
                                <DxColumn data-field="account_name" :caption="$t('Account')" />
                                <DxColumn data-field="transaction_type_name" :caption="$t('Transaction Type')" header-cell-template="transaction-type-header"/> 
                                <DxColumn data-field="transaction_subtype_name" :caption="$t('Transaction Sub Type')" header-cell-template="transaction-sub-type-header"/> 
                                <DxColumn data-field="transaction_no" :caption="$t('Transaction No')" header-cell-template="transaction-no-header"/>
                                <DxColumn data-field="currency" :caption="$t('Currency')"/> 
                                <DxColumn data-field="amount" :sorting-method="this.$numericSort" :caption="$t('Amount')" header-cell-template="amount-header" alignment="right"/> 
                                <DxColumn data-field="rate" :sorting-method="this.$numericSort" :caption="$t('Rate')" alignment="right"/> 
                                <DxColumn data-field="used_amount" :sorting-method="this.$numericSort" :caption="$t('Closed Amount')" alignment="right" header-cell-template="closed-amount-header"/> 
                                <DxColumn data-field="remain_amount" :sorting-method="this.$numericSort" :caption="$t('Remain Amount')" alignment="right" header-cell-template="remain-amount-header"/> 
                                <DxColumn data-field="local_amount" :sorting-method="this.$numericSort" :caption="$t('Collected Amount Local')" alignment="right" header-cell-template="amount-local-header"/> 
                                <DxColumn data-field="used_local_amount" :sorting-method="this.$numericSort" :caption="$t('Closed Amount Local')" alignment="right" header-cell-template="closed-local-amount-header"/> 
                                <DxColumn data-field="remain_local_amount" :sorting-method="this.$numericSort" :caption="$t('Remain Amount Local')" alignment="right" header-cell-template="remain-amount-local-header"/> 

                                <DxColumn cssClass="bg-light-indigo" data-field="cross_transaction_type" :caption="$t('Collection Transaction Type')" header-cell-template="collection-transaction-type-header"/> 
                                <DxColumn cssClass="bg-light-indigo" data-field="cross_transaction_no" :caption="$t('Collection Transaction No')"  header-cell-template="collection-transaction-no-header"/>
                                <DxColumn cssClass="bg-light-indigo" data-field="cross_amount" :sorting-method="this.$numericSort" :caption="$t('Collection Amount')" alignment="right" header-cell-template="collection-amount-header"/> 
                                <DxColumn cssClass="bg-light-indigo" data-field="cross_remain_amount" :sorting-method="this.$numericSort" :caption="$t('Collection Remain Amount')" alignment="right" header-cell-template="collection-remain-amount-header"/> 
                                <DxColumn cssClass="bg-light-indigo" data-field="cross_amount_local" :sorting-method="this.$numericSort" :caption="$t('Collection Amount Local')" alignment="right" header-cell-template="collection-amount-local-header"/> 
                                <DxColumn cssClass="bg-light-indigo" data-field="cross_remain_amount_local" :sorting-method="this.$numericSort" :caption="$t('Collection Remain Amount Local')" alignment="right" header-cell-template="collection-remain-amount-local-header"/> 
                                <DxColumn data-field="record_date" data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Record Date')" header-cell-template="record-date-header"/>

                                <!-- Yerel para birimi gösterimi için özel sütun şablonu oluşturulur --> 
                                <template #headerLocalTemplate="{ data: data }">
                                    <div v-html="setColumnTemplateForLocal(data)"></div> 
                                </template>

                                <template #record-date-header>
                                    <div>{{ $t('Record') }}</div><div>{{ $t('Date') }}</div>
                                </template>
                                <template #transaction_date-header>
                                    <div>{{ $t('Transaction') }}</div><div>{{ $t('Date') }}</div>
                                </template>
                                <template #amount-header>
                                    <div>{{ $t('Collected') }}</div><div>{{ $t('Amount') }}</div>
                                </template>
                                <template #transaction-type-header>
                                    <div>{{ $t('Transaction') }}</div><div>{{ $t('Type') }}</div>
                                </template>
                                <template #transaction-sub-type-header>
                                    <div>{{ $t('Transaction Sub') }}</div><div>{{ $t('Type') }}</div>
                                </template>
                                <template #collection-transaction-type-header>
                                    <div>{{ $t('Collection') }}</div><div>{{ $t('Transaction Type') }}</div>
                                </template>
                                <template #collection-transaction-no-header>
                                    <div>{{ $t('Collection') }}</div><div>{{ $t('Transaction No') }}</div>
                                </template>
                                <template #transaction-no-header>
                                    <div>{{ $t('Transaction') }}</div><div>{{ $t('No') }}</div>
                                </template>
                                <template #transaction-duedate-header>
                                    <div>{{ $t('Transaction') }}</div><div>{{ $t('Due Date') }}</div>
                                </template>
                                <template #closed-amount-header>
                                    <div>{{ $t('Closed') }} </div><div>{{ $t('Amount') }}</div>
                                </template>
                                <template #amount-local-header>
                                    <div>{{ $t('Collected Amount') }}</div><div>{{ $t('Local') }}</div>
                                </template>
                                <template #closed-local-amount-header>
                                    <div>{{ $t('Closed') }} </div><div>{{ $t('Local Amount') }}</div>
                                </template>
                                <template #remain-amount-header>
                                    <div>{{ $t('Remain') }}</div><div>{{ $t('Amount') }}</div>
                                </template>
                                <template #remain-amount-local-header>
                                    <div>{{ $t('Remain') }}</div><div>{{ $t('Local Amount') }}</div>
                                </template>
                                <template #first-rate-header>
                                    <div>{{ $t('First') }}</div><div>{{ $t('Rate') }}</div>
                                </template>
                                <template #last-local-amount-header>
                                    <div>{{ $t('Last') }}</div><div>{{ $t('Local Amount') }}</div>
                                </template>
                                <template #collection-amount-header>
                                    <div>{{ $t('Collection') }}</div><div>{{ $t('Amount') }}</div>
                                </template>
                                <template #collection-amount-local-header>
                                    <div>{{ $t('Collection Amount') }}</div><div>{{ $t('Local') }}</div>
                                </template>
                                <template #collection-remain-amount-header>
                                    <div>{{ $t('Collection') }}</div><div>{{ $t('Remain') }}</div>
                                </template>
                                <template #collection-remain-amount-local-header>
                                    <div>{{ $t('Collection') }}</div><div>{{ $t(' Remain Local') }}</div>
                                </template>

                                <DxStateStoring
                                    :enabled="true"
                                    type="custom"
                                    :storage-key="this.storageKey"
                                    :ref="dataGrid" 
                                    :saving-timeout="1000"
                                    :custom-load="LoadDXState"
                                />

                                <DxSummary :calculate-custom-summary="setSummaries">
                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="transaction_due_date"/>
                                    <DxGroupItem v-if="this.form.currency !== '-1' && this.form.currency !== this.localCurrency"
                                        :show-in-group-footer="true"
                                        name="amount"
                                        summary-type="custom"
                                        show-in-column="amount"
                                        display-format="{0}"
                                    /> 
                                    <DxTotalItem v-if="this.form.currency !== '-1' && this.form.currency !== this.localCurrency"
                                        name="amount"
                                        summary-type="custom"
                                        show-in-column="amount"
                                    />
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="local_amount"
                                        summary-type="custom"
                                        show-in-column="local_amount"
                                        display-format="{0}"
                                    /> 
                                    <DxTotalItem name="local_amount"
                                        summary-type="custom"
                                        show-in-column="local_amount"
                                    />
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="used_local_amount"
                                        summary-type="custom"
                                        show-in-column="used_local_amount"
                                        display-format="{0}"
                                    /> 
                                    <DxTotalItem name="used_local_amount"
                                        summary-type="custom"
                                        show-in-column="used_local_amount"
                                    />
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="remain_local_amount"
                                        summary-type="custom"
                                        show-in-column="remain_local_amount"
                                        display-format="{0}"
                                    /> 
                                    <DxTotalItem name="remain_local_amount"
                                        summary-type="custom"
                                        show-in-column="remain_local_amount"
                                    />
                                </DxSummary>
                        </DxDataGrid> 
                    </div>
            </div> 
            </div>
        </div> 
    </div>  

    <div class="modal fade" id="kt_modal_view_detail" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen">
            <div class="modal-content"  >
                <div class="modal-header" id="kt_modal_add_user_header">
                    <h2 class="modal-title fw-bolder col-md-10">
                        {{ $t('Offset Detail') }}                     
                    </h2>  
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body scroll-y" :class="{'overlay overlay-block': this.isDocketItemSaving == true}">
                    <form class="form fv-plugins-bootstrap5 fv-plugins-framework"> 
                        <div class="row"> 
                             <div class="card p-0">  
                                <div class="card-body px-3">
                                    <div>
                                        <div class="table-responsive min-h-300px">
                                            <DxDataGrid id="gridContainer" 
                                                :show-borders="true" 
                                                :data-source="detailList" 
                                                :allow-column-reordering="true"  
                                                :allow-column-resizing="true" 
                                                :column-auto-width="true" 
                                                @exporting="this.$onExporting($event , 'Collected-List')" >

                                                <DxFilterRow :visible="true"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxGroupPanel :visible="true"/>      
                                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                <DxPaging :page-size="10"/>
                                                <DxPager :visible="false" :allowed-page-sizes="pageSizesDetailList" :show-page-size-selector="showPageSizeSelectorDetailList" :show-info="true" :show-navigation-buttons="true"/>    
                                                <DxSearchPanel :visible="true"/>
                                                
                                                <DxColumn data-field="account_name" :caption="$t('Account')" /> 
                                                <DxColumn data-field="due_transaction_type_name" :caption="$t('Due Type')" /> 
                                                <DxColumn data-field="due_transaction_subtype_name" :caption="$t('Due Subtype')" /> 
                                                <DxColumn data-field="due_transaction_no" :caption="$t('Due Transaction No')" /> 
                                                <DxColumn data-field="due_record_date" :caption="$t('Due Record Date')" data-type="date" alignment="right" format="dd.MM.yyyy"/> 
                                                <DxColumn data-field="due_transaction_due_date" :caption="$t('Transaction Due Date')" data-type="date" alignment="right" format="dd.MM.yyyy"/> 
                                                <DxColumn data-field="currency" :caption="$t('Currency')" /> 
                                                <DxColumn data-field="offset_amount" :sorting-method="this.$numericSort" :caption="$t('Offset Amount')" alignment="right"/> 
                                                <DxColumn data-field="due_amount" :sorting-method="this.$numericSort" :caption="$t('Due Amount')" alignment="right"/> 
                                                <DxColumn data-field="collection_amount" :sorting-method="this.$numericSort" :caption="$t('Collection Amount')" alignment="right"/> 
                                                <DxColumn data-field="collection_transaction_type_name" :caption="$t('Collection Type')" /> 
                                                <DxColumn data-field="collection_transaction_subtype_name" :caption="$t('Collection Subtype')" /> 
                                                <DxColumn data-field="collection_transaction_no" :caption="$t('Collection Transaction No')" /> 
                                                <DxColumn data-field="collection_record_date" :caption="$t('Collection Record Date')" data-type="date" alignment="right" format="dd.MM.yyyy"/> 
                                                <DxColumn data-field="collection_transaction_due_date" :caption="$t('Collection Transaction Due Date')" data-type="date" alignment="right" format="dd.MM.yyyy"/> 

                                                <DxSummary>
                                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="account_name"/>
                                                </DxSummary>
                                            </DxDataGrid> 
                                        </div>
                                    </div> 
                                </div>
                            </div> 
                        </div>
                    </form>
                    <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isDocketItemSaving == true">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">{{ $t('Loading...') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
  </div>  
</template>
<script>
import axios from 'axios' 
// import Swal from 'sweetalert2';
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxStateStoring, DxTotalItem, DxSummary, DxGroupItem, DxScrolling } from 'devextreme-vue/data-grid';
 
export default {
    name: "CollectedList",
    components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxStateStoring, DxTotalItem, DxSummary, DxGroupItem, DxScrolling
    }, 
    data() {
        return {
            storageKey: 'dx_' + this.$options.name,
            pageSize: 100,
            pageSizeDetailList: 10,
            page_size: 20,
            lists: [],  
            detailList: [],
            searchbar: '',
            form: {
                first_date:  moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
                last_date: moment(),
                date_type: 2,
                currency: '-1',
                remain_status: 1
            },
            menuSelection: '/CollectedHelppage',
            iconView : false,
            isListProgressing: false,
            dateTypeList: [
                { id: 1, text: 'Due Date'},
                { id: 2, text: 'Record Date'},
            ],
            isRemainStatusList: [
                { id: 1, text: 'ALL'},
                { id: 2, text: 'With Remain'},
                { id: 3, text: 'No Remain'},
            ],
            currencyList: [],
            localCurrency: null
        };
    },
    computed: {
        searchableList() {
            return this.lists.filter(p => { 
                return p.account_name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
            });
        },
        previewDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
                }
            }
        },
        showPageSizeSelectorLists() {
            return this.lists.length > this.pageSize;
        },
        pageSizesLists() {
            let arr = [];
            let x = 1;
            let loop = (this.lists.length / this.pageSize);
            for (let i = 1; i <= loop && i <= 5; i++) {
                arr.push(this.pageSize * x);
                x++;
            }
            if(loop > 5) arr.push(1000);
            if(loop > 1) arr.push('all');
            return arr;
        },
        showPageSizeSelectorDetailList() {
            return this.detailList.length > this.pageSizeDetailList;
        },
        pageSizesDetailList() {
            let arr = [];
            let x = 1;
            let loop = (this.detailList.length / this.pageSizeDetailList);
            for (let i = 1; i <= loop && i <= 5; i++) {
                arr.push(this.pageSizeDetailList * x);
                x++;
            }
            if(loop > 5) arr.push(100);
            if(loop > 1) arr.push('all');
            return arr;
        },
        setColumnTemplateForLocal(){
            return (value)=>{
                const captionValue = value.column.caption.replace('Local ', '');
                return this.localCurrency == null ? this.$t(captionValue) : this.localCurrency + '<br>' + this.$t(captionValue);
            }
        },
    },
    methods: {
        getCurrencyList() {
            const parameters = {
                session_id: this.$getUser().session_id,
                list_name: ["currency"]
            };
            const requestOne =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'});
            axios.all([requestOne]).then(axios.spread((...responses) => {
                this.currencyList = responses[0].data.currency;
                this.currencyList.unshift({ id: '-1', text: 'ALL'});
                this.localCurrency = this.$getUser().currency_code;
                this.getData();
            }))
            .catch(errors => { 
                console.error(errors); 
            });
        },
        getData() {
            this.lists = [];
            this.isListProgressing = true;
            this.$Progress.start();
            this.form.first_date = (this.form.first_date == null || this.form.first_date == '') ? moment() : this.form.first_date;
            this.form.last_date = ( this.form.last_date == null || this.form.last_date == '') ? moment () : this.form.last_date;
            const queryParameter = {
                session_id: this.$getUser().session_id,
                first_date: moment(this.form.first_date).format('YYYY-MM-DD'),
                last_date: moment(this.form.last_date).format('YYYY-MM-DD'),
                bc_type: 2,
                date_type: this.form.date_type,
                currency: (this.form.currency !== '-1') ? this.form.currency : null,
                remain_status: this.form.remain_status
            };   
            const that = this;
            axios.post('/api/CollectionList/GetCollections', queryParameter, {'Content-Type': 'application/json'}) 
            .then((response) => {  
                this.lists = response.data;
                this.$Progress.finish();
            })
            .catch(function () {
                that.isListProgressing = false;
                this.$Progress.finish();
            })
            .finally(function() {
                that.isListProgressing = false;
                that.$Progress.finish();
            });
        },
        setCriteriasToDefault() {   
            this.form = {
                first_date: moment(),
                last_date: moment(),
                date_type: 2,
                currency: '-1',
                remain_status: 1
            };
            this.lists = [];
        },
        viewDetail(id){ 
            this.detailList = [];
            this.$Progress.start(); 
            const queryParameter = {
                session_id: this.$getUser().session_id,
                collection_id: id
            };   
            axios.post('/api/CollectionList/GetCollectionDetail', queryParameter, {'Content-Type': 'application/json'}) 
                .then((response) => {  
                    this.detailList = response.data; 
                    this.$Progress.finish();
                })
                .catch(function () {
                    this.$Progress.finish();
                });
        },
        /** DX STATE OPERATIONS **/  
        LoadDXState() {
            return JSON.parse(localStorage.getItem(this.storageKey));
        }, 
        SaveDXStateToStorage() { 
            const state = this.$refs.dataGrid.instance.state();
            localStorage.setItem(this.storageKey, JSON.stringify(state));
            this.$refs.listOptionComponent.getState();        
        },
        async helpPageViewIcon()
        {
            const queryparameter = {
                session_id : this.$getUser().session_id,
                menu_selection : '/CollectedHelppage'
            };
            axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(!response.data.error){ 
                    this.iconView = response.data;
                }
            })
        }, 
        setSummaries(options){  
        const optionNames = ["amount", "local_amount", "used_local_amount","remain_local_amount"]; 
        optionNames.forEach(item => {
            if(options.name == item){
                if(options.summaryProcess === 'start'){ options.totalValue = 0; }
                else if(options.summaryProcess === 'calculate'){
                    var itemValue = parseFloat(options.value[item].replaceAll(',', ''));
                    (options.totalValue += itemValue);
                }
                else if(options.summaryProcess === 'finalize'){ 
                    options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
                }
            }
        });
    },
        onCustomizeColumn(e){
            if(e){
                if(!e._isUpdated){
                    if(e.length > 0){ 
                        e._isUpdated = true;
                    }
                    e.forEach((column) => {   
                        if(column.dataField == 'local_amount'){ 
                            column.caption =  'Local Amount';
                            column.headerCellTemplate = 'headerLocalTemplate';
                        }
                        if(column.dataField == 'used_local_amount'){ 
                            column.caption =  'Closed Local Amount';
                            column.headerCellTemplate = 'headerLocalTemplate';
                        }
                        if(column.dataField == 'remain_local_amount'){ 
                            column.caption =  'Remain Local Amount';
                            column.headerCellTemplate = 'headerLocalTemplate';
                        }
                        if(column.dataField == 'cross_amount_local'){ 
                            column.caption =  'Collection Local Amount';
                            column.headerCellTemplate = 'headerLocalTemplate';
                        }
                        if(column.dataField == 'cross_remain_amount_local'){ 
                            column.caption =  'Collection Local Remain';
                            column.headerCellTemplate = 'headerLocalTemplate';
                        }
                    });  
                } 
            } 
        },
    },
    created() {
        this.getCurrencyList();
        this.helpPageViewIcon();
        this.lists = [];
        this.$removeSessionStorage('record_id');
    }
}; 
</script>