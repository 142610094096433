<template>
    <div class="card mb-5 mb-xl-10 bgi-no-repeat bgi-position-y-top bgi-position-x-end shadow-sm bg-abstract-2">
        <div class="card-body pt-9 pb-0">
            <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
                <div class="me-7 mb-4">
                    <div class="symbol symbol-90px symbol-lg-90px symbol-fixed position-relative" v-if="formAccount.account_type == 7">
                        <div class="image-input image-input-outline" data-kt-image-input="true">
                            <div class="image-input-wrapper" 
                            :style="{ backgroundImage: 'url(' + (formAccount.stock_image_url !== null && formAccount.stock_image_url !== '' && formAccount.stock_image_url !== undefined ? formAccount.stock_image_url : 'assets/theme/media/avatars/blank.png') +')', 
                                backgroundRepeat:'no-repeat' , backgroundPosition:'center', objectFit:'cover',backgroundSize:'cover' }" ></div>
                            <label v-if="this.$isModuleActive(3) && this.$isCredentialActive(6,'')" class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body" 
                                @click="onStockAccountImageUploads()"  data-bs-toggle="modal" data-bs-target="#kt_modal_stock_image_uploads" data-kt-menu-trigger="click"
                                data-kt-image-input-action="change" aria-label="Upload image" data-bs-original-title="Upload image" data-kt-initialized="1">
                                <i class="bi bi-pencil"></i>
                            </label>
                        </div>
                    </div>
                    <div v-else class="symbol symbol-90px symbol-lg-90px symbol-fixed position-relative">
                        <img src="assets/theme/media/avatars/blank.png" alt="image">
                        <div class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                        <div class="d-flex flex-column mw-50">
                            <div class="d-flex align-items-center mb-2">
                                <span v-if="formAccount.name !== null && formAccount.name !== undefined && formAccount.name !== ''"
                                    class="text-gray-900 fs-2 fw-bolder me-1 cursor-pointer" data-bs-toggle="tooltip" data-bs-trigger="hover" :title="formAccount.name">
                                    {{ formAccount.name.substring(0, 30) }} 
                                    <span v-if="formAccount.name.length > 30">...</span>
                                </span>
                                <span>
                                    <span class="svg-icon svg-icon-1 svg-icon-primary">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                            <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF"></path>
                                            <path class="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white"></path>
                                        </svg>
                                    </span>
                                </span>
                                <span class="bg-light-success text-success fw-bolder rounded mx-3 px-3 py-1" v-if="formAccount.account_id">{{formAccount.account_id}}</span>
                                <span class="bg-light-success text-success fw-bolder rounded mx-3 px-3 py-1" v-if="formAccount.code">{{formAccount.code}}</span>
                            </div>
                        </div>  
                        <div class="d-flex flex-column flex-grow-1 mt-1" v-if="formAccount.account_type !== null && formAccount.account_type !== '' && formAccount.account_type !== undefined">
                            <div class="d-flex flex-wrap justify-content-end" v-if="formAccount.account_type !== 3 && formAccount.account_type !== 4 && formAccount.account_type !== 7 && formAccount.account_type !== 8">
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <div class="fs-3 fw-bolder counted">{{summary.total_local_debit}}</div> <small class="mt-1 ms-1 fw-bold">{{localCurrency}}</small>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Debit</div>
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <div class="fs-3 fw-bolder counted">{{summary.total_local_credit}}</div> <small class="mt-1 ms-1 fw-bold">{{localCurrency}}</small>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Credit</div>
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <div class="fs-3 fw-bolder counted">{{summary.total_local_balance}}</div> <small class="mt-1 ms-1 fw-bold">{{localCurrency}}</small>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Balance</div>
                                </div>
                                <a href="#" class="btn btn-sm btn-icon btn-flex my-5 text-hover-primary" v-if="formAccount.account_type !== null && formAccount.account_type !== undefined && formAccount.account_type !== ''"
                                    @click="onOpenAccountReport()" data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                    <i class="fa fa-eye text-gray-600 fs-2x"></i>
                                </a>
                            </div>
                            <div class="d-flex flex-wrap justify-content-end" v-if="formAccount.account_type == 3 || formAccount.account_type == 4">
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <div class="fs-3 fw-bolder counted">{{summary.total_debit}}</div> <small class="mt-1 ms-1 fw-bold">{{formAccount.currency}}</small>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Debit</div>
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <div class="fs-3 fw-bolder counted">{{summary.total_credit}}</div> <small class="mt-1 ms-1 fw-bold">{{formAccount.currency}}</small>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Credit</div>
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <div class="fs-3 fw-bolder counted">{{summary.total_balance}}</div> <small class="mt-1 ms-1 fw-bold">{{formAccount.currency}}</small>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Balance</div>
                                </div>
                                <a href="#" class="btn btn-sm btn-icon btn-flex my-5 text-hover-primary" v-if="formAccount.account_type !== null && formAccount.account_type !== undefined && formAccount.account_type !== ''"
                                    @click="onOpenAccountReport()" data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                    <i class="fa fa-eye text-gray-600 fs-2x"></i>
                                </a>
                            </div>
                            <div class="d-flex flex-wrap justify-content-end" v-if="formAccount.account_type == 7">
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <div class="fs-3 fw-bolder counted">{{formAccount.item_amount}}</div>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Total In</div>
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <div class="fs-3 fw-bolder counted">{{formAccount.item_used}}</div>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Total Out</div>
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <div class="fs-3 fw-bolder counted">{{formAccount.item_remain}}</div>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Total Remain</div>
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <div class="fs-3 fw-bolder counted">{{formAccount.item_return}}</div>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Total Return</div>
                                </div>
                                <a href="#" class="btn btn-sm btn-icon btn-flex my-5 text-hover-primary" v-if="formAccount.account_type !== null && formAccount.account_type !== undefined && formAccount.account_type !== ''"
                                    @click="onOpenStockAccountReport()" data-bs-toggle="modal" data-bs-target="#kt_modal_stock_account_report_selection" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                    <i class="fa fa-eye text-gray-600 fs-2x"></i>
                                </a>
                            </div>
                            <div class="d-flex flex-wrap justify-content-end" v-if="formAccount.account_type == 8">
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <div class="fs-3 fw-bolder counted">{{summary.total_local_debit}}</div>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Total</div> 
                                    <!-- KDV Hariç -->
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <div class="fs-3 fw-bolder counted">{{summary.total_local_credit}}</div>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Amortization</div>
                                    <!-- KDV Hariç -->
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <div class="fs-3 fw-bolder counted">{{summary.total_local_balance}}</div>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Sold</div>
                                    <!-- KDV Hariç -->
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-3 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <div class="fs-3 fw-bolder counted">{{summary.total_local_balance}}</div>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Balance</div>
                                    <!-- KDV Hariç -->
                                </div>
                                <a href="#" class="btn btn-sm btn-icon btn-flex my-5 text-hover-primary" v-if="formAccount.account_type !== null && formAccount.account_type !== undefined && formAccount.account_type !== ''"
                                    @click="onOpenAccountReport()" data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                    <i class="fa fa-eye text-gray-600 fs-2x"></i>
                                </a>
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-grow-1 pe-8 mt-1" v-if="formAccount.id !== null && (formAccount.account_type == null || formAccount.account_type == '' || formAccount.account_type == undefined)">
                            <div class="d-flex flex-wrap justify-content-end">
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor"></rect>
                                                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <div class="fs-2 fw-bolder counted">$4,500</div>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Earnings</div>
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <span class="svg-icon svg-icon-3 svg-icon-danger me-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor"></rect>
                                                <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <div class="fs-2 fw-bolder counted">75</div>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Projects</div>
                                </div>
                                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    <div class="d-flex align-items-center text-gray-800">
                                        <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor"></rect>
                                                <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <div class="fs-2 fw-bolder counted">%60</div>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">Success Rate</div>
                                </div>
                                <a href="#" class="btn btn-sm btn-icon btn-flex my-5 text-hover-primary"
                                    @click="onOpenAccountReport()" data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                    <i class="fa fa-eye text-gray-600 fs-2x"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder d-none">
                <li class="nav-item mt-2">
                    <a class="nav-link text-active-primary ms-0 me-10 py-5 active" href="#">Overview</a>
                </li> 
            </ul>
        </div>
        
        <!--AccountReports-->
        <div class="modal fade" id="kt_modal_account_report_selection" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <AccountReports ref="AccountReports" :account-name="formAccount.name" :account-id="formAccount.id" :account-type="formAccount.account_type" />
        </div>
        <!--Stock AccountReports-->
        <div class="modal fade" id="kt_modal_stock_account_report_selection" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <StockAccountReports ref="StockAccountReports" :account-name="formAccount.name" :account-id="formAccount.id" />
        </div>
    </div>
</template>
<script>
 import axios from "axios"; 
import StockAccountReports from '../directives/StockAccountReports.vue';
 export default {
    name: "StatsBar",
    components:{
        StockAccountReports
    },
    props: {
        formAccount: Object
    },
    data() {
        return {
            localCurrency: this.$getUser().currency_code,
            summary: {
                total_debit: 0,
                total_balance: 0,
                total_credit: 0,
                total_local_debit: 0,
                total_local_balance: 0,
                total_local_credit: 0,
            }
        };
    },
    methods: {
        getData() {
            if(this.formAccount.account_type !== null && this.formAccount.account_type !== '' && this.formAccount.account_type !== undefined && this.formAccount.account_type !== 7) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    account_id: this.formAccount.id
                };  
                axios.post('/api/CompanyAccounts/GetAccountSummary', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                        this.summary = response.data;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        },
        onOpenAccountReport(){
            if(this.formAccount.account_type == null || this.formAccount.account_type == '' || this.formAccount.account_type == undefined) return;
            this.$refs.AccountReports.getData();
            this.$refs.AccountReports.setModalCriteriasToDefault();
        },
        onOpenStockAccountReport(){
            if(this.formAccount.account_type == null || this.formAccount.account_type == '' || this.formAccount.account_type == undefined) return;
            this.$refs.StockAccountReports.getData();
            this.$refs.StockAccountReports.setModalCriteriasToDefault();
        },
        onStockAccountImageUploads(){
            this.$parent.$refs.AccountImageUpdate.getFolderId();
        }
    },
    created() {
        setTimeout(() => {
            this.getData();
        }, 500);
    }
};  
</script>