<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Company Settings') }} </h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'Settings' }">{{ $t('Settings') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600">{{ $t('Create Company Settings') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li>   
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/Settings">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to Settings') }} </span>
                    </button>
                </router-link> 
            </div>
        </div>        
        <div class="content flex-row-fluid mt-4" id="kt_content">
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch">
                <div class="card-header">
                     <div class="card-title">
                            <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                               <li class="nav-item">
                                    <a class="nav-link " @click="this.activeView = 'General'" :class="{ 'text-active-primary active': activeView == 'General' }"  data-bs-toggle="tab" href="#company_general" target="_self">
                                        {{ $t('General') }}
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" @click="this.activeView = 'UploadLogo'; getLogoFolderData('companies_logo')" :class="{ 'text-active-primary active': activeView == 'UploadLogo' }" data-bs-toggle="tab" href="#upload_logo" target="_self">
                                        {{ $t('Upload Company Logo') }}
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" @click="this.activeView = 'UploadDocumentLogo'; getLogoFolderData('company_documents_logo')" :class="{ 'text-active-primary active': activeView == 'UploadDocumentLogo' }" data-bs-toggle="tab" href="#upload_document_logo" target="_self">
                                        {{ $t('Upload Document Logo') }}
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" @click="this.activeView = 'ApiQuerySettings'; getApiKeys()" :class="{ 'text-active-primary active': activeView == 'ApiQuerySettings' }" data-bs-toggle="tab" href="#api_query_settings" target="_self">
                                        {{ $t('API Query Settings') }}
                                    </a>
                                </li>
                            </ul>             
                     </div>           
                </div>
                <div v-if="this.activeView == 'General'" class="tab-pane fade show active" id="company_general" role="tabpanel">  
                    <div class="row p-10">
                        <!-- Form alanları gelecek -->                     
                        <div class="col-lg-6  d-flex flex-column flex-row-auto">
                          <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                              <div class="card-body pb-0">
                                  <form id="kt_ecommerce_settings_general_form"
                                      class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                      <div class="fv-row mb-7 row">                                          
                                          <div class="col-lg-12">
                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                {{ $t('Company/Account Name') }}
                                            </label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="" name="name" v-model="form.company_name"> 
                                                <button type="button" v-if="userData.is_company_inquiry_active == 1"
                                                        class="input-group-text m-0 text-hover-success cursor-pointer" 
                                                        id="basic-addon2" 
                                                        @click="onSearchCompanyByName()" 
                                                        data-bs-toggle="modal" 
                                                        data-bs-target="#kt_modal_search_company" 
                                                        data-kt-menu-trigger="click" 
                                                        data-kt-menu-placement="bottom-end"
                                                        :disabled="form.company_name == null || form.company_name == ''">
                                                    <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Name') }}
                                                </button>
                                            </div>
                                          </div>
                                          <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3 required">
                                                  {{ $t('Member Type') }}
                                              </label>
                                              <Select2 v-model="form.company_member_type" :options="companyMemberTypeList" :settings="{width: '100%'}" @select="onChangeMemberType()"/>
                                          </div>
                                          <div class="col-lg-12" v-if="form.company_member_type == 1">
                                            <label class="fs-6 fw-bold form-label mt-3" :class="{'required': this.form.company_member_type == 1}">
                                                {{ $t('Company Number') }}
                                            </label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="" name="name" v-model="form.company_vkn"> 
                                                <button v-if="userData.is_company_inquiry_active == 1" type="button" class="input-group-text m-0 cursor-pointer text-hover-primary" id="basic-addon2" @click="onSearchCompanyByNo()"
                                                        :disabled="form.company_vkn == null || form.company_vkn == ''">
                                                    <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by No') }}
                                                </button>
                                            </div>
                                          </div>
                                          <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3 required">
                                                {{ $t('Country') }}
                                              </label>
                                              <Select2 v-model="form.company_country_code" :options="countryList" @select="onChangeCountry($event)" disabled :settings="{ width: '100%' }"/>
                                          </div>
                                          <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3">
                                                  {{ $t('Region') }}
                                              </label>
                                              <input class="form-control" v-model="form.company_region" placeholder="" name="Region" v-if="form.company_country_code !='GB'">
                                              <Select2 v-model="form.company_region_select" :options="regionList" :settings="{width: '100%'}" v-if="form.company_country_code =='GB'" />
                                          </div>
                                      </div>
                                  </form>
                              </div>
                          </div>
                    </div>
                      <div class="col-lg-6 d-flex flex-column flex-row-auto">
                          <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                              <div class="card-body pb-0">
                                  <form id="kt_ecommerce_settings_general_form"
                                      class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                      <div class="fv-row mb-7 row">
                                          <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3" :class="{'required': this.form.company_member_type == 1}">
                                                  {{ $t('Company Type') }}
                                              </label>
                                              <Select2 v-model="form.company_type_id" :options="companyTypes" :settings="{width: '100%'}" />
                                          </div>
                                          <div class="col-lg-6">
                                              <label class="fs-6 fw-bold form-label mt-3">
                                                  {{ $t('Sector') }}
                                              </label>
                                              <Select2 v-model="form.sector_id" :options="companySectors" :settings="{width: '100%'}" />
                                          </div>
                                          <div class="col-lg-6">
                                              <label class="fs-6 fw-bold form-label mt-3">
                                                  {{ $t('Business Type') }}
                                              </label>
                                              <Select2 v-model="form.business_type_id" :options="businessTypes" :settings="{width: '100%'}" />
                                          </div>
                                          <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3">
                                                  {{ $t('Primary Person') }}
                                              </label>
                                              <input type="text" class="form-control" placeholder="" name="name" v-model="form.primary_person"> 
                                          </div>
                                          <div class="col-lg-6">
                                              <label class="fs-6 fw-bold form-label mt-3">
                                                  {{ $t('Mobile Phone') }}
                                              </label>
                                              <div class="input-group">
                                                  <span class="input-group-text fs-7 text-primary" id="basic-addon1" v-if="form.company_country_code !== '-1'">
                                                      {{form.company_country_code}} {{form.phone_country_code}}
                                                  </span>
                                                  <input type="text" class="form-control" name="name" v-model="form.company_gsm"> 
                                              </div>
                                          </div>
                                          <div class="col-lg-6">
                                              <label class="fs-6 fw-bold form-label mt-3">
                                                  {{ $t('Land Phone') }}
                                              </label>
                                              <div class="input-group">
                                                <span class="input-group-text fs-7 text-primary" id="basic-addon1" v-if="form.company_country_code !== '-1'">
                                                    {{form.company_country_code}} {{form.phone_country_code}}
                                                </span>
                                                <input type="text" class="form-control" name="landPhone" v-model="form.company_landphone">
                                              </div>
                                          </div>
                                          <div class="col-lg-12">
                                              <label class="fs-6 fw-bold form-label mt-3">
                                                  {{ $t('Email') }}
                                              </label>
                                              <input type="text" class="form-control" name="name" v-model="form.company_email" :class="{'border border-danger': isValidEmail == false}"> 
                                              <small class="text-danger" v-if="!isValidEmail && form.company_email !== null && form.company_email !== '' ">{{ $t('Email is not valid') }}</small>
                                          </div>                                    
                                          <div class="col-lg-6">
                                                <label class="fs-6 fw-bold form-label mt-3">
                                                  {{ $t('Rate List') }}
                                              </label>
                                              <Select2 v-model="form.rate_type_selection"  :options="rateTypelist" @select="onChangeRateType($event)" :settings="{ width: '100%' }"/>                                     
                                          </div>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12 d-flex flex-column flex-row-auto">
                        <div class="d-flex flex-column-fluid card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                            <div class="card-body pb-0">
                                <div class="fv-row mb-7 row">
                                    <div class="col-lg-4">
                                        <label class="fs-6 fw-bold form-label">{{ $t('Is VAT Account Active') }}?</label> 
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="false"
                                                v-model="form.is_vat_active">
                                            <label class="form-check-label" for="inlineRadio1">No</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="true"
                                            v-model="form.is_vat_active">
                                            <label class="form-check-label" for="inlineRadio2">Yes</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="fs-6 fw-bold form-label">
                                            {{ $t('VAT Number') }}
                                        </label>
                                        <input type="text" class="form-control" placeholder="" name="name" v-model="form.company_vat_number"> 
                                    </div>
                                    <div class="separator separator-dashed my-5"></div>
                                    <div class="col-lg-4">
                                        <div class="form-check mt-3 mb-2">
                                            <input class="form-check-input" type="checkbox" value="" id="onlineAccounting" v-model="form.online_accounting" >
                                            <label class="fs-6 fw-bold form-label" for="onlineAccounting">
                                            {{ $t('Online Accounting Active/Passive?') }}
                                            </label>
                                        </div> 
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-check mt-3 mb-2">
                                            <input class="form-check-input" type="checkbox" value="" id="isManualIntegrationActive" v-model="form.is_manual_integration_active" >
                                            <label class="fs-6 fw-bold form-label" for="isManualIntegrationActive">
                                            {{ $t('Manual Accounting Active/Passive?') }}
                                            </label>
                                        </div> 
                                    </div>
                                    <!-- <div class="col-lg-4">
                                        <div class="form-check mt-3 mb-2">
                                            <input class="form-check-input" type="checkbox" value="" id="isInsuranceModuleActive" v-model="form.is_insurance_module_active">
                                            <label class="fs-6 fw-bold form-label" for="isInsuranceModuleActive">
                                            {{ $t('Insurance Module Active/Passive?') }}
                                            </label>
                                        </div> 
                                    </div> -->
                                </div>
                              </div>
                          </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                            <div class="card-body pb-0">
                                <form id="kt_ecommerce_settings_general_form" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                                    <div class="fv-row mb-7 row">
                                        <GetAddress @address-selected="onSelectAddress" v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 2"/>
                                        <div class="col-lg-6">
                                            <div class="fv-row">
                                                <label class="fs-6 fw-bold form-label mt-3">
                                                {{ $t('Post Code') }}
                                                </label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" name="post_code" v-model="form.company_postcode">
                                                    <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 " type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                            id="basic-addon2" 
                                                            @click="this.onFindAddressByPostCode()" 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#kt_modal_search_address" 
                                                            data-kt-menu-trigger="click" 
                                                            data-kt-menu-placement="bottom-end"
                                                            :disabled="this.form.company_postcode == null || this.form.company_postcode == ''">
                                                        <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Post Code') }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="fv-row">
                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'address_code'">{{ $t('Address Code') }}
                                                </label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" name="address_code" v-model="form.uprn" v-custom>
                                                    <button v-if="userData.is_address_inquiry_active == 1 && userData.address_inquiry_sub_type == 1 " type="button" class="input-group-text m-0 cursor-pointer text-hover-info" 
                                                            id="basic-addon2" 
                                                            @click="this.onFindAddressByAddressCode()" 
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#kt_modal_search_address_by_code" 
                                                            data-kt-menu-trigger="click" 
                                                            data-kt-menu-placement="bottom-end"
                                                            :disabled="this.form.uprn == null || this.form.uprn == ''">
                                                        <i class="fas fa-search fs-4 me-3"></i> {{ $t('Search by Address Code') }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="fv-row">
                                                <label class="fs-6 fw-bold form-label mt-3">
                                                {{ $t('Address') }}
                                                </label>
                                                <textarea class="form-control" name="address" v-model="form.company_address"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="fv-row">
                                                <label class="fs-6 fw-bold form-label mt-3">
                                                {{ $t('City') }}
                                                </label>
                                                <input type="text" class="form-control" name="city" v-model="form.company_city">
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="fv-row">
                                                <label class="fs-6 fw-bold form-label mt-3">{{ $t('District') }}                                                    
                                                </label>
                                                <input type="text" class="form-control" name="city" v-model="form.company_district">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="d-grid gap-2">
                              <button type="button" class="btn btn-success" @click="this.saveData()" data-kt-indicator="on" :disabled="isValidEmail == false">
                                  <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                  <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                  </svg>
                                  </span> 
                                  <strong v-if="!this.isProgressing"> {{ $t('Save Company Settings') }} </strong> 
                                      <span class="indicator-progress" v-if="this.isProgressing">
                                          {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                      </span> 
                              </button>
                          </div>                 
                      </div>
                    </div>
                </div>
                <div v-if="this.activeView == 'UploadLogo'" class="tab-pane fade show active" id="upload_logo" role="tabpanel">  
                    <div class="row p-10" v-if="imageList.length == 0">
                        <div class="card-toolbar">
                            <div class="d-flex justify-content-end" data-kt-filemanager-table-toolbar="base">
                                <button type="button" class="btn btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_upload_form"  @click="this.openUploadMedia()" :disabled ="this.isViewUploadButton == false">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"></path>
                                            <path
                                                d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z"
                                                fill="currentColor"></path>
                                            <path opacity="0.3" d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z"
                                                fill="currentColor"></path>
                                        </svg>
                                    </span>
                                    <span>{{ $t('Upload Company Logo') }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-3">
                        <div class="scroll-y me-n5 pe-5 h-500px h-xl-auto">
                            <div class="table-responsive mh-550px">
                                <table id="kt_profile_overview_table" class="table table-row-bordered table-row-dashed table-hover gy-4 align-middle fw-bold dataTable no-footer">
                                    <thead class="fs-6 text-gray-400 text-uppercase">
                                        <tr>
                                            <th class="text-gray-400">#</th>
                                            <!-- <th class="text-gray-400 min-w-250px">{{ $t('Document Name') }}</th> -->
                                            <th class="text-gray-400 min-w-200px">{{ $t('Size') }} </th>
                                            <th class="text-gray-400 min-w-200px">{{ $t('Extension') }} </th>
                                            <th class="text-gray-400 min-w-90px">{{ $t('Created At') }} </th>
                                            <th class="text-gray-400 min-w-90px" v-if="imageList.length > 0"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="fs-6" v-if="imageList">
                                        <tr class="odd" v-for="imageItem in imageList" v-bind:key="imageItem" >
                                            <td class="fs-7">
                                                <img class="img-fluid cursor-pointer" width="120" height="120"
                                                :src= "imageItem.url" v-if="imageItem.extension == 'jpg'"/>

                                                <img class="img-fluid cursor-pointer" width="120" height="120"
                                                :src= "imageItem.url" v-if="imageItem.extension ==  'png' "/>

                                                <img class="img-fluid cursor-pointer" width="120" height="120"
                                                :src= "imageItem.url" v-if="imageItem.extension == 'jpeg'"/>

                                                <img class="img-fluid cursor-pointer" width="120" height="120"
                                                :src= "imageItem.url" v-if="imageItem.extension == 'gif'"/>
                                            </td>
                                            <!-- <td>
                                                <div class="d-flex align-items-center"> 
                                                    <div class="d-flex flex-column justify-content-center">
                                                        <span class="fs-6 text-gray-800">{{ imageItem.name }}</span>
                                                    </div>
                                                </div>
                                            </td> -->
                                            <td class="fs-7">{{calculateSize(imageItem.size) }}</td>
                                            <td class="fs-7">{{ imageItem.extension }}</td>
                                            <td class="fs-7">{{ previewDate(imageItem.created_at) }}</td>
                                            <td v-if="imageList.length > 0">
                                                <button type="button" class="btn btn-sm btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_upload_form"  @click="this.openUploadMedia()" :disabled ="this.isViewUploadButton == false">
                                                    {{ $t('Change Logo') }}
                                                </button>
                                            </td>
                                            <td v-if="imageList.length > 0">
                                                <button type="button" class="btn btn-sm btn-light-warning" @click="onCropLogo()" :disabled ="this.isViewUploadButton == false">
                                                    {{ $t('Crop Logo') }}
                                                </button>
                                            </td>
                                            <td v-if="imageList.length > 0">
                                                <button type="button" class="btn btn-sm btn-light-danger" @click="this.deleteLogo(imageItem.id)">
                                                    {{ $t('Delete Logo') }}
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <br> 
                            </div> 
                            <div class="card card-bordered mt-4" v-if="this.showCropSection">
                                <div class="card-header">
                                    <h3 class="card-title">{{ $t('Crop Image') }}</h3>
                                    <div class="card-toolbar">
                                        <button type="button" class="btn btn-sm btn-light mr-4" @click="this.showCropSection = false;">
                                            {{ $t('Discard Changes') }}
                                        </button>
                                        <button type="button" class="btn btn-sm btn-success" @click="this.onImageCropped()">
                                            {{ $t('Save Changes') }}
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <cropper
                                        :src="this.uploadedImage"
                                        ref="cropper"
                                    />
                                </div> 
                            </div>
                        </div> 
                    </div>
                </div>

                <div v-if="this.activeView == 'UploadDocumentLogo'" class="tab-pane fade show active" id="upload_document_logo" role="tabpanel">  
                    <div class="row p-10" v-if="documentImageList.length == 0">
                        <div class="card-toolbar">
                            <div class="d-flex justify-content-end" data-kt-filemanager-table-toolbar="base">
                                <button type="button" class="btn btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_document_upload_form"  @click="this.openUploadDocumentMedia()" :disabled ="this.isViewUploadButton == false">
                                    <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"></path>
                                            <path
                                                d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z"
                                                fill="currentColor"></path>
                                            <path opacity="0.3" d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z"
                                                fill="currentColor"></path>
                                        </svg>
                                    </span>
                                    <span>{{ $t('Upload Document Logo') }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-3">
                        <div class="scroll-y me-n5 pe-5 h-500px h-xl-auto">
                            <div class="table-responsive mh-550px">
                                <table id="kt_profile_overview_table" class="table table-row-bordered table-row-dashed table-hover gy-4 align-middle fw-bold dataTable no-footer">
                                    <thead class="fs-6 text-gray-400 text-uppercase">
                                        <tr>
                                            <th class="text-gray-400">#</th>
                                            <!-- <th class="text-gray-400 min-w-250px">{{ $t('Document Name') }}</th> -->
                                            <th class="text-gray-400 min-w-200px">{{ $t('Size') }} </th>
                                            <th class="text-gray-400 min-w-200px">{{ $t('Extension') }} </th>
                                            <th class="text-gray-400 min-w-90px">{{ $t('Created At') }} </th>
                                            <th class="text-gray-400 min-w-90px" v-if="documentImageList.length > 0"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="fs-6" v-if="documentImageList">
                                        <tr class="odd" v-for="imageItem in documentImageList" v-bind:key="imageItem" >
                                            <td class="fs-7">
                                                <img class="img-fluid cursor-pointer" width="120" height="120"
                                                :src= "imageItem.url" v-if="imageItem.extension == 'jpg'"/>

                                                <img class="img-fluid cursor-pointer" width="120" height="120"
                                                :src= "imageItem.url" v-if="imageItem.extension ==  'png' "/>

                                                <img class="img-fluid cursor-pointer" width="120" height="120"
                                                :src= "imageItem.url" v-if="imageItem.extension == 'jpeg'"/>

                                                <img class="img-fluid cursor-pointer" width="120" height="120"
                                                :src= "imageItem.url" v-if="imageItem.extension == 'gif'"/>
                                            </td>
                                            <!-- <td>
                                                <div class="d-flex align-items-center"> 
                                                    <div class="d-flex flex-column justify-content-center">
                                                        <span class="fs-6 text-gray-800">{{ imageItem.name }}</span>
                                                    </div>
                                                </div>
                                            </td> -->
                                            <td class="fs-7">{{calculateSize(imageItem.size) }}</td>
                                            <td class="fs-7">{{ imageItem.extension }}</td>
                                            <td class="fs-7">{{ previewDate(imageItem.created_at) }}</td>
                                            <td v-if="documentImageList.length > 0">
                                                <button type="button" class="btn btn-sm btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_document_upload_form"  @click="this.openUploadDocumentMedia()" :disabled ="this.isViewUploadButton == false">
                                                    {{ $t('Change Logo') }}
                                                </button>
                                            </td>
                                            <td v-if="documentImageList.length > 0">
                                                <button type="button" class="btn btn-sm btn-light-warning" @click="onCropDocumentLogo()" :disabled ="this.isViewUploadButton == false">
                                                    {{ $t('Crop Logo') }}
                                                </button>
                                            </td>
                                            <td v-if="documentImageList.length > 0">
                                                <button type="button" class="btn btn-sm btn-light-danger" @click="this.deleteDocumentLogo(imageItem.id)">
                                                    {{ $t('Delete Logo') }}
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <br> 
                            </div> 
                            <div class="card card-bordered mt-4" v-if="this.showDocumentCropSection">
                                <div class="card-header">
                                    <h3 class="card-title">{{ $t('Crop Image') }}</h3>
                                    <div class="card-toolbar">
                                        <button type="button" class="btn btn-sm btn-light mr-4" @click="this.showDocumentCropSection = false;">
                                            {{ $t('Discard Changes') }}
                                        </button>
                                        <button type="button" class="btn btn-sm btn-success" @click="this.onDocumentImageCropped()">
                                            {{ $t('Save Changes') }}
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <cropper
                                        :src="this.uploadedImage"
                                        ref="cropper"
                                        class="cropper"
                                    />
                                </div> 
                            </div>
                        </div> 
                    </div>
                </div>
                <div v-if="this.activeView == 'ApiQuerySettings'" class="tab-pane fade show active" id="api_query_settings" role="tabpanel">  
                    <div class="card">
                        <div class="card-header border-0">
                            <div class="card-title">
                                <div class="d-flex align-items-center position-relative my-1">
                                    <button href="#"  @click="openCreateApiKeyModal('addItem')" data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_add_api_key" type="button" class="btn btn-light-primary border border-primary me-3">
                                        <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                        </svg>
                                        </span>
                                        Add API Key
                                    </button>
                                </div>
                            </div>
                            <div class="card-toolbar">
                                <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base"> 
                                    <button type="button" class="btn btn-light-primary border border-primary" @click="getApiKeys()" data-kt-indicator="on">
                                        <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                        <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                        <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                        </svg>
                                        </span>
                                        <strong v-if="!this.isApiKeysListing"> List API Keys </strong> 
                                        <span class="indicator-progress" v-if="this.isApiKeysListing">
                                            Getting Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span> 
                                    </button>
                                </div>
                                <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                                    <div class="fw-bolder me-5">
                                    <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                                    <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div class="table-responsive min-h-300px">
                                    <DxDataGrid :show-borders="true" key-expr="id" :data-source="apiKeyFormList" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" :row-alternatin-enabled="true"
                                        data-row-template="dataRowTemplate" :hover-state-enabled="true" :show-row-lines="true" @exporting="this.$onExporting($event , 'API-definition-list')"> 
                                        <DxFilterRow :visible="true"/>
                                        <DxHeaderFilter :visible="true"/>
                                        <DxGroupPanel :visible="true"/>      
                                        <DxExport :enabled="true" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                        <DxPaging :page-size="20"/>
                                        <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                        <DxSearchPanel :visible="true"/>
                                        <DxScrolling
                                            mode="virtual"
                                            row-rendering-mode="virtual"                                            
                                        />
                                        <DxColumn data-field="type_name" caption="API Type"/> 
                                        <DxColumn data-field="api_url" caption="API Url"/>   
                                        <DxColumn data-field="api_key" caption="API Key" /> 
                                        <DxColumn data-field="client_id" caption="Client ID" /> 
                                        <DxColumn data-field="primary_key" caption="Primary Key" />
                                        <DxColumn data-field="is_active" caption="Is Active"/>  
                                        <DxColumn data-field="is_default" caption="Is Default"/>  
                                        <DxColumn data-field="description" caption="Description"/>  

                                        <template #dataRowTemplate="{ data: data }">
                                            <td class="text-gray-800">
                                                <a href="#" class="text-gray-800 fw-bolder text-hover-primary mb-1" data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                                    {{ data.data.type_name }}
                                                </a>
                                            </td> 
                                            <td class="text-gray-800">
                                                <a href="#" class="text-gray-800 text-hover-primary mb-1" data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                                    {{data.data.api_url}}
                                                </a>
                                            </td> 
                                            <td class="text-gray-800">
                                                <a href="#" class="text-gray-800 text-hover-primary mb-1" data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                                {{data.data.api_key}}
                                                </a>
                                            </td>  
                                            <td class="text-gray-800">
                                                <a href="#" class="text-gray-800 text-hover-primary mb-1" data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                                {{data.data.client_id}}
                                                </a>
                                            </td>  
                                            <td class="text-gray-800">
                                                <a href="#" class="text-gray-800 text-hover-primary mb-1" data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                                    {{data.data.primary_key}}
                                                </a>
                                            </td>  
                                            <td class="text-gray-800">
                                                <a href="#" class="text-gray-800 text-hover-primary mb-1" data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                            <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                                <span v-if="data.data.is_active == 1">
                                                    <i class="las la-check fs-2x text-success"></i>
                                                </span>  
                                                <span v-if="data.data.is_active == 0">
                                                    <i class="las la-times fs-2x text-danger"></i>
                                                </span> 
                                            </a>
                                                </a>
                                            </td>
                                            <td class="text-gray-800">
                                                <a href="#" class="text-gray-800 text-hover-primary mb-1" data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                            <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateUser(data.data.id)">
                                                <span v-if="data.data.is_default == 1">
                                                    <i class="las la-check fs-2x text-success"></i>
                                                </span>  
                                                <span v-if="data.data.is_default == 0">
                                                    <i class="las la-times fs-2x text-danger"></i>
                                                </span> 
                                            </a>
                                                </a>
                                            </td>
                                            <td class="text-gray-800">
                                                <a href="#" class="text-gray-800 text-hover-primary mb-1" data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_add_api_key" @click="openEditApiKey(data.data.id, 'editItem')">
                                                {{ data.data.description }}
                                                </a>
                                            </td>
                                        </template>
                                    </DxDataGrid>     
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
        </div> 

        <!-- COMPANY UPLOAD LOGO MODAL -->
        <div class="modal fade" action="none" id="kt_modal_upload_form"  data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <form class="form" action="#" id="kt_modal_upload_form">
                        <div class="modal-header p-4 bg-primary">
                            <h2 class="fw-bold ms-5 text-white">{{ $t('Upload File') }}</h2> 
                            <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" ref="closeUploadModal">
                                <span class="svg-icon svg-icon-1 text-white text-hover-dark"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                                </span>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div class="form-group"> 
                                <div id="kt_modal_upload_dropzone">
                                    <div class="col-lg-12">  
                                     <DxFileUploader
                                        :multiple="false"
                                        upload-mode="useButtons" 
                                        :visible="true" 
                                        :upload-headers="getUploadHeaders()"
                                        upload-url="/api/MediaLibrary/Upload"
                                        :on-uploaded="onLogoUploaded"
                                        :ref="'fileUploader'"
                                        accept="image/jpeg,image/png"/>
                                    </div>
                                </div>
                                <span class="form-text fs-6 text-muted ms-3">{{ $t('Max file size is 1MB per file') }}.</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- COMPANY DOCUMENT UPLOAD LOGO MODAL -->
        <div class="modal fade" action="none" id="kt_modal_document_upload_form"  data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <form class="form" action="#" id="kt_modal_document_upload_form">
                        <div class="modal-header p-4 bg-primary">
                            <h2 class="fw-bold ms-5 text-white">{{ $t('Upload File') }}</h2> 
                            <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" ref="closeDocumentUploadModal">
                                <span class="svg-icon svg-icon-1 text-white text-hover-dark"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                                </span>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div class="form-group"> 
                                <div id="kt_modal_upload_dropzone">
                                    <div class="col-lg-12">  
                                     <DxFileUploader
                                        :multiple="false"
                                        upload-mode="useButtons" 
                                        :visible="true" 
                                        :upload-headers="getUploadHeadersForDocuments()"
                                        upload-url="/api/MediaLibrary/Upload"
                                        :on-uploaded="onDocumentLogoUploaded"
                                        :ref="'documentFileUploader'"
                                        accept="image/jpeg,image/png"/>
                                    </div>
                                </div>
                                <span class="form-text fs-6 text-muted ms-3">{{ $t('Max file size is 1MB per file') }}.</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- SEARCH ADDRESS BY POSTCODE RESULT -->
        <div class="modal fade" id="kt_modal_search_address" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <FindAddressByPostCode ref="FindAddressByPostCode" :post-code="this.form.company_postcode"></FindAddressByPostCode>
        </div>
        <!-- SEARCH ADDRESS BY ADDRESS CODE RESULT -->
        <div class="modal fade" id="kt_modal_search_address_by_code" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <FindAddressByAddressCode ref="FindAddressByAddressCode" :address-code="this.form.uprn"></FindAddressByAddressCode>
        </div>
        
        <!-- SEARCH COMPANY BY NAME RESULT -->
        <div class="modal fade" id="kt_modal_search_company" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <SearchCompanyByName ref="SearchCompanyByName" :account-name="this.form.company_name"></SearchCompanyByName>
        </div>

        
        <div class="modal fade" id="kt_modal_add_api_key" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content" style="background-color: #f3f6f9; border: 1px solid #717273;">
                    <div class="modal-header" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-10">
                            <span v-if="this.apiKeyModalType == 'editItem'">Edit API Key </span>
                            <span v-if="this.apiKeyModalType == 'addItem'">Add API Key </span>                    
                        </h2>  
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeApiKeyModal">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body pt-1" :class="{'overlay overlay-block': this.isParameterSaving == true}">
                        <div class="row">
                            <div class="tab-content" id="myTabContent">
                                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel">
                                    <div class="p-10"> 
                                        <div class="row">
                                            <div class="col-lg-12 d-flex flex-column flex-row-auto">
                                                <form id="kt_ecommerce_settings_general_form"
                                                    class="form fv-plugins-bootstrap5 fv-plugins-framework">                                
                                                    <div class="fv-row mb-6 row">
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                API Type
                                                            </label>
                                                            <Select2 v-model="apiKeyForm.type" :options="apiKeyTypeList" :settings="{width: '100%'}" />
                                                        </div>
                                                        <div class="col-lg-12" v-if="apiKeyForm.type !== 1">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                API Sub Type
                                                            </label>
                                                            <Select2 v-model="apiKeyForm.sub_type" :options="apiSubTypeList" :settings="{width: '100%'}" />
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="fv-row fv-plugins-icon-container">
                                                                <label class="fs-6 fw-bold form-label mt-3 required">
                                                                    <span>API Url</span>
                                                                </label>
                                                                <input type="text" class="form-control" name="apiUrl" v-model="apiKeyForm.api_url">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3 required">
                                                                <span>API Key</span>
                                                            </label>
                                                            <input type="text" class="form-control" name="apiKey" v-model="apiKeyForm.api_key">
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3">
                                                                    <span>Client ID</span>
                                                                </label>
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control" name="clientID" v-model="apiKeyForm.client_id">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="fv-row">
                                                                <label class="fs-6 fw-bold form-label mt-3">
                                                                    <span>Primary Key</span>
                                                                </label>
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control" name="primaryKey" v-model="apiKeyForm.primary_key">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-5" for="isActive">
                                                                Is Active
                                                            </label>
                                                            <div class="form-check mt-3 mb-2">
                                                                <input class="form-check-input" type="checkbox" value="" id="isActive" v-model="apiKeyForm.isActive">
                                                            </div> 
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-5" for="isDefault">
                                                                Is Default
                                                            </label>
                                                            <div class="form-check mt-3 mb-2">
                                                                <input class="form-check-input" type="checkbox" value="" id="isDefault" v-model="apiKeyForm.isDefault">
                                                            </div> 
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                    <span>Description</span>
                                                                </label>
                                                            <div class="col-lg-12">
                                                                <textarea type="text" class="form-control" rows="3" name="description" v-model="apiKeyForm.description" :maxlength="255"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="col-lg-12 mt-0">
                                                <div class="" :class="{'d-grid gap-2': this.apiKeyModalType == 'addItem'}"> 
                                                    <button v-if="this.apiKeyModalType == 'addItem'" type="button" class="btn btn-lg btn-success " @click="this.saveApiKey()" data-kt-indicator="on">
                                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                        </svg>
                                                        </span>
                                                        <strong v-if="!this.isProgressing"> Save API Key </strong> 
                                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                                Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span> 
                                                    </button>
                                                    <button v-if="this.apiKeyModalType == 'editItem'" type="button" class="btn btn-lg btn-primary float-end" @click="this.updateApiKey()" data-kt-indicator="on">
                                                        <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                                        </svg>
                                                        </span>
                                                        <strong v-if="!this.isProgressing"> Update API Key </strong> 
                                                            <span class="indicator-progress" v-if="this.isProgressing">
                                                                Saving Data... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span> 
                                                    </button>
                                                    <button type="button" class="btn btn-danger float-start btn-lg" v-if="this.apiKeyModalType == 'editItem'" 
                                                        @click="this.deleteApiKey(this.apiKeyForm.id)">
                                                        <strong>Delete</strong> 
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isParameterSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment'; 
import { DxFileUploader} from 'devextreme-vue/file-uploader';
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false});
import Swal from 'sweetalert2';
import SearchCompanyByName from '../../Accounts/Modals/SearchCompanyByName.vue';
import FindAddressByPostCode from '../../Accounts/Modals/FindAddressByPostCode.vue';
import FindAddressByAddressCode from '../../Accounts/Modals/FindAddressByAddressCode.vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling } from 'devextreme-vue/data-grid';

export default {
  name: "CompanySettings",
  components: {
    DxFileUploader, SearchCompanyByName, FindAddressByPostCode, FindAddressByAddressCode, Cropper, DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, DxScrolling
  },
  data() {
        return {
            showCropSection: false,
            showDocumentCropSection: false,
            userData: this.$getUser(),
            companyTypes: [],
            businessTypes: [],
            companySectors: [],
            countryList: [],
            regionList: [],
            rateTypelist: [], 
            isProgressing: false,
            menuSelection: '/CompanySettingsHelppage',
            iconView : false,
            addressList: [{id: '-1', text: 'Select an option'}],
            activeView: "General", 
            form: { 
                id: null,
                company_member_type: 1,
                company_type_id: '-1',
                business_type_id: '-1',
                sector_id: '-1',
                address_id: '-1',
                company_country_code: '-1',
                company_region_select: '-1',
                company_name: null,
                primary_person: null,
                company_gsm: null,
                company_landphone: null,
                company_email: null,
                company_address: null,
                company_city: null,
                company_town: null,
                company_state: null,
                company_region: null,
                company_country_name: null,
                company_postcode: null,
                company_vkn: null,
                incorporated_at: new Date(),
                accounting_start_period: new Date(),
                accounting_end_period: new Date(),
                vat_start_period: new Date(),
                vat_end_period: new Date(),
                accounting_fee_monthly: 0.00,
                accounting_fee_yearly: 0.00,
                account_office_reference: null,
                company_vat_number: null,
                online_accounting: false,
                isDisabledOnlineAccounting: false,
                is_vat_active: 'false',
                is_automatic_task: false,
                vat_period: 1,
                companyTaxType: '',
                payroll_period: 1,
                accounting_fee_monthly_date: new Date(),
                accounting_fee_yearly_date: new Date(),
                accountingFeeSelection: 'monthly',
                accounting_fee_period: 1,
                phone_country_code: null,
                company_district: null,
                x_coordinate: 0,
                y_coordinate: 0,
                uprn: null,
                udprn: null,
                rate_type: 0,
                rate_type_selection: 0,
                is_insurance_module_active:false,
                is_manual_integration_active:false,
            },
            logoFolderId: null,
            logoDocumentFolderId: null,
            imageList: [],
            documentImageList: [],
			companyMemberTypeList: [
				{id: 1, text: 'Company'},
				{id: 2, text: 'Person'},
			],
            coordinates: {
				width: 0,
				height: 0,
				left: 0,
				top: 0,
			},
			image: null,
            apiKeyFormList:[],
            apiKeyModalType: 'addParameter',
            apiKeyForm: {
                api_key: null,
                client_id: null,
                primary_key: null,
                api_url: null,
                type: 1,
                sub_type: 1,
                is_active: 0,
                isActive: false,
                is_default: 0,
                isDefault: false,
                description: null,
                id: null
            },
            apiKeyTypeList:[
                { id: 1, text: "Company Inquiry" },
                { id: 2, text: "Address Inquiry" },
                { id: 3, text: "Address Inquiry By Address Code" }
            ],
            apiSubTypeList:[
                { id: 1, text: "OS Places" },
                { id: 2, text: "getAddress" }
            ],
            isParameterSaving: false,
            isApiKeysListing: false,
            changeDocument:null,
            changeImage:null,
        };
    },
    computed: {
        isValidEmail() {
            return (this.form.company_email == null || this.form.company_email == '' || this.form.company_email == undefined) ? null : /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.company_email);
        },
        previewDate(){
            return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY HH:mm')
                }
            }
        },
        calculateSize(){
            return (bytes)=>{
                if (bytes) 
                {
                    const decimals = 2;
                    const k = 1024;
                    const dm = decimals < 0 ? 0 : decimals;
                    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']; 
                    const i = Math.floor(Math.log(bytes) / Math.log(k)); 
                    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +' '+ sizes[i];
                }
            } 
        }
    },
    methods: {
        getData() {
            this.searchbar = '';       
            this.$Progress.start();
            this.$setSessionStorage('refreshList', true);   
            const queryParameter = {
                session_id: this.$getUser().session_id
            };
            axios.post('/api/CompanySettings/Get', queryParameter, {'Content-Type': 'application/json'})
            .then((response) => {   
                    this.data = response.data;
                    this.form = { 
                        id: response.data.id,  
                        address_id: '-1',
                        company_name: response.data.company_name,
                        sector_id: (response.data.sector_id == '' || response.data.sector_id == null) ? '-1' : response.data.sector_id,
                        business_type_id: (response.data.business_type_id == '' || response.data.business_type_id == null) ? '-1' : response.data.business_type_id,
                        company_type_id: (response.data.company_type_id == '' || response.data.company_type_id == null) ? '-1' : response.data.company_type_id,
                        company_member_type: response.data.company_member_type,
                        company_vkn: (response.data.company_vkn !== null && response.data.company_vkn !== '') ? response.data.company_vkn : null,
                        primary_person: response.data.primary_person,
                        company_gsm: response.data.company_gsm,
                        company_landphone: response.data.company_landphone,
                        company_email: response.data.company_email,
                        company_address: response.data.company_address,
                        company_city: response.data.company_city,
                        company_town: response.data.company_town,
                        company_state: response.data.company_state,
                        company_region: response.data.company_region,
                        company_region_select: (response.data.company_region == '' || response.data.company_region == null) ? '-1' : response.data.company_region,
                        company_postcode: response.data.company_postcode,
                        company_country_code: response.data.company_country_code,
                        activation_date: response.data.activation_date,
                        incorporated_at: response.data.incorporated_at,
                        accounting_start_period: response.data.accounting_start_period,
                        accounting_end_period: response.data.accounting_end_period,
                        vat_start_period: response.data.vat_start_period,
                        vat_end_period: response.data.vat_end_period,
                        accounting_fee_monthly: parseFloat(response.data.accounting_fee_monthly.replace(/,/g, '')),
                        accounting_fee_yearly: parseFloat(response.data.accounting_fee_yearly.replace(/,/g, '')),
                        accountingFeeSelection: 'monthly',
                        account_office_reference: response.data.account_office_reference,
                        company_vat_number: response.data.company_vat_number,
                        current_period: response.data.current_period,
                        current_period_id: response.data.current_period_id,
                        online_accounting: response.data.online_accounting == 1 ? true : false,
                        is_manual_integration_active: response.data.is_manual_integration_active == 1 ? true : false,
                        is_insurance_module_active: response.data.is_insurance_module_active == 1 ? true : false,
                        is_vat_active: response.data.is_vat_active == 1 ? 'true' : 'false',
                        is_automatic_task: response.data.is_automatic_task == 1 ? true : false,
                        vat_period: response.data.vat_period,
                        payroll_period: response.data.payroll_period,
                        accounting_fee_monthly_date: response.data.accounting_fee_monthly_date,
                        accounting_fee_yearly_date: response.data.accounting_fee_yearly_date,
                        accounting_fee_period: response.data.accounting_fee_period,
                        phone_country_code: response.data.phone_country_code,
                        company_district: response.data.company_district,
                        uprn: response.data.uprn,
                        rate_type: response.data.rate_type,
                        rate_type_selection: 0
                    };
                    
                    const parameters = {
                        session_id: this.$getUser().session_id,
                        country_code: this.form.company_country_code,
                        get_access_status: 0,
                        member_type: this.form.company_member_type
                    }
                    const parametersTwo = {
                        session_id: this.$getUser().session_id,
                    }; 
                    
                    const requestOne =  axios.post('/api/NewCompany/GetCompanyTypes', parameters, {'Content-Type': 'application/json'}); 
                    const requestTwo =  axios.post('/api/CompanySettings/CompanyBusinessTypes', parameters, {'Content-Type': 'application/json'}); 
                    const requestThree =  axios.post('/api/CompanySettings/CompanySectors', parameters, {'Content-Type': 'application/json'});  
                    const requestFour =  axios.post('/api/NewCompany/GetLists', parameters, {'Content-Type': 'application/json'}); 
                    const requestFive =  axios.post('/api/CompanySettings/GetRegionList', parameters, {'Content-Type': 'application/json'}); 
                    const requestSix =  axios.post('/api/Rate/RateTypes', parametersTwo, {'Content-Type': 'application/json'});

                    axios.all([requestOne, requestTwo, requestThree, requestFour, requestFive, requestSix]).then(axios.spread((...responses) => {
                    this.companyTypes = responses[0].data;
                    this.businessTypes = responses[1].data;
                    this.companySectors = responses[2].data;
                    this.countryList = responses[3].data; 
                    this.regionList = responses[4].data;
                    this.rateTypelist = responses[5].data;
                    this.countryList.unshift({ id: '-1', text: 'Select an option'});
                    this.regionList.unshift({ id: '-1', text: 'Select an option'});
                    this.companyTypes.unshift({ id: '-1', text: 'Select an option'});
                    this.companySectors.unshift({ id: '-1', text: 'Select an option'});
                    this.businessTypes.unshift({ id: '-1', text: 'Select an option'});
                    this.rateTypelist.unshift({ id: 0, text: 'Select an option'});
                    this.companyTypes.forEach(type => {
                        if(type.id == this.form.company_type_id) {
                            if (type.tax_type == 1) {this.form.companyTaxType = 'Corporation Tax';}
                            if (type.tax_type == 2) {this.form.companyTaxType = 'Income Tax';}
                            if (type.tax_type == 3) {this.form.companyTaxType = 'None Tax';}
                        }
                    });
                    this.countryList.forEach(element => {
                        if(element.id == this.form.company_country_code) {
                            this.form.phone_country_code = element.phone_country_code;
                        }
                    });
                    this.rateTypelist.forEach(element => {
                        if(element.type == this.form.rate_type) {
                            this.form.rate_type_selection = element.id;
                        }
                    });
                    this.$Progress.finish();
                }))
                .catch(errors => { 
                    console.error(errors); 
                    this.$Progress.finish();
                });
            })
            .catch(function (error) {
                console.log(error);
                this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
            });  
        },
        saveData(){
            this.isProgressing = true;
            const params = this.form;
            const that = this;   

            if(params.company_country_code == 'GB'){
              params.company_region = params.company_region_select;
            } 

            params.session_id = this.$getUser().session_id,
            this.form.online_accounting == true ? params.online_accounting = 1 : params.online_accounting = 0; 
            this.form.is_manual_integration_active == true ? params.is_manual_integration_active = 1 : params.is_manual_integration_active = 0; 
            this.form.is_insurance_module_active == true ? params.is_insurance_module_active = 1 : params.is_insurance_module_active = 0; 
            this.form.is_vat_active == 'true' ? params.is_vat_active = 1 : params.is_vat_active = 0; 
            this.form.is_automatic_task == true ? params.is_automatic_task = 1 : params.is_automatic_task = 0; 
            if (params.company_type_id == '-1') { params.company_type_id = null; }
            if (params.business_type_id == '-1') { params.business_type_id = null; }
            if (params.sector_id == '-1') { params.sector_id = null; }
            if (params.company_vkn == '') { params.company_vkn = null; }
            axios.post('/api/CompanySettings/UpdateCompanySettings', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(!response.data.error && response.data != null && response.data.length == 36){ 
                    params.online_accounting == 1 ? this.form.online_accounting = true : this.form.online_accounting = false; 
                    params.is_insurance_module_active == 1 ? this.form.is_insurance_module_active = true : this.form.is_insurance_module_active = false; 
                    params.is_manual_integration_active == 1 ? this.form.is_manual_integration_active = true : this.form.is_manual_integration_active = false; 
                    params.is_vat_active == 1 ? this.form.is_vat_active = 'true' : this.form.is_vat_active = 'false'; 
                    params.is_automatic_task == 1 ? this.form.is_automatic_task = true : this.form.is_automatic_task = false; 
                    this.$swal("Success", "Data saved succesfully..!", 'success');  
                    this.$setSessionStorage('refreshList', true);   
                } else {              
                    if(this.form.company_country_code == null) {this.form.company_country_code = '-1';}
                    if(this.form.business_type_id == null) {this.form.business_type_id = '-1';}
                    if(this.form.company_type_id == null) {this.form.company_type_id = '-1';}
                    if(this.form.sector_id == null) {this.form.sector_id = '-1';}
                }
            })
            .catch(function (error) {
                console.log(error);
                this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
            }) .finally(function () { 
                setTimeout(() => {
                    that.isProgressing = false;
                }, 500);
            }); 
        },
        async helpPageViewIcon(){
            const queryparameter = {
                session_id : this.$getUser().session_id,
                menu_selection : '/CompanySettingsHelppage'
            };
            axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(!response.data.error){ 
                    this.iconView = response.data;
                }
            })
        },  
        onChangeCountry(event) {
            if(event == null || event == '' || event == undefined) {
                this.countryList.forEach(element => {
                    if(element.id == this.form.company_country_code) {
                        this.form.phone_country_code = element.phone_country_code;
                    }
                });
            } else this.form.phone_country_code = event.phone_country_code;
            this.form.company_region_select = '-1';
            this.form.company_region = null;
            this.companyTypes = [];
            const params = {
                session_id: this.$getUser().session_id,
                country_code: this.form.company_country_code,
                member_type: this.form.company_member_type                
            };
            axios.post('/api/NewCompany/GetCompanyTypes', params, {'Content-Type': 'application/json'}).then((response) => { 
                this.companyTypes = response.data;
                this.companyTypes.unshift({ id: '-1', text: 'Select an option'});            
                this.form.company_type_id = '-1';
            });
        },
        openUploadMedia(){
            try{ 
                this.uploadedImage = null;
                this.$refs.fileUploader.instance.reset();
            }catch(Err){
                console.error(Err);
            }
        },
        openUploadDocumentMedia(){
            try{ 
                this.uploadedImage = null;
                this.$refs.documentFileUploader.instance.reset();
            }catch(Err){
                console.error(Err);
            }
        },
        getLogoFolderData(folderName){
            const parameters = { 
                session_id: this.$getUser().session_id,
                folder_name: folderName
            }; 
            axios.post('/api/MediaLibrary/GetLogoFolder', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(folderName == 'company_documents_logo') {
                    this.logoDocumentFolderId = response.data;
                    this.getCompanyDocumentLogo();
                } else {
                    this.logoFolderId = response.data;
                    this.getCompanyLogo();
                }
            }) 
            .catch(function (error) {
                console.log(error);
                this.Progress.finish();
            });   
        },
        getUploadHeaders(){
            return { 
                sid: this.$getUser().session_id,
                fid: this.logoFolderId,
                url: window.location.origin + '/assets/uploads/companies_logo',
                mid: this.changeImage
            };
        },
        getUploadHeadersForDocuments(){
            return { 
                sid: this.$getUser().session_id,
                fid: this.logoDocumentFolderId,
                url: window.location.origin + '/assets/uploads/company_documents_logo',
                mid: this.changeDocument
            };
        },
        getCompanyLogo(){
            const parameters = { 
                session_id:this.$getUser().session_id,
                folder_id: this.logoFolderId
            }; 
            axios.post('/api/MediaLibrary/GetCompanyLogo', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                    this.imageList = response.data;
                    var userData = ls.get('user_' + sessionStorage.getItem('sid'));
                    if(userData !== null && userData !== '' && userData !== undefined) {
                        userData.company_logo = this.imageList[0].url;
                        ls.set('user_' + sessionStorage.getItem('sid'), userData);
                        this.emitter.emit('CompanyLogo', userData.company_logo);
                        this.uploadedImage = this.imageList[0].url;
                        this.changeImage = this.imageList[0].id;
                    } 
                 }
                 this.$refs.closeUploadModal.click();
            }) 
            .catch(function (error) {
                console.log(error);
            });          
        },
        getCompanyDocumentLogo(){
            const parameters = { 
                session_id:this.$getUser().session_id,
                folder_id: this.logoDocumentFolderId
            }; 
            axios.post('/api/MediaLibrary/GetCompanyLogo', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                    this.documentImageList = response.data;
                    this.uploadedImage = this.documentImageList[0].url;
                    this.changeDocument = this.documentImageList[0].id;
                 }
                 this.$refs.closeDocumentUploadModal.click();
            }) 
            .catch(function (error) {
                console.log(error);
            });          
        },
        deleteLogo(itemID){
            this.$swal.fire({
                title: 'Are you sure you want to delete this Company Logo?',
                confirmButtonColor: '#f1416c',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                icon: 'warning'
            }).then((result) => { 
                if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    id: itemID
                };
                axios.post('/api/MediaLibrary/DeleteLogo', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                            new Swal( { icon: 'error', title: 'Deleted...', text: 'Company Logo deleted..!' });
                            var userData = ls.get('user_' + sessionStorage.getItem('sid'));
                            if(userData !== null && userData !== '' && userData !== undefined) {
                                userData.company_logo = "";
                                ls.set('user_' + sessionStorage.getItem('sid'), userData);
                                this.emitter.emit('CompanyLogo', userData.company_logo);
                            }
                            this.getCompanyLogo();
                            this.showCropSection = false; 
                        }
                })
                .catch(function (error) {
                    console.log(error);
                });
                }
            })    
        },
        deleteDocumentLogo(itemID){
            this.$swal.fire({
                title: 'Are you sure you want to delete this Company Logo?',
                confirmButtonColor: '#f1416c',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                icon: 'warning'
            }).then((result) => { 
                if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    id: itemID
                };
                axios.post('/api/MediaLibrary/DeleteLogo', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){  
                            new Swal( { icon: 'error', title: 'Deleted...', text: 'Company Document Logo deleted..!' }); 
                            this.getCompanyDocumentLogo();
                            this.showDocumentCropSection = false;
                        }
                })
                .catch(function (error) {
                    console.log(error);
                });
                }
            })    
        }, 
        onFindAddressByPostCode(){  
            this.$refs.FindAddressByPostCode.getData();
        },
        onFindAddressByAddressCode(){  
            this.$refs.FindAddressByAddressCode.getData();
        },
        onSelectAddressSearchResult(item){
            this.form.company_address = item.ADDRESS;
            this.form.company_city = item.POST_TOWN;
            this.form.company_district = item.LOCAL_CUSTODIAN_CODE_DESCRIPTION;
            this.form.x_coordinate = item.X_COORDINATE;
            this.form.y_coordinate = item.Y_COORDINATE;
            this.form.uprn = item.UPRN;
            this.form.udprn = item.UDPRN;
            this.form.company_postcode = item.POSTCODE;
        },
        onSelectAddress(item) {
            if(item.id == '-1') return;
            this.form.company_address = item.fullAddress;
            this.form.company_city = item.town_or_city;
            this.form.company_district = item.district;
            this.form.company_postcode = item.postcode;
        },
        onChangeMemberType() {
            if(this.form.company_member_type == 2) {
                this.form.company_vkn = null;
            }
            this.companyTypes = [];
            const params = {
                session_id: this.$getUser().session_id,
                country_code: this.form.company_country_code,
                member_type: this.form.company_member_type                
            };
            axios.post('/api/NewCompany/GetCompanyTypes', params, {'Content-Type': 'application/json'}).then((response) => { 
                this.companyTypes = response.data;             
                this.companyTypes.unshift({ id: '-1', text: 'Select an option'});            
                this.form.company_type_id = '-1';
            });
        },
        onSearchCompanyByNo(selectedCompanyNumber){
            if(selectedCompanyNumber) {
                this.form.company_vkn = selectedCompanyNumber;
            }
            const parameters = {
                CompanyNumber: this.form.company_vkn,
                SessionId: this.$getUser().session_id
            }
            const that = this;
            axios.post('api/GetCompanyAccounts/SearchByNo', parameters, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                    this.form.company_name = response.data.name;
                    this.form.company_vkn = response.data.number;
                    this.form.primary_person = response.data.contactName;
                    this.form.company_postcode = response.data.postalCode;
                    this.form.company_address = response.data.addressLine2 == null ? response.data.addressLine1 : response.data.addressLine1 + ' '+ response.data.addressLine2; 
                    //this.form.country = response.data.country; Çıktı England şeklinde?
                    this.form.company_city = response.data.locality;
                    this.companySearchResult = [];
                    if(this.form.company_vkn !== null && this.form.company_vkn !== '' && this.form.company_vkn !== undefined) {
                        this.form.company_member_type = 1;
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                that.$swal("Error", "No records found..!", 'error');
            });
        },
        onSearchCompanyByName(){
            this.$refs.SearchCompanyByName.getData();
        },
        onImageCropped() {
            setTimeout(() => { 
                const { coordinates, canvas, } = this.$refs.cropper.getResult();
                this.coordinates = coordinates;
                this.image = canvas.toDataURL();
                if (canvas) {
                    const form = new FormData();
                    canvas.toBlob(blob => {
                        form.append('file', new File([blob],  Math.floor(Math.random() * 1000) + '_logo.png'));
                        form.append('FileName', Math.floor(Math.random() * 1000) + '_logo.png');
                        fetch('/api/MediaLibrary/Upload', {
                            method: 'POST',
                            body: form,
                            headers: this.getUploadHeaders()
                        }).then(() => {
                            this.getCompanyLogo();
                            this.showCropSection = false;
                        });
                    }, 'image/*');
                }
            }, 500);
		},
        onDocumentImageCropped() {
            setTimeout(() => { 
                const { coordinates, canvas, } = this.$refs.cropper.getResult();
                this.coordinates = coordinates;
                this.image = canvas.toDataURL();
                if (canvas) {
                    const form = new FormData();
                    canvas.toBlob(blob => {
                        form.append('file', new File([blob],  Math.floor(Math.random() * 1000) + '_logo.png'));
                        form.append('FileName', Math.floor(Math.random() * 1000) + '_logo.png');
                        fetch('/api/MediaLibrary/Upload', {
                            method: 'POST',
                            body: form,
                            headers: this.getUploadHeadersForDocuments()
                        }).then(() => {
                            this.getCompanyDocumentLogo();
                            this.showDocumentCropSection = false;
                        });
                    }, 'image/*');
                }   
            }, 500);
		},
        onLogoUploaded(){
            this.showCropSection = true;
            this.getCompanyLogo();
        },
        onDocumentLogoUploaded(){
            this.showDocumentCropSection = true;
            this.getCompanyDocumentLogo();
        },
        onChangeRateType(event){
            this.form.rate_type = event.type;
        },
        onCropLogo() {
            this.showCropSection = !this.showCropSection;
            this.uploadedImage = this.imageList[0].url;
        },
        onCropDocumentLogo() {
            this.showDocumentCropSection = !this.showDocumentCropSection;
            this.uploadedImage = this.documentImageList[0].url;
        },
        openCreateApiKeyModal(modalType){
            this.apiKeyModalType = modalType;
            this.clearApiKeyForm();
        },
        saveApiKey(){
            const params = this.apiKeyForm; 
            const that = this;      
            this.isParameterSaving = true;
            params.session_id = this.$getUser().session_id;
            params.is_active = params.isActive == true ? 1 : 0;
            params.is_default = params.isDefault == true ? 1 : 0;
            axios.post('/api/CompanySettings/CreateApiKey', params, {'Content-Type': 'application/json'})
            .then((response) => { 
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                    this.getApiKeys();
                    this.$refs.closeApiKeyModal.click();
                }
                this.isParameterSaving = false;

            })
            .finally(function () { 
                that.isParameterSaving = false;
            });   
        },
        getApiKeys() {
            this.isApiKeysListing = true;
            const params = {
                session_id: this.$getUser().session_id
            };
            const that = this;
            axios.post('/api/CompanySettings/GetApiKeys', params, {'Content-Type': 'application/json'})
            .then((response) => {
                this.apiKeyFormList = response.data;
                this.apiKeyFormList.forEach(element => {
                    element.api_key = this.$unlock(element.api_key);
                    element.client_id = this.$unlock(element.client_id);
                    element.primary_key = this.$unlock(element.primary_key);
                    element.api_url = this.$unlock(element.api_url);
                });
            })
            .catch(function (error) {
                console.log(error);
                that.isApiKeysListing = false;
            })
            .finally(function () {
                that.isApiKeysListing = false;
            });
        },
        openEditApiKey(item, modalType){
            this.apiKeyModalType = modalType;
                this.isParameterSaving = false;
                this.isModalButtonDisabled = false;        
                const params = {
                    session_id: this.$getUser().session_id,
                    id: item
                };
                axios.post('/api/CompanySettings/GetByApiKey', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                    this.apiKeyForm = {
                        api_key: this.$unlock(response.data.api_key),
                        client_id: this.$unlock(response.data.client_id),
                        primary_key: this.$unlock(response.data.primary_key),
                        api_url: this.$unlock(response.data.api_url),
                        type: response.data.type,
                        sub_type: response.data.sub_type,
                        is_active: response.data.is_active,
                        isActive: response.data.is_active == 1 ? true : false,
                        is_default: response.data.is_default,
                        isDefault: response.data.is_default == 1 ? true : false,
                        description: response.data.description,
                        id: response.data.id
                    };
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        clearApiKeyForm(){
            this.apiKeyForm = {
                api_key: null,
                client_id: null,
                primary_key: null,
                api_url: null,
                type: 1,
                sub_type: 1,
                is_active: 0,
                isActive: false,
                is_default: 0,
                isDefault: false,
                description: null
            }
        },
        updateApiKey(){
            const params = this.apiKeyForm; 
            const that = this;            
            this.isParameterSaving = true;
            params.session_id = this.$getUser().session_id;
            params.is_active = params.isActive == true ? 1 : 0;
            params.is_default = params.isDefault == true ? 1 : 0;
            axios.post('/api/CompanySettings/UpdateApiKey', params, {'Content-Type': 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.getApiKeys();
                this.$refs.closeApiKeyModal.click();
                }
                this.isParameterSaving = false;
            })
            .catch(function (error) {
                console.log(error);
                this.$setSessionStorage('refreshList', true); 
            })
            .finally(function () { 
                setTimeout(() => {
                    that.isParameterSaving = false;
                }, 500);
            });   
        },
        deleteApiKey(itemID){
            this.$swal.fire({
                title: 'Are you sure you want to delete this API Key?',
                confirmButtonColor: '#f1416c',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                icon: 'warning'
            }).then((result) => { 
                if (result.isConfirmed) {
                    const parameters = {
                        session_id: this.$getUser().session_id,
                        id: itemID
                    };
                    axios.post('/api/CompanySettings/DeleteApiKey', parameters, {'Content-Type': 'application/json'})
                    .then((response) => {
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                            this.getApiKeys(); 
                            this.$refs.closeApiKeyModal.click();
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
            })    
        },
    }, 
    created() {
        this.helpPageViewIcon();
        this.getData();

    }
}; 
</script>