<template> 
  <div> 
    <div class="page-title flex-row me-3 mt-3"> 
        <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Stock List') }}
        </h1>    
        <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
            <li class="breadcrumb-item text-gray-600">
                <a href="#" class="text-gray-600 text-hover-primary">
                    <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                </a>
            </li> 
            <li class="breadcrumb-item text-primary">{{ $t('Accounts') }}</li>     
            <li class="breadcrumb-item text-gray-600">{{ $t('Stock List') }}
                <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
            </li> 
        </ul> 
    </div>  
    <div class="content flex-row-fluid mt-4" id="kt_content"> 
       <!-- <StatsBar></StatsBar> -->
        <div class="card">
            <div class="card-header border-0 pt-6">
                <div class="card-title">
                    <router-link to="/StockCreate" v-if="this.$isCredentialActive(16,'I')">
                        <button type="button" class="btn btn-light-primary border border-primary me-3">
                            <span class="svg-icon svg-icon-muted"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/>
                                <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                            </svg>
                            </span>
                            {{ $t('Create New Account') }}
                        </button>
                    </router-link>
                    <div class="d-flex align-items-center position-relative my-1"> 
                    </div>
                </div>
                <div class="card-toolbar">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base" v-if="this.$isCredentialActive(16,'R')"> 
                        <button type="button" class="btn btn-light-primary border border-primary me-3" data-bs-toggle="modal" 
                            data-bs-target="#kt_search_account_modal" @click="openSearchModal()">
                            <i class="fa fa-search"></i>
                            {{ $t('Search') }}
                        </button>
                        <button type="button" class="btn btn-light border border-gray-400" @click="getData()" data-kt-indicator="on" v-if="this.$getUser().is_allow_list_all_data" >
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span>
                            <span v-if="!this.isListProgressing"> {{ $t('All Accounts') }} </span>
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>
                    </div>
                    <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                        <div class="fw-bolder me-5">
                        <span class="me-2" data-kt-customer-table-select="selected_count"></span>{{ $t('Selected') }}</div>
                        <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">{{ $t('Delete Selected') }}</button>
                    </div>
                </div>
            </div>
            <div class="card-body pt-3">
                <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div>
                        <ListOptions :name="this.$options.name" @click="SaveDXStateToStorage" ref="listOptionComponent"></ListOptions>
                        <DxDataGrid id="gridContainer" :show-borders="true" 
                                    key-expr="id" 
                                    :data-source="lists" 
                                    :allow-column-reordering="true" 
                                    :allow-column-resizing="true" 
                                    :column-auto-width="true" 
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true" 
                                    :ref="'dataGrid'"
                                    @exporting="this.$onExporting($event , 'Stock-Account-List')"> 

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="pageSize"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                                <DxSearchPanel :visible="true"/>
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                />
                                <DxColumn data-field="detail" caption="..." cell-template="detail-cell-template" alignment="center" :allow-exporting="false" :width="45"/> 
                                <DxColumn data-field="account_id" :caption="$t('Code')" cell-template="account_id-cell-template"/> 
                                <DxColumn data-field="stock_image_url" :caption="$t('Image')" cell-template="stock_image_url-cell-template" alignment="center" :allow-exporting="false"/> 
                                <DxColumn data-field="name" :caption="$t('Name')" cell-template="name-cell-template"/> 
                                <DxColumn data-field="product_code" :caption="$t('Product Code')"  header-cell-template="product-code-header" cell-template="product_code-cell-template"/> 
                                <DxColumn data-field="stock_group_name"  :caption="$t('Stock Group')"  header-cell-template="stock-group-header" cell-template="stock_group_name-cell-template"/> 
                                <DxColumn data-field="account_sub_type_name" :caption="$t('Type')" cell-template="account_sub_type_name-cell-template"/> 
                                <DxColumn data-field="product_group_name" :caption="$t('Product Group')" header-cell-template="product-group-header" cell-template="product_group_name-cell-template"/> 
                                <DxColumn data-field="product_type_name" :caption="$t('Product Type')" header-cell-template="product-type-header" cell-template="product_type_name-cell-template"/> 
                                <DxColumn data-field="item_amount" :sorting-method="this.$numericSort" :caption="$t('Total In')" alignment="right" header-cell-template="total-in-header" cell-template="item_amount-cell-template"/> 
                                <DxColumn data-field="item_used" :sorting-method="this.$numericSort" :caption="$t('Total Out')" alignment="right"  header-cell-template="total-out-header" cell-template="item_used-cell-template"/> 
                                <DxColumn data-field="item_remain" :sorting-method="this.$numericSort" :caption="$t('Remains')" alignment="right" cell-template="item_remain-cell-template"/>   
                                <DxColumn data-field="unit_name" :caption="$t('Unit')" alignment="left" cell-template="unit_name-cell-template"/> 
                                <DxColumn data-field="created_by" :caption="$t('Created By')" alignment="left" cell-template="created-by-cell-template"/> 

                                <template #product-code-header>
                                    <div>{{ $t('Product') }}</div><div>{{ $t('Code') }}</div>
                                </template>
                                <template #stock-group-header>
                                    <div>{{ $t('Stock') }}</div><div>{{ $t('Group') }}</div>
                                </template>
                                <template #product-group-header>
                                    <div>{{ $t('Product') }}</div><div>{{ $t('Group') }}</div>
                                </template>
                                <template #product-type-header>
                                    <div>{{ $t('Product') }}</div><div>{{ $t('Type') }}</div>
                                </template>
                                <template #total-in-header>
                                    <div>{{ $t('Total') }}</div><div>{{ $t('In') }}</div>
                                </template>
                                <template #total-out-header>
                                    <div>{{ $t('Total') }}</div><div>{{ $t('Out') }}</div>
                                </template>

                                <!-- Cell Templates -->
                                <template #detail-cell-template="{ data }">
                                    <a href="#" class="btn btn-sm btn-icon text-hover-primary w-20px h-20px" 
                                        @click="onOpenAccountReport(data.data)" data-bs-toggle="modal" data-bs-target="#kt_modal_stock_account_report_selection" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                        <i class="fa fa-eye text-gray-600 fs-3"></i>
                                    </a>
                                </template>
                                <template #stock_image_url-cell-template="{ data }">
                                   <a href="#" class="text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                        <div>
                                            <img v-if="data.data.stock_image_url" :src="data.data.stock_image_url" width="40" height="40" alt="Stock image">
                                            <i v-else class="bi bi-card-image fs-1"></i>
                                        </div>
                                    </a>
                                </template>
                                <template #account_id-cell-template="{ data }">
                                   <a href="#" class="text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                        <div>
                                            <span class="badge badge-circle badge-light me-3">
                                                <i class="bi bi-pencil text-gray-900"></i> 
                                            </span> 
                                            <span class="text-gray-900"> 
                                                {{ data.data.account_id }} 
                                            </span> 
                                        </div>
                                    </a>
                                </template>
                                <template #name-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 fw-bold text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                        {{ data.data.name }} <div v-if="data.data.is_bundle_product == 1" class="ms-2 badge badge-sm badge-success">{{ $t('Bundle') }}</div>
                                    </a>
                                </template>
                                <template #product_code-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                        {{ data.data.product_code }} 
                                    </a>
                                </template>
                                <template #stock_group_name-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                        {{ data.data.stock_group_name }}
                                    </a>
                                </template>
                                <template #account_sub_type_name-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                        {{ data.data.account_sub_type_name }}
                                    </a>
                                </template>
                                <template #product_group_name-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                        {{ data.data.product_group_name }}
                                    </a>
                                </template>
                                <template #product_type_name-cell-template="{ data }">
                                    <a href="#" class="text-gray-800 text-hover-primary mb-1" @click="updateItem(data.data.id)">
                                        {{ data.data.product_type_name }}
                                    </a>
                                </template>
                                <template #item_amount-cell-template="{ data }">
                                    <a href="#" class="mb-1" @click="updateItem(data.data.id)" :class="{ 'text-danger': (parseFloat(data.data.item_amount) == 0) || (parseFloat(data.data.item_amount) < 0), 'text-gray-900': parseFloat(data.data.item_amount) > 0}">
                                        {{ data.data.item_amount }}
                                    </a>
                                </template>
                                <template #item_used-cell-template="{ data }">
                                    <a href="#" class="mb-1" @click="updateItem(data.data.id)" :class="{ 'text-danger': (parseFloat(data.data.item_used) == 0) || (parseFloat(data.data.item_used) < 0), 'text-gray-900': parseFloat(data.data.item_used) > 0}">
                                        {{ data.data.item_used }}
                                    </a>
                                </template>
                                <template #item_remain-cell-template="{ data }">
                                    <a href="#" class="mb-1" @click="updateItem(data.data.id)" :class="{ 'text-danger': (parseFloat(data.data.item_remain) == 0) || (parseFloat(data.data.item_remain) < 0), 'text-gray-900': parseFloat(data.data.item_remain) > 0}">
                                        {{ data.data.item_remain }}
                                    </a>
                                </template>
                                <template #unit_name-cell-template="{ data }" >
                                   <a href="#" class="mb-1" @click="updateItem(data.data.id)" :class="{ 'text-danger': (parseFloat(data.data.item_remain) == 0) || (parseFloat(data.data.item_remain) < 0), 'text-gray-900': parseFloat(data.data.item_remain) > 0}">
                                        {{ data.data.unit_name }}
                                   </a>
                                </template> 
                                <template #created-by-cell-template="{ data }" >
                                   <a href="#" @click="updateItem(data.data.id)" class="text-gray-800 text-hover-primary mb-1" >
                                        {{ data.data.created_by }}
                                   </a>
                                </template> 

                                <DxSummary :calculate-custom-summary="setSummaries">
                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="account_id"/>
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="item_amount"
                                        summary-type="custom"
                                        show-in-column="item_amount"
                                        display-format="{0}"
                                    /> 
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="item_used"
                                        summary-type="custom"
                                        show-in-column="item_used"
                                        display-format="{0}"
                                    /> 
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="item_remain"
                                        summary-type="custom"
                                        show-in-column="item_remain"
                                        display-format="{0}"
                                    /> 
                                    <DxTotalItem name="item_amount"
                                        summary-type="custom"
                                        show-in-column="item_amount"
                                    />
                                    <DxTotalItem name="item_used"
                                        summary-type="custom"
                                        show-in-column="item_used"
                                    />
                                    <DxTotalItem name="item_remain"
                                        summary-type="custom"
                                        show-in-column="item_remain"
                                    />
                                </DxSummary>

                                <DxStateStoring
                                        :enabled="true"
                                        type="custom"
                                        :storage-key="this.storageKey"
                                        :ref="dataGrid" 
                                        :saving-timeout="1000"
                                        :custom-load="LoadDXState"
                                    />
                        </DxDataGrid>
                    </div>
                </div> 
            </div>
        </div> 
    </div>

    <!-- Search Account Modal -->
    <div class="modal fade" id="kt_search_account_modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <SearchAccount ref="SearchAccount" :account-type="7"/>
    </div>  

    <!--StockAccountReports-->
    <div class="modal fade" id="kt_modal_stock_account_report_selection" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <StockAccountReports ref="StockAccountReports" :account-name="accountReport.name" :account-id="accountReport.id" />
    </div>
  </div>  
</template>

<script>
import axios from 'axios'
import moment from 'moment';
// import Swal from 'sweetalert2';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSummary, DxTotalItem, DxSearchPanel, DxStateStoring, DxScrolling } from 'devextreme-vue/data-grid';
import SearchAccount from '../../Accounts/Modals/SearchAccount.vue';
import StockAccountReports from '../../../directives/StockAccountReports.vue';
 
 
export default {
  name: "StockList",
  components:{
        DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSummary, DxTotalItem, DxSearchPanel, DxStateStoring, SearchAccount, DxScrolling, StockAccountReports
  },
  data() {
    return {
        storageKey: 'dx_' + this.$options.name,
        pageSize: 20,
        lists: [],
        searchbar: '',
        isListProgressing: false,
        page_size: 10,
        page: 1,
        currencyList: [],
        form: {
            currency: '-1',
            first_date: moment().add(-10, 'days'),
            last_date: moment(),
        },
        menuSelection: '/StockHelppage',
        iconView : false,
        accountReport: {
            id: null,
            name: null
        }
    };
  },
  computed: {
    searchableList() {
      return this.lists.filter(p => { 
        return p.name.toLowerCase().indexOf(this.searchbar.toLowerCase()) != -1;
      }).slice((this.page - 1) * this.page_size, this.page * this.page_size);
    },
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    showPageSizeSelector() {
        return this.lists.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.lists.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    }
  },
  methods: {
    getData(searchText, searchParameter) {
        if((searchText == '' || searchText == undefined || searchText.length < 3) && !this.$getUser().is_allow_list_all_data) return;
        this.$Progress.start();
        if(searchText == '' || searchText == undefined || searchText == null) {
            this.isListProgressing = true;
      }
      const params = {
        session_id : this.$getUser().session_id,
        account_type : 7, // Stock: 7
        search_text: searchText,
        search_parameter: (searchText !== null && searchText !== '' && searchText !== undefined) ? searchParameter : 0  // 0: All, 1: Search By Account Name, 2: Product Code
      };  
      axios.post('api/GetCompanyAccounts/GetStockAccounts', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                this.lists = response.data.account_data;
                this.lists.forEach(element => {
                    element.name = this.$unlock(element.name);
                    element.account_id = this.$unlock(element.account_id);
                    element.text = this.$unlock(element.text);
                });
            }
            this.$Progress.finish();
             setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
             setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        });  
    },
    updateItem(id){ 
        this.$setSessionStorage('record_id', id);
        this.$router.push({ name: 'StockUpdate' });
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/StockHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    },
    setSummaries(options){  
        const optionNames = ["item_amount", "item_used", "item_remain"]; 
        optionNames.forEach(item => {
            if(options.name == item){
                if(options.summaryProcess === 'start'){ options.totalValue = 0; }
                else if(options.summaryProcess === 'calculate'){
                    var itemValue = parseFloat(options.value[item].replaceAll(',', ''));
                    (options.totalValue += itemValue);
                }
                else if(options.summaryProcess === 'finalize'){ 
                    options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
                }
            }
        });
    },
    /** DX STATE OPERATIONS **/  
    LoadDXState() {
        return JSON.parse(localStorage.getItem(this.storageKey));
    }, 
    SaveDXStateToStorage() { 
        const state = this.$refs.dataGrid.instance.state();
        localStorage.setItem(this.storageKey, JSON.stringify(state));
        this.$refs.listOptionComponent.getState();        
    },
    openSearchModal() {
        this.$refs.SearchAccount.getData();
    },
    onOpenAccountReport(item){
        let accountInfo = {
            id: item.id, 
            name: item.name
        };
        this.$refs.StockAccountReports.getData(accountInfo);
        this.$refs.StockAccountReports.setModalCriteriasToDefault();
    },
  },
  created() {
    this.helpPageViewIcon();
    this.lists = [];
  }
}; 
</script>