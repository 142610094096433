<template>
    <div class="modal-dialog modal-dialog-centered modal-xxl">
        <div class="modal-content"  >
            <div class="modal-header bg-primary py-3">
                <div class="modal-title col-md-10 text-white" v-if="transactionType !== 10">
                  <h3 class="fw-bold text-white ms-5" v-if="accountName !== null && accountName !== undefined && accountName !== ''">
                    <span class="text-white fw-boldest">{{accountName}}</span>  's Transactions
                  </h3>
                  <h3 class="fw-bold text-white" v-else>Transaction List</h3>
                </div>  
                <h2 class="modal-title fw-bolder col-md-10" v-if="transactionType == 10">Policy Card List</h2>
                <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeTransactionListModal">
                  <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                      <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                    </svg>
                  </span>
                </div>
            </div>
            <div class="modal-body pt-5 scroll-y">
              <InvoiceList ref="InvoiceList" v-if="transactionType == 1"/>
              <CashList ref="CashList" v-if="transactionType == 2" />
              <BankList ref="BankList" v-if="transactionType == 3"/>
              <ExpenseList ref="ExpenseList" v-if="transactionType == 4"/>
              <OffsettingList ref="OffsettingList" v-if="transactionType == 5"/>
              <ChequeList ref="ChequeList" v-if="transactionType == 6"/>
              <ChequeOperationsList ref="ChequeOperationsList" v-if="transactionType == 7"/>
              <TurnoverDiffList ref="TurnoverDiffList" v-if="transactionType == 8"/>
              <PolicyList ref="PolicyList" v-if="transactionType == 10"/>
              <BalanceClosingOperationsList ref="BalanceClosingOperationsList" v-if="transactionType == 11"/>
              <OfferList ref="OfferList" v-if="transactionType == 12"/>
            </div>
        </div>
    </div>
</template>

<script>
import InvoiceList from './Invoice/List.vue';
import OfferList from './Offer/List.vue';
import CashList from './Cash/List.vue';
import BankList from './Bank/List.vue';
import ExpenseList from './Expense/List.vue';
import OffsettingList from './Offsetting/List.vue';
import ChequeList from './Cheque/List.vue';
import ChequeOperationsList from './ChequeOperations/List.vue';
import PolicyList from '../Policies/List.vue';
import TurnoverDiffList from '../Settings/TurnoverDiff/List.vue';
import BalanceClosingOperationsList from './BalanceClosingOperations/List.vue';
export default {    
  name: "TransactionListModal",
  props: {
    transactionType: Number,
    accountId: String,
    accountName: String
  },
  components: {
    InvoiceList, CashList, BankList, ExpenseList, OffsettingList, ChequeList, ChequeOperationsList, PolicyList, TurnoverDiffList, BalanceClosingOperationsList, OfferList
  },
  methods: {
    getData(viewType, selectedInvoice) {
      if(this.transactionType == 1) this.$refs.InvoiceList.setCriteriasToDefault(this.accountId, viewType, selectedInvoice);
      if(this.transactionType == 2) this.$refs.CashList.setCriteriasToDefault(this.accountId);
      if(this.transactionType == 3) this.$refs.BankList.setCriteriasToDefault(this.accountId);
      if(this.transactionType == 4) this.$refs.ExpenseList.setCriteriasToDefault();
      if(this.transactionType == 5) this.$refs.OffsettingList.setCriteriasToDefault();
      if(this.transactionType == 6) this.$refs.ChequeList.setCriteriasToDefault(this.accountId);
      if(this.transactionType == 7) this.$refs.ChequeOperationsList.setCriteriasToDefault(this.accountId);
      if(this.transactionType == 8) this.$refs.TurnoverDiffList.setCriteriasToDefault();
      if(this.transactionType == 10) this.$refs.PolicyList.setCriteriasToDefault();
      if(this.transactionType == 11) this.$refs.BalanceClosingOperationsList.setCriteriasToDefault(this.accountId);
      if(this.transactionType == 12) this.$refs.OfferList.setCriteriasToDefault(this.accountId);
    },
    onForwardParent(item) {
      if(this.transactionType == 11) this.$parent.getCollections(item);
      else return;
    }
  }
}
</script>