<template> 
  <div> 
    <div class="ms-4">
        <div class="page-title flex-row me-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Stock Transaction List') }}
            </h1> 
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-primary">{{ $t('Reports') }}</li>    
                <li class="breadcrumb-item text-primary">{{ $t('Stock Reports') }}</li> 
                <li class="breadcrumb-item text-gray-600">{{ $t('Stock Transaction List') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                </li> 
            </ul> 
        </div>  
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
        <div class="card">
            <div class="row fv-row border-0 pt-6 px-9">
                <div class="col-lg-9">
                    <div class="fv-row mb-4 row">
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('Start Date') }}</span>
                            </label>
                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.first_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                        </div>
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('End Date') }}</span>
                            </label>
                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.last_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                        </div>
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('Stock Group') }}</span>
                            </label>
                            <Select2 v-model="this.form.stock_group" :options="this.stockGroupList" :settings="{ width: '100%' }"/>  
                        </div>  
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('In/Out') }}</span>
                            </label>
                            <Select2 v-model="this.form.in_out" :options="this.inOutList" :settings="{ width: '100%' }"/> 
                        </div>
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('Product Group') }}</span>
                            </label> 
                            <Select2 v-model="this.form.product_group" :options="this.productGroupList" :settings="{ width: '100%' }" @select="onChangeProductGroup()"/> 
                        </div>
                         <div class="col-lg col-md-4 col-sm-6 col-xs-12">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('Product Type') }}</span>
                            </label>
                            <Select2 v-model="this.form.product_type" :options="this.productTypeList" :settings="{ width: '100%' }"/> 
                        </div>
                         <div class="col-lg col-md-4 col-sm-6 col-xs-12">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('Stock Name') }}</span>
                            </label> 
                            <Select2 v-model="this.form.stock_name" :options="this.stockNameList" :settings="{ width: '100%' }"/>  
                        </div>
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12">
                            <label class="fs-6 form-label mt-3">
                                <span>{{ $t('Transaction Type') }}</span>
                            </label>
                            <Select2 v-model="this.form.transaction_type" :options="this.transactionTypeList" :settings="{ width: '100%' }" @select="onChangeTransactionType($event, true)"/>  
                        </div>
                        <div class="col-lg">
                            <label class="fs-6 form-label mt-3">
                                <span>{{ $t('Sub Type') }}</span>
                            </label>
                            <Select2 v-model="this.form.transaction_subtype" :options="this.transactionSubTypeList" :settings="{ width: '100%' }"/>  
                        </div>
                    </div>  
                </div>
                <div class="col-lg-3">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base" v-if="this.$isCredentialActive(73,'R')"> 
                        <Popper hover placement="top">
                            <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                <i class="bi bi-eraser-fill fs-4"> </i>
                            </button>
                            <template #content>
                                <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                            </template>
                        </Popper>
                        <button type="button" class="btn btn-light-primary border border-primary" @click="getListData()" data-kt-indicator="on">
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span>
                            <span v-if="!this.isListProgressing"> {{ $t('List Transactions') }} </span> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button> 
                    </div>
                    <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                        <div class="fw-bolder me-5">
                        <span class="me-2" data-kt-customer-table-select="selected_count"></span>{{ $t('Selected') }}</div>
                        <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">{{ $t('Delete Selected') }}</button>
                    </div>
                </div>
            </div>
            <div class="card-body pt-3">
                <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div>
                        <div class="table-responsive min-h-300px">

                            <ListOptions :name="this.$options.name" @click="SaveDXStateToStorage" ref="listOptionComponent"></ListOptions>
                            <DxDataGrid id="gridContainer"
                                    :show-borders="true"
                                    key-expr="id"
                                    :data-source="lists"
                                    :allow-column-reordering="true" 
                                    :allow-column-resizing="true"
                                    :column-auto-width="true"
                                    :row-alternatin-enabled="true"
                                    :hover-state-enabled="true" 
                                    :show-row-lines="true" 
                                    :ref="'dataGrid'"
                                    @exporting="this.$onExporting($event , 'Stock-Transaction-List')">   
                                    <DxFilterRow :visible="true"/>
                                    <DxHeaderFilter :visible="true"/>
                                    <DxGroupPanel :visible="true"/>      
                                    <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                    <DxPaging :page-size="pageSize"/>
                                    <DxPager :visible="false" :allowed-page-sizes="pageSizesLists" :show-page-size-selector="showPageSizeSelectorLists" :show-info="true" :show-navigation-buttons="true"/>    
                                    <DxSearchPanel :visible="true"/>
                                    <DxScrolling
                                        mode="virtual"
                                        row-rendering-mode="virtual"
                                    />
                                   
                                    <DxColumn data-field="transaction_date"  data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Trns. Date')" header-cell-template="transaction-date-header" cell-template="transaction_date-cell-template"/>
                                    <DxColumn data-field="stk_product_code" :caption="$t('Product Code')" cell-template="product_code-cell-template"/>
                                    <DxColumn data-field="transaction_type_name" :caption="$t('Trns. Type')" header-cell-template="transaction-type-header" cell-template="transaction_type_name-cell-template"/> 
                                    <DxColumn data-field="invoice_type_name" :caption="$t('Trns. Type')" header-cell-template="invoice-type-header" cell-template="invoice_type_name-cell-template"/> 
                                    <DxColumn data-field="stock_account_name" :caption="$t('Stock Name')" cell-template="stock_account_name-cell-template"/>
                                    <DxColumn data-field="in_out_name" :caption="$t('In / Out')" header-cell-template="inout-type-header" cell-template="in_out_name-cell-template"/>  
                                    <DxColumn data-field="transaction_no" :caption="$t('Trns. No')" header-cell-template="transaction-no-header" cell-template="transaction_no-cell-template"/>
                                    <DxColumn data-field="quantity1" :sorting-method="this.$numericSort" :caption="$t('Quantity 1')"  alignment="right" cell-template="quantity1-cell-template"/>
                                    <DxColumn data-field="quantity2" :sorting-method="this.$numericSort" :caption="$t('Quantity 2')"  alignment="right" cell-template="quantity2-cell-template"/>
                                    <DxColumn data-field="unit1_name" :caption="$t('Unit 1')" cell-template="unit1_name-cell-template"/>
                                    <DxColumn data-field="price" :sorting-method="this.$numericSort" :caption="$t('Price')" alignment="right" cell-template="price-cell-template"/>
                                    <DxColumn data-field="total_price" :sorting-method="this.$numericSort" :caption="$t('Total Price')"  alignment="right" header-cell-template="total-price-header" cell-template="total_price-cell-template"/>
                                    <DxColumn data-field="currency" :caption="$t('Cur.')" cell-template="currency-cell-template"/>
                                    <DxColumn data-field="discount" :sorting-method="this.$numericSort" :caption="$t('Discount')"  alignment="right" cell-template="discount-cell-template"/>
                                    <DxColumn data-field="discount_amount" :sorting-method="this.$numericSort" :caption="$t('Discount Amt')"  alignment="right" header-cell-template="discount-amount-header" cell-template="discount_amount-cell-template"/> 
                                    <DxColumn data-field="net_price" :sorting-method="this.$numericSort" :caption="$t('Net Price')" alignment="right" header-cell-template="net-price-header" cell-template="net_price-cell-template"/> 
                                    <DxColumn data-field="tax_amount" :sorting-method="this.$numericSort" :caption="$t('Tax Amt')" alignment="right" header-cell-template="tax-amount-header" cell-template="tax_amount-cell-template"/> 
                                    <DxColumn data-field="tax_rate" :sorting-method="this.$numericSort" :caption="$t('Tax Rate')"  alignment="right" header-cell-template="tax-rate-header" cell-template="tax_rate-cell-template"/> 
                                    <DxColumn data-field="gross_amount" :sorting-method="this.$numericSort" :caption="$t('Gross Amt')" alignment="right"  header-cell-template="gross-amount-header" cell-template="gross_amount-cell-template"/> 
                                    <DxColumn data-field="product_group_name" :caption="$t('Product Group')" header-cell-template="product-group-header" cell-template="product_group_name-cell-template"/> 
                                    <DxColumn data-field="product_type_name" :caption="$t('Product Type')" header-cell-template="product-type-header" cell-template="product_type_name-cell-template"/> 
                                    <DxColumn data-field="id" header-cell-template="offset-detail-header" :caption="$t('Offsets')" :allow-filtering="false" cell-template="has_detail-cell-template" :allow-exporting="false"/>
                                    <DxColumn data-field="stock_code" :caption="$t('Stock Code')" cell-template="stock_code-cell-template"/>
                                    <DxColumn data-field="record_date" data-type="date" alignment="right" format="dd.MM.yyyy" header-cell-template="record-date-header" :caption="$t('Record Date')" cell-template="record_date-cell-template"/>
                                    <template #record-date-header>
                                        <div>{{ $t('Record') }}</div><div>{{ $t('Date') }}</div>
                                    </template>
                                    <template #transaction-type-header>
                                        <div>{{ $t('Transaction') }}</div><div>{{ $t('Type') }}</div>
                                    </template>
                                    <template #invoice-type-header>
                                        <div>{{ $t('Sub') }}</div><div>{{ $t('Type') }}</div>
                                    </template>
                                    <template #inout-type-header>
                                        <div>{{ $t('In') }}</div><div>{{ $t('Out') }}</div>
                                    </template>
                                     <template #product-group-header>
                                        <div>{{ $t('Product') }}</div><div>{{ $t('Group') }}</div>
                                    </template>
                                     <template #product-type-header>
                                        <div>{{ $t('Product') }}</div><div>{{ $t('Type') }}</div>
                                    </template>
                                    <template #transaction-no-header>
                                        <div>{{ $t('Transaction') }}</div><div>{{ $t('No') }}</div>
                                    </template>
                                    <template #transaction-date-header>
                                        <div>{{ $t('Transaction') }}</div><div>{{ $t('Date') }}</div>
                                    </template>
                                    <template #discount-amount-header>
                                        <div>{{ $t('Discount') }}</div><div>{{ $t('Amount') }}</div>
                                    </template>
                                    <template #total-price-header>
                                        <div>{{ $t('Total') }}</div><div>{{ $t('Price') }}</div>
                                    </template>
                                    <template #net-price-header>
                                        <div>{{ $t('Net') }}</div><div>{{ $t('Price') }}</div>
                                    </template>
                                    <template #tax-amount-header>
                                        <div>{{ $t('Tax') }}</div><div>{{ $t('Amount') }}</div>
                                    </template>
                                    <template #tax-rate-header>
                                        <div>{{ $t('Tax') }}</div><div>{{ $t('Rate') }}</div>
                                    </template>
                                    <template #gross-amount-header>
                                        <div>{{ $t('Gross') }}</div><div>{{ $t('Amount') }}</div>
                                    </template>
                                    <template #offset-detail-header>
                                        <div>{{ $t('Offsets') }}</div>
                                    </template> 

                                    <!-- Cell Templates --> 
                                    <template #record_date-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ previewDate(data.data.record_date) }} 
                                        </span>
                                    </template>
                                    <template #stock_account_name-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.stock_account_name }} <small v-if="data.data.is_bundle_product == 1" class="ms-2 badge badge-sm badge-success">{{ $t('Bundle') }}</small>
                                        </span>
                                    </template>
                                    <template #product_code-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.stk_product_code }}
                                        </span>
                                    </template>
                                    <template #stock_code-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.stock_code }}
                                        </span>
                                    </template>
                                    <template #transaction_type_name-cell-template="{ data }"> 
                                        <span class="align-middle" :class="{ 'text-primary': data.data.transaction_type == '11', 'text-info': data.data.transaction_type == '12'}">
                                            {{ data.data.transaction_type_name }}
                                        </span>
                                    </template>
                                    <template #invoice_type_name-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.invoice_type_name }}
                                        </span>
                                    </template>
                                    <template #in_out_name-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.in_out_name }}
                                        </span>
                                    </template>
                                    <template #transaction_no-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.transaction_no }}
                                        </span>
                                    </template>
                                    <template #transaction_date-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ previewDate(data.data.transaction_date) }}
                                        </span>
                                    </template>
                                    <template #option_date-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ previewDate(data.data.option_date) }}
                                        </span>
                                    </template>
                                    <template #quantity1-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.quantity1 }}
                                        </span>
                                    </template>
                                    <template #quantity2-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.quantity2 }}
                                        </span>
                                    </template>
                                    <template #unit1_name-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.unit1_name }}
                                        </span>
                                    </template>
                                    <template #price-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.price }}
                                        </span>
                                    </template>
                                    <template #total_price-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.total_price }}
                                        </span>
                                    </template>
                                    <template #currency-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.currency }}
                                        </span>
                                    </template>
                                    <template #discount-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.discount }}
                                        </span>
                                    </template>
                                    <template #discount_amount-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.discount_amount }}
                                        </span>
                                    </template>
                                    <template #net_price-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.net_price }}
                                        </span>
                                    </template>
                                    <template #tax_amount-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.tax_amount }}
                                        </span>
                                    </template>
                                    <template #tax_rate-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.tax_rate }}
                                        </span>
                                    </template>
                                    <template #gross_amount-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.gross_amount }}
                                        </span>
                                    </template>
                                    <template #vendor_commission-cell-template="{ data }"> 
                                        <span class="align-middle text-end">
                                            {{ data.data.vendor_commission }}
                                        </span>
                                    </template>
                                    <template #product_group_name-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.product_group_name }}
                                        </span>
                                    </template>
                                    <template #product_type_name-cell-template="{ data }"> 
                                        <span class="align-middle">
                                            {{ data.data.product_type_name }}
                                        </span>
                                    </template>
                                    <template #has_detail-cell-template="{ data }"> 
                                        <span class="align-middle">  
                                            <button class="btn btn-sm btn-icon btn-secondary btn-icon-dark btn-text-dark w-20px h-20px" @click="viewDetail(data.data.id)" v-if="data.data.has_detail == 1"
                                                    data-bs-toggle="modal" data-bs-target="#kt_modal_view_detail" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                <span class="svg-icon svg-icon-muted icon-size-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor"/>
                                                <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor"/>
                                                </svg>
                                                </span>
                                            </button> 
                                        </span>
                                    </template> 

                                    <DxSummary :calculate-custom-summary="setSummaries">
                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="record_date"/>
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="discount_amount"
                                        summary-type="custom"
                                        show-in-column="discount_amount"
                                        display-format="{0}"
                                        /> 
                                        <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="gross_amount"
                                        summary-type="custom"
                                        show-in-column="gross_amount"
                                        display-format="{0}"
                                        /> 
                                        <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="quantity1"
                                        summary-type="custom"
                                        show-in-column="quantity1"
                                        display-format="{0}"
                                        />
                                        <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="quantity2"
                                        summary-type="custom"
                                        show-in-column="quantity2"
                                        display-format="{0}"
                                        />  
                                        <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="tax_amount"
                                        summary-type="custom"
                                        show-in-column="tax_amount"
                                        display-format="{0}"
                                        />  
                                        <DxTotalItem
                                            name="discount_amount"
                                            summary-type="custom"
                                            show-in-column="discount_amount"
                                        />
                                        <DxTotalItem
                                            name="gross_amount"
                                            summary-type="custom"
                                            show-in-column="gross_amount"
                                        />
                                        <DxTotalItem
                                            name="quantity1"
                                            summary-type="custom"
                                            show-in-column="quantity1"
                                        />
                                        <DxTotalItem
                                            name="quantity2"
                                            summary-type="custom"
                                            show-in-column="quantity2"
                                        />
                                        <DxTotalItem
                                            name="tax_amount"
                                            summary-type="custom"
                                            show-in-column="tax_amount"
                                        />
                                    </DxSummary>

                                    <DxStateStoring
                                        :enabled="true"
                                        type="custom"
                                        :storage-key="this.storageKey"
                                        :ref="dataGrid" 
                                        :saving-timeout="1000"
                                        :custom-load="LoadDXState"
                                    />
                            </DxDataGrid>     
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>  
    
    <div class="modal fade" id="kt_modal_view_detail" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen">
            <div class="modal-content"  >
                <div class="modal-header bg-primary" id="kt_modal_add_user_header">
                    <h2 class="modal-title fw-bolder col-md-10 text-white">
                        {{ $t('Stock Offset Detail') }}                      
                    </h2>  
                    <div class="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                        <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                            </svg>
                        </span>
                    </div>
                </div>
                <div class="modal-body scroll-y" :class="{'overlay overlay-block': this.isDocketItemSaving == true}">
                    <form class="form fv-plugins-bootstrap5 fv-plugins-framework"> 
                        <div class="row"> 
                            <div class="card p-0">  
                                <div class="card-body px-3">
                                    <div>
                                        <div class="table-responsive min-h-300px">
                                            <DxDataGrid id="gridContainer" :show-borders="true" :data-source="detailList" :allow-column-reordering="true"  :allow-column-resizing="true" :column-auto-width="true" @exporting="this.$onExporting($event , 'Stock-Transaction-List')">
                                                <DxFilterRow :visible="true"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxGroupPanel :visible="true"/>      
                                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                <DxPaging :page-size="10"/>
                                                <DxPager :visible="false" :allowed-page-sizes="pageSizesDetailList" :show-page-size-selector="showPageSizeSelectorDetailList" :show-info="true" :show-navigation-buttons="true"/>    
                                                <DxSearchPanel :visible="true"/>
                                                
                                                <DxColumn data-field="offset_creation_date" data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Offset Create Date')" />
                                                <DxColumn data-field="quantity" :sorting-method="this.$numericSort" :caption="$t('Quantity')" alignment="right"/>
                                                <DxColumn data-field="transaction_type_name_in" :caption="$t('Transaction Type (IN)')" /> 
                                                <DxColumn data-field="transaction_sub_type_in" :caption="$t('Trans. Sub Type (IN)')"/>
                                                <DxColumn data-field="invoice_no_in" :caption="$t('Invoice No (IN)')"/>
                                                <DxColumn data-field="transaction_date_in" data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Trans.Date (IN)')"/> 
                                                <DxColumn data-field="stock_price_in" :sorting-method="this.$numericSort" :caption="$t('Stock Price (IN)')" alignment="right"/> 
                                                <DxColumn data-field="stock_price_total_in" :sorting-method="this.$numericSort" :caption="$t('Stock Price Total (IN)')" alignment="right"/> 
                                                <DxColumn data-field="transaction_type_name_out" :caption="$t('Trans.Type (OUT)')"/> 
                                                <DxColumn data-field="transaction_sub_type_out" :caption="$t('Trans.Sub Type (OUT)')"/> 
                                                <DxColumn data-field="invoice_no_out" :caption="$t('Invoice No (OUT)')"/> 
                                                <DxColumn data-field="transaction_date_out" :caption="$t('Trans.Date (OUT)')" data-type="date" alignment="right" format="dd.MM.yyyy" /> 
                                                <DxColumn data-field="stock_price_out" :sorting-method="this.$numericSort" :caption="$t('Stock Price (OUT)')" alignment="right"/> 
                                                <DxColumn data-field="stock_price_total_out" :sorting-method="this.$numericSort" :caption="$t('Stock Price Total (OUT)')" alignment="right"/>   

                                                <DxSummary>
                                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="offset_creation_date"/>
                                                </DxSummary>
                                            </DxDataGrid> 
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </form>
                    <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isDocketItemSaving == true">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">{{ $t('Loading...') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
  </div>  
</template>
<script>
import axios from 'axios' 
// import Swal from 'sweetalert2';
import moment from 'moment'; 
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxGroupItem,DxPager, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxStateStoring, DxScrolling } from 'devextreme-vue/data-grid';
 
export default {
  name: "StockTransactionList",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager,DxGroupItem, DxColumn, DxSearchPanel, DxSummary, DxTotalItem, DxStateStoring, DxScrolling
  }, 
  data() {
    return {
        storageKey: 'dx_' + this.$options.name,
        pageSize: 100,
        pageSizeDetailList: 10,
        page: 1, 
        page_size: 20,
        lists: [],
        form:  {
            first_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            last_date: moment(),
            in_out: 0,
            stock_group: '-1',
            stock_name: '-1',
            product_group: '-1',
            product_type: '-1',
            transaction_type: 0,
            transaction_subtype: 0,
        },
        isListProgressing: false,
        detailList: [],
        isFilterOpen: false,
        inOutList: [{ id: 0, text: 'ALL'}, { id: 1, text: 'Incoming' },{ id: 2, text: 'Outgoing' }],
        stockGroupList: [],
        productGroupList: [],
        productTypeList: [],
        stockNameList: [],
        summary: {
            discount_amount_local: null,
            gross_amount_local: null,
            total_price_local: null,
            quantity1: null,
            quantity2: null,
            tax_amount_local: null
        },
        menuSelection: '/StockTransactionListHelppage',
        iconView : false,
        transactionTypeList: [],
        transactionSubTypeList: [],
    };
  },
  computed: {
    previewDate(){
        return (value)=>{
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            }
        }
    },
    showPageSizeSelectorLists() {
        return this.lists.length > this.pageSize;
    },
    pageSizesLists() {
        let arr = [];
        let x = 1;
        let loop = (this.lists.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(1000);
        if(loop > 1) arr.push('all');
        return arr;
    },
    showPageSizeSelectorDetailList() {
        return this.detailList.length > this.pageSizeDetailList;
    },
    pageSizesDetailList() {
        let arr = [];
        let x = 1;
        let loop = (this.detailList.length / this.pageSizeDetailList);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSizeDetailList * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    }
  },
  methods: {
    getData(){ 
        this.$Progress.start();
        this.transactionTypeList = []; 
        this.transactionSubTypeList = [];

        const parameters = {
            session_id: this.$getUser().session_id,
            list_types: [{ list_type: 6, list_name: "stock_group"}],
            list_name: ["currency", "account_setting", "product_group"],
            account_type : 7 // Stock: 7
        };  

        const parametersTwo = {
            session_id: this.$getUser().session_id,
            tip: 2 //Transaction Types
        };        
        const requestOne =  axios.post('/api/Helper/GetMultiLists', parameters, {'Content-Type': 'application/json'});
        const requestTwo =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/GetCompanyAccounts/GetStockAccounts', parameters, {'Content-Type': 'application/json'}); 
        const requestFour =  axios.post('/api/Helper/GetConst', parametersTwo, {'Content-Type': 'application/json'});
        axios.all([requestOne, requestTwo, requestThree,requestFour]).then(axios.spread((...responses) => {
            this.stockGroupList = responses[0].data.stock_group; 
            this.stockGroupList.unshift({ id: '-1', text: 'ALL'}); 
            this.productGroupList = responses[1].data.product_group;
            this.productGroupList.unshift({ id: '-1', text: 'ALL'});
            this.transactionTypeList = responses[3].data; 
            this.transactionTypeList.unshift({ id: 0, text: 'ALL'});

            if(responses[2].data.error == null || responses[2].data.error == 'undefined' || responses[2].data.error == ''){
                this.stockNameList = responses[2].data.account_data;
                this.stockNameList.forEach(element => {
                    element.name = this.$unlock(element.name);
                    element.account_id = this.$unlock(element.account_id);
                    element.text = this.$unlock(element.text);
                });
            }
            this.stockNameList.unshift({ id: '-1', text: 'ALL'});
            this.productTypeList.unshift({ id: '-1', text: 'ALL'}); 
            this.productGroupList.forEach((value) => {
                if(value.is_default) {
                    this.form.product_group_id = value.id;
                }
            });
            this.transactionSubTypeList.unshift({ id: 0, text: 'ALL'}); 
            
            this.$Progress.finish();
        }))
        .catch(errors => { 
            console.error(errors);  
            this.$Progress.finish();
        });  
    },
    getListData() {
        this.$Progress.start();
        this.isListProgressing = true;
        this.form.first_date = (this.form.first_date == null || this.form.first_date == '') ? moment() : this.form.first_date;
        this.form.last_date = ( this.form.last_date == null || this.form.last_date == '') ? moment () : this.form.last_date;
        const params = {
            session_id: this.$getUser().session_id,
            first_date: moment(this.form.first_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            last_date: moment(this.form.last_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            in_out: this.form.in_out,
            stock_group: this.form.stock_group == '-1'? null : this.form.stock_group,
            stock_name: this.form.stock_name == '-1'? null : this.form.stock_name,
            product_group: this.form.product_group == '-1'? null : this.form.product_group,
            product_type: this.form.product_type == '-1'? null : this.form.product_type,
            transaction_type: this.form.transaction_type,
            transaction_subtype: this.form.transaction_subtype,
        };
        axios.post('/api/Transaction/GetAllStockTransactionList', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.lists = response.data.transactions;
                this.summary = response.data.summary;
            }
            this.page = 1;
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        });  
    },
    viewDetail(id){
        this.detailList = [];
        this.$Progress.start(); 
        const queryParameter = {
            session_id: this.$getUser().session_id,
            stock_transaction_id: id
        };   
        axios.post('/api/Transaction/GetAllStockTransactionDetail', queryParameter, {'Content-Type': 'application/json'}) 
        .then((response) => {
            this.detailList = !response.data.error ? response.data : [];
            this.$Progress.finish();
        })
        .catch(function () {
            this.$Progress.finish();
        });
    },
    setCriteriasToDefault() {   
        this.form = {
            first_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            last_date: moment(),
            in_out: 0,
            stock_group: '-1',
            stock_name: '-1',
            product_group: '-1',
            product_type: '-1',
            transaction_type: 0,
            transaction_subtype: 0,
        };
        this.lists = [];
    },
    onChangeProductGroup() {
        this.productTypeList = [];
        const params = {
            session_id: this.$getUser().session_id,
            parent_id: this.form.product_group
        };
        axios.post("/api/Helper/GetProductTypeParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
            this.productTypeList = response.data;
            this.productTypeList.unshift({ id: '-1', text: 'ALL'}); 
            this.form.product_type = this.productTypeList[0].id;
            this.productTypeList.forEach((value) => {
                if(value.is_default) {
                    this.form.product_type = value.id;
                }
            });
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    setSummaries(options){
        if(options.name == "discount_amount") { options.totalValue = this.summary.discount_amount_local }
        if(options.name == "gross_amount") { options.totalValue = this.summary.gross_amount_local }
        if(options.name == "quantity1") { options.totalValue = this.summary.quantity1 }
        if(options.name == "quantity2") { options.totalValue = this.summary.quantity2 }
        if(options.name == "tax_amount") { options.totalValue = this.summary.tax_amount_local } 
    },
    /** DX STATE OPERATIONS **/  
    LoadDXState() {
        return JSON.parse(localStorage.getItem(this.storageKey));
    }, 
    SaveDXStateToStorage() { 
        const state = this.$refs.dataGrid.instance.state();
        localStorage.setItem(this.storageKey, JSON.stringify(state));
        this.$refs.listOptionComponent.getState();        
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/StockTransactionListHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    onChangeTransactionType(e, isSetDefault){
        var finalDeger = 0;
        if(e.tip == 2 && e.deger == 5){
            finalDeger = 1; // Invoice için
        }
        if(e.tip == 2 && e.deger == 6){
            finalDeger = 3; // Bank için
        }
        if(e.tip == 2 && e.deger == 7){
            finalDeger = 3; // Cash için
        }
        if(e.tip == 2 && e.deger == 10){
            finalDeger = 10; // Cheque için
        } 
        if(e.tip == 2 && e.deger == 15){
            finalDeger = 27; // Policy için
        } 
        if(finalDeger == 0){
            this.transactionSubTypeList = [];
            this.transactionSubTypeList.unshift({ id: 0, text: 'ALL'}); 
            if(isSetDefault) {
                this.form.transaction_subtype = 0;
            }
            return;
        }
        this.$Progress.start();
        this.transactionSubTypeList = [];
        const params = {
            session_id: this.$getUser().session_id,
            tip: finalDeger //Transaction Types
        };
        axios.post('/api/Helper/GetConst', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.transactionSubTypeList = response.data; 
            this.transactionSubTypeList.unshift({ id: 0, text: 'ALL'}); 
            if(isSetDefault) {
                this.form.transaction_subtype = 0;
            }
            this.$Progress.finish();

        })
        .catch(function (error) {
            console.log(error); 
            this.$Progress.finish(); 
        });
    },
  },
  created() {
    this.helpPageViewIcon();
    this.$removeSessionStorage('record_id');
    this.getData();
    this.lists = [];
  }
}; 
</script>